import { Gantt, GanttProps, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { observer } from 'mobx-react-lite';
import { useAsyncRetry, useList, useWindowSize } from 'react-use';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Descriptions, Flex, Modal, Segmented, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { isArray, isNull } from 'is-lite/exports';
import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import { toJS } from 'mobx';

import { SmartTooltip } from 'smart/ui';
import {
    cleanEmptyChildren,
    getNeededMetaRoutePathWithFilters,
    JSONSafeParse,
    renderExcelToPrintCells
} from 'smart/utils';
import { toSnakeCase } from 'utils';
import { Action, MetaField } from '../../../modules/services/backend-api/generated_info';
import { useHandlerRun } from '../../utils/hooks';
import { EmptyMarker, StoreLink } from '../../../ui';
import { metaStore } from '../../../utils/store/MetaStore';
import { DATE_TIME_LOCAL_FORMAT } from '../../../utils/helpers/dates';

import './GanttDiagram.scss';
import { ReloadOutlined } from '@ant-design/icons';

interface IGanttData extends Task {
    [key: string]: any;
}

interface IGanttDiagram {
    meta: string;
    selectable?: boolean;
    data?: IGanttData[];
    loadingRequestParams?: boolean;
    requestParams?: { action: Action; ids: string[]; args?: PlainObject<any>; [key: string]: any };
    title?: React.ReactNode;
    extra?: React.ReactNode;
    rowSelection?: TableProps['rowSelection'];
    height?: number;
}

const fontFamily =
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";

export const GanttDiagram = observer<IGanttDiagram>(
    ({
        meta,
        data: propsData,
        loadingRequestParams,
        requestParams,
        title,
        extra,
        selectable,
        rowSelection,
        height
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        const { run } = useHandlerRun();

        const [tasks, tasksMethods] = useList<Task>([]);
        const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Hour);
        const [openedTask, setOpenedTask] = useState<Task | null>(null);
        const [selectedRows, selectedRowsMethods] = useList<Task>([]);
        const [selectedRowKeys, selectedRowKeysMethods] = useList<React.Key>([]);
        const [expanded, setExpanded] = useState<readonly React.Key[]>([]);
        const { width: windowWidth } = useWindowSize();

        useEffect(() => {
            if (tasks?.length) {
                let maxDuration = 1;

                for (const task of tasks) {
                    const duration = dayjs(task.end).diff(dayjs(task.start), 'hours');
                    maxDuration = Math.max(maxDuration, duration);
                }

                if (maxDuration > 504) setViewMode(ViewMode.Month);
                else if (maxDuration > 120) setViewMode(ViewMode.Week);
                else if (maxDuration > 48) setViewMode(ViewMode.Day);
                else if (maxDuration > 12) setViewMode(ViewMode.HalfDay);
            }
        }, [tasks]);

        const fieldsMap = useMemo(() => {
            const fields =
                toJS(
                    metaStore.meta.get((meta || requestParams?.action?.Meta_Code) ?? '')?.info
                        ?.Fields
                ) ?? [];

            const map = new Map<string, MetaField>();

            for (const field of fields) {
                map.set(field.FieldName, field);
            }

            return map;
        }, [
            meta,
            requestParams?.action?.Meta_Code,
            metaStore.meta.get((meta || requestParams?.action?.Meta_Code) ?? '')?.info?.Fields
        ]);

        // NOTE: Object.keys() для числового enum можно использовать
        const viewModeOptions = useMemo(
            () => [
                ...Object.values(ViewMode)
                    .filter(
                        (mode) =>
                            mode === 'Hour' ||
                            mode === 'Day' ||
                            mode === 'Half Day' ||
                            mode === 'Week' ||
                            mode === 'Month'
                    )
                    .map((mode) => ({
                        value: mode,
                        label: t(mode.toLowerCase())
                    }))
            ],
            [t]
        );

        const responseData = useAsyncRetry(async () => {
            if (requestParams && !loadingRequestParams) {
                const { action, ids, args, associationCode } = requestParams;

                let idsArray = ids;

                if (associationCode) {
                    const association = await metaStore.getAssociation({
                        association: action.Association?.Code,
                        meta: action.Meta_Code,
                        ids: selectedRowKeys
                    });

                    idsArray = association.ids;
                }

                let response;

                if (idsArray.length) {
                    response = await run(
                        {
                            ids: idsArray,
                            args,
                            Action_Id: action.Id!,
                            meta: action.Handler?.Meta_Code ?? action?.Meta_Code,
                            handler: action.Handler_Code
                        },
                        action?.IsLogResultHidden
                    );
                }

                const result = response?.run?.at(-1)?.Result?.gantt_view_json;
                const parsedResult = JSONSafeParse(result);

                return (isArray(parsedResult) ? parsedResult : []) as Task[];
            }

            return null;
        }, [requestParams, loadingRequestParams]);

        useEffect(() => {
            const array = propsData ?? (responseData.value || []);

            const tasks = array.map((d) => {
                const color = d.color ?? '#808080';

                let styles = { backgroundColor: color };
                if (d.styles) styles = { ...d.styles, backgroundColor: color };

                return {
                    ...d,
                    start: new Date(d.start),
                    end: new Date(d.end),
                    styles,
                    hideChildren: true
                };
            });

            tasksMethods.set(tasks);
        }, [propsData, responseData.value]);

        // const locationRoute = metaStore.meta.get('all')?.routesMap?.get('CatLocations')?.[0];
        // const getLocationPath = (id: string) =>
        //     locationRoute
        //         ? `${locationRoute?.path?.split('?')?.[0]}/${id}`
        //         : `/other/CatLocations/${id}`;

        const handleDoubleClick = useCallback<NonNullable<GanttProps['onDoubleClick']>>(
            (...args) => {
                setOpenedTask(args[0]);
            },
            []
        );

        const tasksTree = useMemo(() => {
            type Item = (typeof tasks)[number];
            type ItemWithChildren = Item & { children: ItemWithChildren[]; hideChildren: boolean };

            function buildTree(items: Item[]): ItemWithChildren[] {
                const itemMap: { [key: string]: ItemWithChildren } = {};
                const rootItems: ItemWithChildren[] = [];

                // Создаем словарь, где ключ — это `id`, значение — элемент.
                items.forEach((item) => {
                    itemMap[item.id] = { ...item, children: [], hideChildren: true };
                });

                // Добавляем детей к родителям
                items.forEach((item) => {
                    if (item.project) {
                        const parent = itemMap[item.project];
                        if (parent) {
                            parent.children!.push(itemMap[item.id]);
                        }
                    } else {
                        // Если `project` — null, это корневой элемент.
                        rootItems.push(itemMap[item.id]);
                    }
                });

                return cleanEmptyChildren(rootItems);
            }

            return buildTree(tasks);
        }, [tasks]);

        const handleExpanderClick = useCallback((task: Task) => {
            // console.log(expanded, task);
            tasksMethods.set((prev) => {
                const index = prev.findIndex((t) => t.id === task.id);

                return [...prev.slice(0, index), task, ...prev.slice(index + 1)];
            });
        }, []);

        const columnWidth = useMemo(() => {
            let coef = 10;

            if (viewMode === 'Hour') coef = 20;
            else if (viewMode === 'Half Day') coef = 12;
            else if (viewMode === 'Day') coef = 12;
            else if (viewMode === 'Week') coef = 7;
            else if (viewMode === 'Month') coef = 4;

            return windowWidth / coef - tasksTree.length * (coef / 10);
        }, [tasksTree.length, viewMode, windowWidth]);

        return (
            <Card
                loading={responseData.loading}
                className={'gantt_diagram'}
                size={'small'}
                classNames={{
                    body: 'gantt_diagram__body',
                    header: 'gantt_diagram__header',
                    extra: 'gantt_diagram__toolbar'
                }}
                title={title}
                extra={
                    <Flex gap={5} align="center">
                        <Segmented
                            style={{ width: '100%', marginLeft: 'auto' }}
                            block
                            id="timeline_table_display_mode"
                            options={viewModeOptions}
                            value={viewMode}
                            onChange={setViewMode}
                            size={'small'}
                        />

                        {extra}

                        <Button icon={<ReloadOutlined />} onClick={responseData.retry} />
                    </Flex>
                }
            >
                {tasks.length ? (
                    <Gantt
                        tasks={tasks}
                        viewMode={viewMode}
                        onDoubleClick={handleDoubleClick}
                        onExpanderClick={handleExpanderClick}
                        locale={language}
                        rowHeight={39}
                        listCellWidth={'100px'}
                        columnWidth={columnWidth}
                        // preStepsCount={}
                        fontFamily={fontFamily}
                        ganttHeight={height}
                        handleWidth={0}
                        fontSize={'14px'}
                        barCornerRadius={6}
                        headerHeight={38}
                        TaskListTable={() => (
                            <Flex vertical>
                                <Table
                                    // dataSource={tasks}
                                    dataSource={tasksTree}
                                    showHeader={false}
                                    tableLayout="fixed"
                                    sticky
                                    style={{ width: 250 }}
                                    bordered
                                    rowKey={'id'}
                                    rowSelection={
                                        selectable
                                            ? rowSelection ?? {
                                                  columnWidth: 50,
                                                  selectedRowKeys,
                                                  onChange: (selectedRowKeys, selectedRows) => {
                                                      selectedRowKeysMethods.set(selectedRowKeys);
                                                      selectedRowsMethods.set(selectedRows);
                                                  }
                                              }
                                            : undefined
                                    }
                                    expandable={{
                                        expandedRowKeys: expanded,
                                        showExpandColumn: true,
                                        columnWidth: 50,
                                        onExpand: (expanded, task) => {
                                            handleExpanderClick({
                                                ...task,
                                                hideChildren: !expanded,
                                                children: undefined
                                            });
                                        },
                                        onExpandedRowsChange: setExpanded
                                    }}
                                    columns={[
                                        {
                                            key: '1',
                                            dataIndex: 'name',
                                            title: <div style={{ height: 22 }}> </div>,
                                            minWidth: 250,
                                            render: (value, record) => {
                                                // const toMetaRoute = metaStore.meta
                                                //     .get('all')
                                                //     ?.routesMap?.get(
                                                //         (requestParams?.action?.Meta_Code ||
                                                //             meta) ??
                                                //             ''
                                                //     )
                                                //     ?.at(0);

                                                const { path, preFilters } =
                                                    getNeededMetaRoutePathWithFilters(
                                                        requestParams?.action?.Meta_Code || meta
                                                    );

                                                // const pathname = toMetaRoute
                                                //     ? `${toMetaRoute.path.split('?')[0]}/${
                                                //           record.id
                                                //       }`
                                                //     : `/other/${
                                                //           requestParams?.action?.Meta_Code || meta
                                                //       }/${record.id}`;

                                                const state = {
                                                    data: record.object_ref,
                                                    filterString: preFilters
                                                };

                                                const isChild = !!record.project;

                                                return (
                                                    <StoreLink
                                                        style={{ padding: 0, display: 'block' }}
                                                        // to={{ pathname, search: '' }}
                                                        to={{ pathname: path, search: '' }}
                                                        state={state}
                                                    >
                                                        <SmartTooltip
                                                            style={{
                                                                width: 250,
                                                                paddingLeft: isChild
                                                                    ? 10
                                                                    : undefined
                                                            }}
                                                        >
                                                            {value}
                                                        </SmartTooltip>
                                                    </StoreLink>
                                                );
                                            }
                                        }
                                    ]}
                                    size={'small'}
                                    pagination={false}
                                />
                            </Flex>
                        )}
                        TooltipContent={({ task }) => {
                            const { path, preFilters } =
                                getNeededMetaRoutePathWithFilters('CatLocations');

                            return (
                                <Card
                                    size={'small'}
                                    style={{
                                        boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.2)'
                                    }}
                                    title={task.name}
                                    styles={{ body: { width: '30wh' } }}
                                >
                                    <Flex vertical>
                                        <Flex gap={5}>
                                            <span style={{ color: 'gray' }}>
                                                {t('start_date')}:
                                            </span>
                                            <span>
                                                {dayjs(task.start).format(DATE_TIME_LOCAL_FORMAT)}
                                            </span>
                                        </Flex>
                                        <Flex gap={5}>
                                            <span style={{ color: 'gray' }}>{t('end_date')}:</span>
                                            <span>
                                                {dayjs(task.end).format(DATE_TIME_LOCAL_FORMAT)}
                                            </span>
                                        </Flex>
                                        <Flex gap={5}>
                                            <span style={{ color: 'gray' }}>
                                                {t('source_location')}:
                                            </span>
                                            <StoreLink
                                                style={{ padding: 0 }}
                                                to={{
                                                    // pathname: getLocationPath(
                                                    //     task.object_ref?.SourceLocation?.Id
                                                    // ),
                                                    pathname: `${path}/${task.object_ref?.SourceLocation?.Id}`,
                                                    search: ''
                                                }}
                                                state={{
                                                    data: task.object_ref?.SourceLocation,
                                                    filterString: preFilters
                                                }}
                                            >
                                                {task.object_ref?.SourceLocation?.Name?.[
                                                    language
                                                ] ?? task.object_ref?.SourceLocation?.Key}
                                            </StoreLink>
                                        </Flex>
                                        <Flex gap={5}>
                                            <span style={{ color: 'gray' }}>
                                                {t('destination_location')}:
                                            </span>
                                            <StoreLink
                                                style={{ padding: 0 }}
                                                to={{
                                                    // pathname: getLocationPath(
                                                    //     task.object_ref?.DestinationLocation?.Id
                                                    // ),
                                                    pathname: `${path}/${task.object_ref?.DestinationLocation?.Id}`,
                                                    search: ''
                                                }}
                                                state={{
                                                    data: task.object_ref?.DestinationLocation,
                                                    filterString: preFilters
                                                }}
                                            >
                                                {task.object_ref?.DestinationLocation?.Name?.[
                                                    language
                                                ] ?? task.object_ref?.DestinationLocation?.Key}
                                            </StoreLink>
                                        </Flex>
                                    </Flex>
                                </Card>
                            );
                        }}
                        TaskListHeader={() => (
                            <div
                                style={{
                                    height: 38,
                                    backgroundColor: '#fafafa',
                                    borderTop: '1px solid #f0f0f0',
                                    borderBottom: '1px solid #f0f0f0'
                                }}
                            ></div>
                        )}
                        // barFill={1}
                    />
                ) : (
                    <EmptyMarker />
                )}
                <Modal
                    open={!isNull(openedTask)}
                    onCancel={() => setOpenedTask(null)}
                    onClose={() => setOpenedTask(null)}
                    onOk={() => setOpenedTask(null)}
                    centered
                    title={openedTask?.name}
                    width={'60vw'}
                >
                    <Descriptions
                        bordered
                        column={2}
                        items={Object.entries(openedTask?.object_ref ?? {}).map(([key, value]) => {
                            if (fieldsMap.get(key)?.ValueType?.includes('ref:')) {
                                const { path, preFilters } =
                                    getNeededMetaRoutePathWithFilters('CatLocations');

                                return {
                                    key,
                                    label: t(toSnakeCase(key)),
                                    children: (
                                        <StoreLink
                                            style={{ padding: 0 }}
                                            to={{
                                                // pathname: getLocationPath(value?.Id),
                                                pathname: `${path}/${value?.Id}`,
                                                search: ''
                                            }}
                                            state={{
                                                data: value,
                                                filterString: preFilters
                                            }}
                                        >
                                            {renderExcelToPrintCells({
                                                value,
                                                dataSource: openedTask?.object_ref ?? {},
                                                language,
                                                field: fieldsMap.get(key)
                                            })}
                                        </StoreLink>
                                    )
                                };
                            }

                            return {
                                key,
                                label: t(toSnakeCase(key)),
                                children: renderExcelToPrintCells({
                                    value,
                                    dataSource: openedTask?.object_ref ?? {},
                                    language,
                                    field: key.includes('Date')
                                        ? { ValueType: 'datetime', FieldName: key }
                                        : fieldsMap.get(key)
                                })
                            };
                        })}
                    />
                </Modal>
            </Card>
        );
    }
);
