import { EventSourceInput } from '@fullcalendar/core';
import { ConfigProvider } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { metaStore } from 'utils/store/MetaStore';
import { ScheduleSlotType } from './ScheduleSlotsHelper';

export const useGenerateEvents = (
    rootId: string
): {
    events: EventSourceInput[];
    retry: () => void;
    loading: boolean;
} => {
    const config = useContext(ConfigProvider.ConfigContext);
    const { theme } = config;
    const colorPrimary = theme?.token?.colorPrimary;
    const colorAccent = theme?.token?.colorAccent;
    const colorError = theme?.token?.colorError;

    const overridedSlotColor = `${colorAccent ?? colorError}BF`; // 75% alpha

    const [events, setEvents] = useState<EventSourceInput[]>([]);
    const [slots, setSlots] = useState([]);

    const [{ loading }, retry] = useAsyncFn(async () => {
        const response = await metaStore.makeSelect({
            meta: 'RegScheduleSlots',
            filters: `Schedule=eq.${rootId}`
        });

        if (response.objects.length > 0) {
            setSlots(response.objects);
        }
    }, [rootId]);

    useEffect(() => {
        retry();
    }, [retry]);

    useEffect(() => {
        const eventSources: EventSourceInput[] = [];

        slots.forEach((slot) => {
            const slotData = slot as ScheduleSlotType;

            const eventSource: EventSourceInput = {
                events: [
                    {
                        title: slotData.schedule_item_name ? slotData.schedule_item_name : '',
                        start: slotData.SlotFromAt as string, // ? dayjs(slotData.datetime_range.from_datetime as string).toISOString() : '',
                        end: slotData.SlotToAt as string, // ? dayjs(slotData.datetime_range.to_datetime as string).toISOString() : '',
                        display: 'block'
                    }
                ],
                backgroundColor: slotData.IsOverrided
                    ? // ? 'rgba(255, 77, 79, .9)'
                      overridedSlotColor
                    : // : 'rgba(55, 136, 216, .9)',
                      colorPrimary,
                borderColor: 'rgba(0,0,0,0)'
            };

            eventSources.push(eventSource);
        });

        // console.log('EVENTS:', eventSources);

        setEvents(eventSources);
    }, [slots]);

    return {
        events,
        loading,
        retry
    };
};
