import { useState, useEffect, useCallback, useContext, useRef, useMemo } from 'react';
import { Table, Button, Flex, Typography, Dropdown, Space, Divider, ConfigProvider } from 'antd';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { useAsync, useAsyncRetry } from 'react-use';
import { Loader } from 'ui/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { HandlerRunModal, HandlerRunModalLoaderOnly, SmartSelectField } from 'smart/components';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { PlainObject } from '@gilbarbara/types';
import { observer } from 'mobx-react-lite';
import {
    ArrowsAltOutlined,
    BorderOutlined,
    CheckSquareOutlined,
    ExclamationCircleOutlined,
    MoreOutlined,
    ReloadOutlined,
    ShrinkOutlined
} from '@ant-design/icons';

import { StoreLink } from 'ui';
import { fnv1aHash, getNeededMetaRoutePathWithFilters, toHexAlpha } from 'smart/utils';
import { useBaseLanguage, useConditionalScroll, useHandlerRun } from 'smart/utils/hooks';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { useLocation } from 'react-router-dom';
import { useStoreNavigate } from 'utils/store';
import { LANGUAGES } from 'utils/i18n/i18n';
import { LazyIcon } from 'smart/ui';
import { FilterField } from '../SmartTablePage/components/SmartTableFilterMenu/types';
import { IHandlerWithId } from '../SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';

import './GridTablePage.scss';

interface CargoUnit {
    Id: string;
    DisplayName: { [lang: string]: string };
    Vehicle: { [lang: string]: string };
}

interface GridItem {
    Tier?: number;
    CapacityEquivalentValue: number;
    EquivalentValue: number;
    Count: number;
    Row: number;
    Line: number;
    Utilization: number;
    Id: string;
    CargoUnits: CargoUnit[];
}

interface GridTablePageProps {
    fromMeta: string;
    filters?: Filter[];
    fullState?: PlainObject;
}

const initialDataSource = {
    objects: [],
    maxTier: 1,
    originalObjects: []
};

export const GridTablePage = observer<GridTablePageProps>(({ fromMeta, filters, fullState }) => {
    const stateData = fullState?.data;

    const isFromeZone = fromMeta === 'CatTerminalZones';

    const cargoUnitsMetaInfo = metaStore.meta.get('DocCargoUnits')?.info;
    const cargoUnitsUiAllowView = cargoUnitsMetaInfo?.UiAllowView ?? true;

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const location = useLocation();
    const storeNavigate = useStoreNavigate();
    const { run } = useHandlerRun();

    const tableRef = useRef<HTMLDivElement>(null);
    const detailTableRef = useRef<HTMLDivElement>(null);

    let isSyncingScroll = false;

    const baseLanguage = useBaseLanguage();

    const { theme } = useContext(ConfigProvider.ConfigContext);
    const colorPrimary = theme?.token?.colorPrimary;

    const [selectedCells, setSelectedCells] = useState<GridItem[]>([]);
    const [searchedCell, setSearchedCell] = useState<GridItem>();
    const [selectedDetailCells, setSelectedDetailCells] = useState<GridItem[]>([]);
    const [isSmall, setIsSmall] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(false);

    const [zone, setZone] = useState<IObjectWithId>(isFromeZone ? stateData : undefined);

    const [handlerModal, setHandlerModal] = useState(false);
    const [handlerModalLoader, setHandlerModalLoader] = useState(false);
    const [handlerModalData, setHandlerModalData] = useState<Action | null>(null);
    const [handlerModalHardIds, setHandlerModalHardIds] = useState<string[] | undefined>();
    const [handlerModalRow, setHandlerModalRow] = useState<IObjectWithId | undefined>();

    useConditionalScroll('.grid_table .ant-table-body');

    useEffect(() => {
        setSelectedDetailCells([]);
    }, [selectedCells.at(-1)?.Line]);

    useEffect(() => {
        if (!tableRef.current || !detailTableRef.current) return;

        const tableBody = tableRef.current?.querySelector('div.ant-table-body') as HTMLDivElement;
        const detailTableBody = detailTableRef.current?.querySelector(
            'div.ant-table-body'
        ) as HTMLDivElement;

        if (!tableBody || !detailTableBody) return;

        const syncScroll = (source: HTMLDivElement, target: HTMLDivElement) => {
            if (isSyncingScroll) return;
            isSyncingScroll = true;
            requestAnimationFrame(() => {
                target.scrollLeft = source.scrollLeft;
                isSyncingScroll = false;
            });
        };

        const onScroll = () => syncScroll(tableBody, detailTableBody);
        const onDetailScroll = () => syncScroll(detailTableBody, tableBody);

        tableBody.addEventListener('scroll', onScroll);
        detailTableBody.addEventListener('scroll', onDetailScroll);

        return () => {
            tableBody.removeEventListener('scroll', onScroll);
            detailTableBody.removeEventListener('scroll', onDetailScroll);
        };
    }, [tableRef.current, detailTableRef.current]);

    // Запрашиваем рейсы для отбора
    const { loading: zoneLoading } = useAsync(async () => {
        const filter = filters?.[0].value[0];
        if (filter && isFromeZone && !zone) {
            // console.log('TRIPS RESPONSE');
            const response = await metaStore.makeSelect({
                meta: 'CatTerminalZones',
                filters: `Id=eq.${filter}`
            });

            if (response) {
                const zone = response.objects[0];
                setZone(zone);
            }
        }
    }, [filters, fromMeta]);

    const {
        value: dataSource,
        loading,
        retry
    } = useAsyncRetry(async () => {
        if (zone) {
            const filters = `TerminalZone=eq.${zone.Id}`;

            const objects: GridItem[] = (
                await metaStore.makeSelect({ meta: 'RegZoneStorageSlots', filters })
            )?.objects;

            const transformedData = (() => {
                const grouped = {};

                objects.forEach((cell) => {
                    if (!grouped[cell.Line]) grouped[cell.Line] = { Line: cell.Line };
                    grouped[cell.Line][cell.Row] = cell;
                });

                return Object.values(grouped);
            })();

            return {
                objects: transformedData,
                originalObjects: objects
            };
        }

        return initialDataSource;
    }, [zone]);

    const {
        value: detailDataSource,
        loading: detailLoading,
        retry: detailRetry
    } = useAsyncRetry(async () => {
        const line = selectedCells.at(-1)?.Line;

        if (isShowDetail && zone && line) {
            const filters = `TerminalZone=eq.${zone.Id}&Line=eq.${line}`;

            const objects: GridItem[] = (
                await metaStore.makeSelect({ meta: 'RegZoneStorageSlotsD', filters })
            )?.objects;

            const transformedData = (() => {
                const grouped = {};

                objects.forEach((cell) => {
                    if (!grouped[cell.Tier]) grouped[cell.Tier] = { Line: cell.Tier };
                    grouped[cell.Tier][cell.Row] = cell;
                });

                return Object.values(grouped).reverse();
            })();

            const maxTier = objects.reduce((max, item) => Math.max(max, item?.Tier ?? 1), 1);

            return {
                objects: transformedData,
                originalObjects: objects,
                maxTier
            };
        }

        return initialDataSource;
    }, [zone, isShowDetail, selectedCells.at(-1)?.Line]);

    // const { objects, minRow, maxRow, minLine, maxLine, originalObjects } =
    const { objects, originalObjects } = dataSource ?? initialDataSource;
    const {
        objects: detailObjects,
        originalObjects: detailOriginalObjects,
        maxTier
    } = detailDataSource ?? initialDataSource;

    useEffect(() => {
        if (searchedCell) {
            if (searchedCell.Slot) {
                setSelectedCells([searchedCell.Slot]);
            } else setSelectedCells([]);
        }
    }, [searchedCell, isShowDetail]);

    useEffect(() => {
        if (searchedCell && isShowDetail && detailOriginalObjects.length) {
            const detailCell = detailOriginalObjects.find((item) =>
                item.CargoUnits?.some((unit) => unit.Id === searchedCell.Id)
            );

            if (detailCell) {
                setSelectedDetailCells([detailCell]);
            }
        }
    }, [searchedCell, isShowDetail, detailOriginalObjects]);

    const handleCellClick = (record: GridItem) => {
        setSelectedCells((prev) => {
            if (prev.some((cell) => cell.Id === record.Id)) {
                // return prev.filter((cell) => cell.Id !== record.Id);
                return [];
            }
            // return prev.length < 2 ? [...prev, record] : [record];
            return [record];
        });
    };

    const handleDetailCellClick = (record: GridItem) => {
        setSelectedDetailCells((prev) => {
            if (prev.some((cell) => cell.Id === record.Id)) {
                // return prev.filter((cell) => cell.Id !== record.Id);
                return [];
            }
            // return prev.length < 2 ? [...prev, record] : [record];
            return [record];
        });
    };

    const handleRefresh = async () => {
        await Promise.all([retry(), detailRetry()]);
    };

    const handleNavigateAfterAction = useCallback(
        (
            actionResponse: Response,
            action: Action,
            filters: FilterField[] | Filter[],
            metaFields?: MetaField[]
        ) => {
            // if (!uiAllowSelect) return;

            const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

            let pageTitle = location.state?.pageTitle || pathnameLastElem;
            // const extraPageTitle = location.state?.extraPageTitle;
            if (action.Name) {
                // pageTitle = action.Name[language];
                pageTitle = action.Name;
            }

            let pathname = location.pathname;
            // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
            let cacheKey = fnv1aHash(
                `${pathnameLastElem}_filtered_${JSON.stringify(filters ?? location.state ?? {})}`
            );
            let filterString;

            if (action.NavItem && action.NavItem.Path) {
                // pathname = action.NavItem.Path;
                pathname = action.NavItem.Path.includes('?')
                    ? action.NavItem.Path.split('?')[0]
                    : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                // используем просутю hash функцию по методу fnv1
                filterString = action.NavItem.Path.includes('?')
                    ? action.NavItem.Path.split('?')[1]
                    : undefined;
                cacheKey = fnv1aHash(
                    `${action.NavItem.Id}_${JSON.stringify(filters ?? action?.Id ?? {})}`
                );
            } else if (action.Association?.TargetMeta_Code) {
                pathname = `/other/${action.Association?.TargetMeta_Code}`;
                // используем просутю hash функцию по методу fnv1
                cacheKey = fnv1aHash(
                    `${pathname}_filtered_${JSON.stringify(filters ?? action?.Id ?? {})}`
                );
            }

            storeNavigate(
                { pathname },
                {
                    state: {
                        filters,
                        pageTitle,
                        // extraPageTitle,
                        cacheKey,
                        fromMeta: action.Meta_Code,
                        associationCode: action.Association_Code,
                        responseData: { ...actionResponse.run?.[0]?.Result, ids: [] },
                        metaFields,
                        filterString
                    }
                }
            );
        },
        [
            location.pathname,
            // location.state?.extraPageTitle,
            location.state?.pageTitle,
            storeNavigate
        ]
    );

    const handleNavigateBeforeAction = useCallback(
        ({
            action,
            filters,
            metaFields,
            hardIds,
            hardArgs
        }: {
            action: Action;
            filters: FilterField[] | Filter[];
            metaFields?: MetaField[];
            hardIds?: string[];
            hardArgs?: PlainObject;
        }) => {
            // if (!uiAllowSelect) return;

            const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

            let pageTitle = location.state?.pageTitle || pathnameLastElem;
            let pathname = location.pathname;
            const cacheKeyBodyPermanent = hardIds ?? action?.Id ?? {};
            const cacheKeyBody = JSON.stringify(filters?.length ? filters : cacheKeyBodyPermanent);
            // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
            let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${cacheKeyBody}`);

            let filterString;
            if (action.NavItem && action.NavItem.Path) {
                if (action.Name) {
                    // pageTitle = action.Name[language];
                    pageTitle = action.Name;
                }
                // pathname = action.NavItem.Path;
                pathname = action.NavItem.Path.includes('?')
                    ? action.NavItem.Path.split('?')[0]
                    : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                // используем просутю hash функцию по методу fnv1
                filterString = action.NavItem.Path.includes('?')
                    ? action.NavItem.Path.split('?')[1]
                    : undefined;
                cacheKey = fnv1aHash(`${action.NavItem.Id}_${cacheKeyBody}`);
            }

            storeNavigate(
                { pathname },
                {
                    state: {
                        filters,
                        pageTitle,
                        cacheKey,
                        fromMeta: action.Meta_Code,
                        associationCode: action.Association_Code,
                        requestData: {
                            action,
                            ids: hardIds ?? [],
                            args: hardArgs ?? {}
                        },
                        metaFields,
                        filterString
                    }
                }
            );
        },
        [location.pathname, location.state?.pageTitle, storeNavigate]
    );

    const actionClickHandler = useCallback(
        async (
            action: Action,
            hardIds?: string[],
            hardArgs?: PlainObject,
            row?: IObjectWithId
        ): Promise<Response | undefined> => {
            if (action.IsNavigationFirst) {
                handleNavigateBeforeAction({ action, hardArgs, hardIds, filters: [] });
            } else if (action.Handler_Code) {
                setHandlerModalData({
                    ...action,
                    Handler: {
                        ...action.Handler,
                        Id: action.Id!,
                        ChildParams: action.Handler?.ChildParams.map((p) => {
                            if (hardArgs && Object.keys(hardArgs).includes(p.ParamName)) {
                                return { ...p, DefaultValue: hardArgs[p.ParamName] };
                            }

                            return p;
                        })
                    } as IHandlerWithId
                });

                const isShowModal =
                    action.Handler &&
                    ((action.Handler.ChildParams?.length &&
                        !action.Handler?.ChildParams?.every((param) => !param.IsRequested)) ||
                        action.Handler_Code === 'MassUpdate' ||
                        action.Handler_Code === 'MassChildUpsert');

                if (isShowModal) {
                    setHandlerModal(true);
                    setHandlerModalHardIds(hardIds);
                    setHandlerModalRow(row);
                } else {
                    setHandlerModalLoader(true);

                    const response = await run(
                        {
                            Action_Id: action.Id!,
                            meta: action.Meta_Code,
                            ids: hardIds ?? [],
                            handler: action.Handler_Code,
                            args: hardArgs || {}
                        },
                        action.IsLogResultHidden
                    );

                    setHandlerModalLoader(false);

                    return response;
                }
            }

            return undefined;
        },
        // [refresh, run, selectedRowKeys, setLogMessages]
        [handleNavigateBeforeAction, run]
    );

    useAsync(async () => metaStore.getInfo('DocCargoUnits'), []);

    const cargoActions = metaStore.meta.get('DocCargoUnits')?.info?.Actions;

    const getCargoActionsMenuItems = useCallback(
        (ids: string[], row: IObjectWithId, args?: PlainObject) => {
            const action = cargoActions?.find(
                (a) => a.Handler_Code === 'DocCargoUnits.CreateTerminalTask_Replacement'
            );

            if (action) {
                const item = {
                    key: action.Name[language as LANGUAGES] || action.Type_Code,
                    icon: <LazyIcon icon={action.Icon} />,
                    label: action.Name[language as LANGUAGES] || action.Id,
                    disabled: action.IsDisabled,
                    onClick: () => actionClickHandler(action, ids, args, row)
                };

                return [item];
            }

            return [];
        },
        [cargoActions, actionClickHandler, language]
    );

    // const moveCells = () => {
    //     if (selectedCells.length === 2) {
    //         const [first, second] = selectedCells;
    //         first.Row = second.Row;
    //         first.Line = second.Line;
    //         second.Row = first.Row;
    //         second.Line = first.Line;
    //         setSelectedCells([]);
    //     }
    // };

    const { path: cargoUnitsPathName, preFilters: cargoUnitsPreFilters } =
        getNeededMetaRoutePathWithFilters('DocCargoUnits');

    const getColumns = useCallback(
        (
            originalObjects: GridItem[],
            selectedCells: GridItem[],
            handleCellClick: (cell: GridItem) => void
        ) => {
            const allRows = new Set<number>();
            originalObjects.forEach((item) => {
                allRows.add(item.Row);
            });

            return [
                {
                    title: '',
                    dataIndex: 'Line',
                    key: 'Line',
                    fixed: 'left',
                    width: 55,
                    align: 'center',
                    render: (line: number) => (
                        <div
                            style={{
                                // margin: '-8px',
                                margin: '-4px -8px',
                                backgroundColor: '#fafafa',
                                width: 'calc(100% + 1rem)',
                                // height: '46px',
                                height: '37px',
                                // padding: '0.7rem 0rem'
                                padding: '0.4rem 0rem',
                                fontWeight: 600
                            }}
                        >
                            {line}
                        </div>
                    )
                },
                ...Array.from(allRows)
                    .sort((a, b) => a - b)
                    .map((row) => ({
                        title: row,
                        dataIndex: row,
                        key: row,
                        width: 55,
                        align: 'center',
                        render: (cell: GridItem, record: any) => {
                            const isSelected = selectedCells.some(
                                (selectedCell) => selectedCell.Id === cell?.Id
                            );

                            const isNotConatainer = !cell || cell?.CapacityEquivalentValue === 0;

                            const utilization = cell?.Utilization ?? 0;
                            const tier = cell?.Tier;

                            const backgroundColor = isNotConatainer
                                ? 'gray'
                                : cell?.EquivalentValue === 0
                                ? 'lighblue'
                                : `${colorPrimary}${toHexAlpha(
                                      tier ? utilization / maxTier : utilization
                                  )}`;

                            // const slotName = `LS-${record.Line}-${row}:`;
                            // const slotName = `${zone?.Code}-${cell.Line}-${cell.Row}${
                            const slotName = `${zone?.Code}-${cell.Row}-${cell.Line}${
                                cell.Tier ? `-${cell.Tier}` : ''
                            }:`;
                            const containersInfo = `${t('containers')}: ${cell?.Count ?? 0}`;
                            const busyInfo = `${t('busy')}: ${cell?.EquivalentValue ?? 0} ${t(
                                'TEU'
                            )}`;
                            const freeInfo = `${t('free')}: ${
                                (cell?.CapacityEquivalentValue ?? 0) - (cell?.EquivalentValue ?? 0)
                            } ${t('TEU')}`;

                            const cargoUnitLinks = (cell?.CargoUnits ?? [])?.map((cargoUnit) => {
                                const cargoUnitId = cargoUnit.Id;
                                const cargoUnitLabel =
                                    cargoUnit.DisplayName?.[
                                        language === 'tech' ? baseLanguage : language
                                    ] ?? cargoUnit.DisplayName?.[baseLanguage];

                                //   const status = bs.Status;
                                // const color = backgroundColor;
                                //   const statusName =
                                //       status?.Name?.[
                                //           language === 'tech' ? baseLanguage : language
                                //       ] ?? status?.Name?.[baseLanguage];

                                const to = `${cargoUnitsPathName}/${cargoUnitId}`;

                                return (
                                    <Flex
                                        gap={8}
                                        align="center"
                                        key={cargoUnitId}
                                        style={{ padding: '1px 11px', minWidth: '200px' }}
                                    >
                                        {/* <Tooltip title={statusName}>
                                        <Badge color={color} />
                                    </Tooltip> */}
                                        {cargoUnitsPathName && cargoUnitsUiAllowView ? (
                                            <StoreLink
                                                key={cargoUnitId}
                                                style={{ padding: 0 }}
                                                to={to}
                                                state={{
                                                    data: cargoUnit,
                                                    filterString: cargoUnitsPreFilters
                                                }}
                                            >
                                                {cargoUnitLabel}
                                            </StoreLink>
                                        ) : (
                                            cargoUnitLabel
                                        )}
                                        <Dropdown
                                            menu={{
                                                items: getCargoActionsMenuItems(
                                                    [cargoUnit.Id],
                                                    cargoUnit
                                                )
                                            }}
                                            trigger={['click']}
                                            placement="bottomLeft"
                                            destroyPopupOnHide
                                        >
                                            <Button type="text" style={{ marginLeft: 'auto' }}>
                                                <MoreOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Flex>
                                );
                            });

                            return (
                                <Dropdown
                                    dropdownRender={(menu) => {
                                        return (
                                            <div className={'grid_table__slot_context'}>
                                                <div style={{ padding: '0 5px' }}>
                                                    <Typography.Text strong>
                                                        {slotName}
                                                    </Typography.Text>
                                                    <Typography.Text>
                                                        {containersInfo}
                                                    </Typography.Text>
                                                    <Typography.Text>{busyInfo}</Typography.Text>
                                                    <Typography.Text>{freeInfo}</Typography.Text>
                                                </div>
                                                {!!cargoUnitLinks.length && (
                                                    <>
                                                        <Divider style={{ margin: '5px 0' }} />
                                                        <Flex vertical>{cargoUnitLinks}</Flex>
                                                    </>
                                                )}
                                                {/* <Divider style={{ margin: '5px 0' }} /> */}

                                                {/* ) : null} */}
                                                {/* {React.cloneElement(menu as React.ReactElement, {
                                                style: {
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 'none',
                                                    padding: 0
                                                }
                                            })} */}
                                                {/* {status !== 'closed' ? ( */}
                                                {/* <>
                                                <Divider style={{ margin: '5px 0' }} />
                                                <Typography.Text>{bottomText}</Typography.Text>
                                            </> */}
                                                {/* ) : null} */}
                                            </div>
                                        );
                                    }}
                                    // menu={{

                                    // }}
                                    trigger={['contextMenu']}
                                    destroyPopupOnHide
                                    getPopupContainer={() =>
                                        document.querySelector('.grid_table') as HTMLElement
                                    }
                                >
                                    <div
                                        onClick={
                                            isNotConatainer
                                                ? undefined
                                                : () => handleCellClick(cell)
                                        }
                                        style={{
                                            border: isSelected
                                                ? '2px solid blue'
                                                : '1px solid gray',
                                            // padding: '8px',
                                            // margin: '-7px',
                                            margin: '-3px -7px',
                                            backgroundColor,
                                            width: 'calc(100% + 0.9rem)',
                                            height: '35px',
                                            // padding: '0.7rem 0rem'
                                            padding: '0.4rem 0rem'
                                            // textAlign: 'center'
                                        }}
                                    >
                                        {cell?.Count ?? null}
                                    </div>
                                </Dropdown>
                            );
                        }
                    }))
            ];
        },
        [
            t,
            language,
            baseLanguage,
            cargoUnitsPathName,
            cargoUnitsUiAllowView,
            cargoUnitsPreFilters,
            getCargoActionsMenuItems,
            maxTier,
            zone
        ]
    );

    const searchOptions = useMemo(() => {
        return originalObjects.reduce((acc, item) => {
            if (item.CargoUnits) {
                acc.push(...item.CargoUnits.map((unit) => ({ ...unit, Slot: item })));
            }

            return acc;
        }, []);
    }, [originalObjects]);

    return (
        <div id="grid_table_page_container">
            <Flex
                align="flex-start"
                justify="space-between"
                gap={5}
                wrap="wrap"
                style={{ marginBottom: 5 }}
            >
                <Flex align="center" gap={5}>
                    <Typography.Text>{t('zone')}:</Typography.Text>
                    <div style={{ width: '250px' }}>
                        <SmartSelectField
                            meta="CatTerminalZones"
                            onChange={setZone}
                            value={zone}
                            loading={zoneLoading}
                            disabled={isFromeZone}
                            // filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`}
                        />
                    </div>
                </Flex>

                <Space>
                    {/* <Button
                        icon={<SwapOutlined />}
                        onClick={moveCells}
                        disabled={selectedCells.length !== 2}
                    >
                        {t('swap')}
                    </Button> */}
                    <SmartSelectField
                        placeholder={`${t('search')}...`}
                        value={searchedCell}
                        meta={''}
                        // dataSource={originalObjects}
                        dataSource={searchOptions}
                        onChange={setSearchedCell}
                        disableDropdown
                        disabled={!zone}
                        myViewFieldName="DisplayName"
                        // myRefViewTemplate="LS-{{Current.Line}}-{{Current.Row}}"
                        style={{ width: 240 }}
                    />
                    <Button
                        icon={isShowDetail ? <CheckSquareOutlined /> : <BorderOutlined />}
                        disabled={!zone}
                        onClick={() => setIsShowDetail((prev) => !prev)}
                        children={
                            isShowDetail && zone && selectedCells.at(-1)
                                ? // ? `${zone.Code}-${selectedCells.at(-1)?.Line}-${selectedCells.at(-1)?.Row}`
                                  `${zone.Code}-${selectedCells.at(-1)?.Row}-${
                                      selectedCells.at(-1)?.Line
                                  }`
                                : t('details_by_line')
                        }
                    />
                    <Button icon={<ReloadOutlined />} disabled={!zone} onClick={handleRefresh} />
                    <Button
                        icon={isSmall ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
                        disabled={!zone}
                        onClick={() => setIsSmall((prev) => !prev)}
                    />
                </Space>
            </Flex>
            <Loader status={loading}>
                <Loader
                    customTip={t('filter_first_message') as string}
                    customIndicator={<ExclamationCircleOutlined />}
                    status={!zone}
                    style={{ color: 'rgba(0,0,0,.85)' }}
                >
                    <div ref={tableRef}>
                        <Table
                            className={isSmall ? 'grid_table grid_table__small' : 'grid_table'}
                            size="small"
                            dataSource={objects}
                            columns={getColumns(originalObjects, selectedCells, handleCellClick)}
                            pagination={false}
                            bordered
                            tableLayout="fixed"
                            scroll={
                                isShowDetail
                                    ? { y: 'calc(66vh - 13rem)' }
                                    : { y: 'calc(100vh - 16rem)' }
                            }
                        />
                    </div>
                </Loader>
            </Loader>

            {isShowDetail && (
                <>
                    <Typography.Text strong style={{ marginTop: 5 }}>
                        {selectedCells.at(-1) && `${t('line')}: ${selectedCells.at(-1)?.Line}`}
                    </Typography.Text>
                    <Loader status={detailLoading}>
                        <Loader
                            customTip={t('filter_first_message') as string}
                            customIndicator={<ExclamationCircleOutlined />}
                            status={!selectedCells.length}
                            style={{ color: 'rgba(0,0,0,.85)' }}
                        >
                            <div ref={detailTableRef}>
                                <Table
                                    className={
                                        isSmall ? 'grid_table grid_table__small' : 'grid_table'
                                    }
                                    size="small"
                                    dataSource={detailObjects}
                                    columns={getColumns(
                                        detailOriginalObjects,
                                        selectedDetailCells,
                                        handleDetailCellClick
                                    )}
                                    pagination={false}
                                    bordered
                                    tableLayout="fixed"
                                    scroll={{ y: 'calc(33vh - 7rem)' }}
                                />
                            </div>
                        </Loader>
                    </Loader>
                </>
            )}

            {handlerModal && (
                <HandlerRunModal
                    open={handlerModal}
                    onCancel={() => {
                        setHandlerModal(false);
                        setHandlerModalData(null);
                        setHandlerModalHardIds(undefined);
                        setHandlerModalRow(undefined);
                    }}
                    action={handlerModalData}
                    ids={handlerModalHardIds ?? []}
                    onRefresh={() => {}}
                    metaName={handlerModalData?.Meta_Code ?? 'DocTrips'}
                    navigateAfterRun={handleNavigateAfterAction}
                    // filters={filters}
                    meta={handlerModalData?.Meta_Code ?? 'DocTrips'}
                    row={handlerModalRow}
                />
            )}

            {handlerModalLoader && (
                <HandlerRunModalLoaderOnly
                    open={handlerModalLoader}
                    onCancel={() => {
                        setHandlerModalLoader(false);
                        setHandlerModalData(null);
                    }}
                    action={handlerModalData}
                />
            )}
        </div>
    );
});
