import { useCallback, useEffect, useRef, useState } from 'react';
import { useEffectOnce, useMap, useUpdateEffect } from 'react-use';
import { IObjectWithId } from 'utils/store/MetaStore';
import { isDefined } from 'is-lite/exports';
import { ISettingsModel } from 'smart/settings';
import { useDetailFormChanged } from 'modules/layouts/DefaultLayout/components/CommonPage/CommonPage';
import { useLocation } from 'react-router-dom';
import { Meta } from 'modules/services/backend-api/generated_info';
import { PlainObject } from '@gilbarbara/types';
import { JSONSafeParse, parseValueType } from 'smart/utils';
import { useTranslation } from 'react-i18next';
import { durationPrettyOutput } from 'smart/components/SmartDurationField/SmartDurationField';
// import { useNotifications } from 'utils/hooks';
// import { isEqual } from 'lodash';
// import { PlainObject } from '@gilbarbara/types';

interface DataType {
    Id: string;
    [key: string]: any;
}

interface UseDataProps {
    id: string;
    mode: 'edit' | 'view';
    data?: DataType;
    isCreate?: boolean;
    isCopy?: boolean;
    // parentFieldName?: string;
    executeUIHandler: Function;
    metaInfo: Meta | undefined;
    setModel: React.Dispatch<React.SetStateAction<ISettingsModel<any>>>;
}

// const withoutParent = (object: PlainObject, parentFieldName: string | undefined) => {
//     if (!parentFieldName) return object;

//     const copy = JSON.parse(JSON.stringify(object));
//     delete copy[parentFieldName];
//     return copy;
// };

export const useData = ({
    data,
    id,
    mode,
    // parentFieldName,
    isCreate,
    isCopy,
    executeUIHandler,
    metaInfo
}: // setModel
UseDataProps) => {
    // const hasParentFieldAndValueInPropsData = parentFieldName && data?.[parentFieldName];

    const markAllFieldsAlreadyChanged = isCopy || isCreate;

    const initialChangesValue = {
        Id: id,
        CreatedAt: data?.CreatedAt
    };

    // if (hasParentFieldAndValueInPropsData)
    //     initialChangesValue[parentFieldName] = data[parentFieldName];

    const location = useLocation();
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const dataBeforeEdit = useRef<IObjectWithId>(initialChangesValue);

    const [dataSource, setDataSource] = useState<IObjectWithId>(initialChangesValue);
    const [changes, changesMethods] = useMap<IObjectWithId>(initialChangesValue);
    const CreatedAt = dataSource.CreatedAt;

    // const [isNotChangedData, setIsNotChangedData] = useState(true);
    // const isChanged = Object.keys(withoutParent(changes, parentFieldName)).length > 2;
    const isChanged = Object.keys(changes).length > 2;

    // useEffect(() => {
    //     console.log('CHANGES', changes);
    // }, [changes]);
    // useEffect(() => {
    //     console.log('DATA SOURCE', dataSource);
    // }, [dataSource]);

    // console.log(isChanged, parentFieldName, data?.[parentFieldName], changes);

    const [_, setIsChanged] = useDetailFormChanged();

    const setChangedStatus = (changed: boolean) => {
        setIsChanged((prev) => {
            const map = new Map(prev.changedMap);
            map.set(`${location.pathname}_${location.state?.cacheKey}`, changed);

            return { ...prev, changedMap: map };
        });
    };

    useEffectOnce(() => {
        if (data) setDataSource((prevData) => ({ ...prevData, ...data }));

        if (markAllFieldsAlreadyChanged && data) {
            changesMethods.setAll({ ...initialChangesValue, ...data });
        }
    });

    const initialCreateValuesCalled = useRef(false);

    useEffect(() => {
        // console.log(isCreate, metaInfo);
        // if (metaInfo && isCreate && !initialCreateValuesCalled.current) {
        if (metaInfo && mode === 'view' && initialCreateValuesCalled.current) {
            const initialValues = metaInfo.Fields.reduce((acc, field) => {
                if (!field.IsReadOnly && field.ValueType?.includes('value:')) {
                    changesMethods.remove(field.FieldName);
                    acc[field.FieldName] = undefined;
                }

                return acc;
            }, {} as PlainObject<any>);

            setDataSource((prev) => ({ ...initialValues, ...prev }));
        } else if (metaInfo && mode === 'edit' && !initialCreateValuesCalled.current) {
            const initialValues = metaInfo.Fields.reduce((acc, field) => {
                // if (field.ValueType?.includes('value:')) {
                //     console.log(field);
                // }

                if (!field.IsReadOnly) {
                    const { options } = parseValueType({ valueType: field.ValueType }, language, {
                        info: metaInfo,
                        // current: dataSource,
                        current: data,
                        // self: dataSource[field.FieldName],
                        self: data?.[field.FieldName],
                        // root: dataSource
                        root: data
                    });

                    if (options?.value) {
                        acc[field.FieldName] = options.value;
                    }
                }

                return acc;
            }, {} as PlainObject<any>);

            // console.log(initialValues);

            changesMethods.setAll({ ...initialValues, ...changes });
            setDataSource((prev) => ({ ...initialValues, ...prev }));

            initialCreateValuesCalled.current = true;
        }
        // }, [isCreate, metaInfo]);
    }, [mode, metaInfo]);

    useEffect(() => {
        if (!changesMethods.get('CreatedAt')) {
            changesMethods.set('CreatedAt', CreatedAt);
        }
    }, [CreatedAt]);

    useUpdateEffect(() => {
        setChangedStatus(isChanged);
    }, [isChanged]);

    useUpdateEffect(() => {
        changesMethods.reset();
        dataBeforeEdit.current = dataSource;
    }, [mode]);

    // ### Функция для изменения ДАННЫХ ПО КЛЮЧУ ('Id' или 'TO_Status.Status')
    const handleDataSourceChange = useCallback(
        (key: string, value: any) => {
            // console.log('CHANGES', key, value);
            setDataSource((prevData: any) => {
                return { ...prevData, [key]: value };
            });
            changesMethods.set(key, isDefined(value) ? value : null);
            executeUIHandler({
                methodName: 'onChange',
                fieldName: key,
                extraArguments: [value]
            });

            // if (!isEqual(dataBeforeEdit.current[key], value)) {
            //     setIsNotChangedData(false);
            // }
        },
        [executeUIHandler]
    );

    // console.log('Changes!', changes);

    return {
        dataSource,
        setData: setDataSource,
        changeData: handleDataSourceChange,
        changes,
        isChanged
    };
};
