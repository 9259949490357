import { memo, useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebounce, useMountedState, useUpdateEffect } from 'react-use';
import { isDefined } from 'is-lite/exports';

interface NumberFieldProps extends Omit<InputNumberProps, 'onChange'> {
    onChange?: (value: string | number | null | undefined) => void;
}

export const NumberField = memo<NumberFieldProps>(({ value, onChange, ...props }) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);

    const isMounted = useMountedState();

    useUpdateEffect(() => {
        if (onChange && value === undefined) setInnerValue(undefined);
    }, [value]);

    useDebounce(
        () => {
            if (isMounted() && onChange && isDefined(innerValue) && innerValue !== value) {
                onChange(innerValue);
            }
        },
        400,
        [innerValue]
    );

    return (
        <InputNumber
            {...props}
            placeholder={t('no_value') as string}
            value={value}
            onChange={setInnerValue}
            onClick={(e) => e.stopPropagation()}
        />
    );
});
