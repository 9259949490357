import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import { isArray, isBoolean, isPropertyOf, isString } from 'is-lite/exports';
import { isPlainObject } from 'lodash';
import { MetaField } from 'modules/services/backend-api/generated_info';
import { durationPrettyOutput } from 'smart/components/SmartDurationField/SmartDurationField';
import { columnFilterRule } from 'smart/components/SmartTable/SmartTable';
import { UserSpecificFormat } from 'utils/helpers/dates';
import { i18n } from 'utils/i18n/i18n';
import { IObjectWithId } from 'utils/store/MetaStore';
import * as XLSX from 'xlsx';

const renderDuration = (duration: number | undefined) => {
    if (!duration) return duration;
    // dayjs(date).locale(i18n.language).local().format(UserSpecificFormat.getDateTimeLocalFormat());
    // return dayjs(date).locale(i18n.language).local().format('DD.MM.YYYY HH:mm');
    return durationPrettyOutput(duration);
};

const renderDateTimeRange = (date: PlainObject<string> | undefined) => {
    if (!date || !date?.FromDatetime) return date;

    return `${UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(date.FromDatetime, i18n.language)}${date.ToDatetime ? ` - ${UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(date.ToDatetime, i18n.language)}` : ''}`;
};

const renderDatesRange = (date: PlainObject<string> | undefined) => {
    if (!date || !date?.FromDate) return date;

    return `${UserSpecificFormat.convertFromDbDateToUiDate(date.FromDate, i18n.language)}${date.ToDate ? ` - ${UserSpecificFormat.convertFromDbDateToUiDate(date.ToDate, i18n.language)}` : ''}`;
};

const renderDateTime = (date: string | undefined) => {
    if (!date || typeof date !== 'string') return date;

    return UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(date, i18n.language);
};

const renderDate = (date: string | undefined) => {
    if (!date || typeof date !== 'string') return date;

    return UserSpecificFormat.convertFromDbDateToUiDate(date, i18n.language);
};

const renderTimeRangeArray = (time: PlainObject<string>[] | undefined) => {
    if (!time || !isArray(time)) return time;

    return time.map((v) => {
        return `${v.FromTime} ${v.ToTime ? ` - ${v.ToTime}` : ''}, `;
    });
};

const renderTimeRange = (time: PlainObject<string> | undefined) => {
    if (!time || !time?.FromTime) return time;

    return `${time.FromTime}${time.ToTime ? ` - ${time.ToTime}` : ''}`;
};

const renderTime = (time: string | undefined) => {
    if (!time) return time;

    return UserSpecificFormat.convertFromDbTimeToUiTime(time, i18n.language);
};

interface IExportDataToExcel {
    metaName: string;
    tableData: IObjectWithId[];
    columns: MetaField[];
}

export const exportDataToExcel = ({ metaName, tableData, columns }: IExportDataToExcel) => {
    const newData: IObjectWithId[] = [];
    const sortedColumns = columns.filter(
        (column) =>
            !column.IsHiddenOnTable &&
            columnFilterRule(
                { dataIndex: column.FieldName },
                0,
                columns.map((column) => ({ ...column, dataIndex: column.FieldName }))
            )
    );

    for (const item of tableData) {
        const filteredItem: Record<string, any> = {};

        for (const column of sortedColumns) {
            const columnTitle = column.Name?.[i18n.language] || i18n.t(column.ColumnName);
            const fieldName = column.FieldName;

            if (isString(column.ValueType) && column.ValueType.includes('coordinate')) {
                const value = item[fieldName];

                filteredItem[columnTitle] =
                    value && value.Latitude && value.Longitude
                        ? `${value.Latitude} ${value.Longitude}`
                        : '';
                continue;
            }

            if (
                fieldName.endsWith('Value') &&
                isPropertyOf(item as PlainObject, fieldName.replace('Value', 'MeasureUnit'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('Value', 'MeasureUnit')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (
                fieldName.endsWith('CurrencyValue') &&
                isPropertyOf(item as PlainObject, fieldName.replace('CurrencyValue', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('CurrencyValue', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            // if (
            //     fieldName.endsWith('Value') &&
            //     isPropertyOf(item as PlainObject, fieldName.replace('Value', 'CurrencyValue'))
            // ) {
            //     filteredItem[columnTitle] = `${item[fieldName]} ${
            //         item[fieldName.replace('Value', 'Currency')].Name?.[i18n.language]
            //     }`;
            //     continue;
            // }

            if (
                fieldName.endsWith('Value') &&
                isPropertyOf(item as PlainObject, fieldName.replace('Value', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('Value', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (
                fieldName.endsWith('Amount') &&
                isPropertyOf(item as PlainObject, fieldName.replace('Amount', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('Amount', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (
                fieldName.endsWith('CostValue') &&
                isPropertyOf(item as PlainObject, fieldName.replace('CostValue', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('CostValue', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (column.ValueType?.includes('duration') || column.ValueType?.includes('seconds')) {
                filteredItem[columnTitle] = renderDuration(item[fieldName]);
                continue;
            }

            if (column.ValueType?.includes('datetime_range')) {
                filteredItem[columnTitle] = renderDateTimeRange(item[fieldName]);
                continue;
            }

            if (
                column.ValueType?.includes('dates_range') ||
                column.ValueType?.includes('daterange')
            ) {
                filteredItem[columnTitle] = renderDatesRange(item[fieldName]);
                continue;
            }

            if (column.ValueType?.includes('datetime')) {
                filteredItem[columnTitle] = renderDateTime(item[fieldName]);
                continue;
            }

            if (column.ValueType?.includes('[]time_range')) {
                filteredItem[columnTitle] = renderTimeRangeArray(item[fieldName]);
                continue;
            }

            if (column.ValueType?.includes('time_range')) {
                filteredItem[columnTitle] = renderTimeRange(item[fieldName]);
                continue;
            }

            if (column.ValueType?.includes('time') || column.ValueType?.includes('raw_time')) {
                filteredItem[columnTitle] = renderTime(item[fieldName]);
                continue;
            }

            if (column.ValueType?.includes('date')) {
                filteredItem[columnTitle] = renderDate(item[fieldName]);
                continue;
            }

            if (fieldName === 'Name') {
                filteredItem[columnTitle] = item.Name?.[i18n.language];
                continue;
            }

            if (isPlainObject(item[fieldName]) && isPropertyOf(item[fieldName], 'Name')) {
                filteredItem[columnTitle] = item[fieldName]?.Name?.[i18n.language];
                continue;
            }

            if (isBoolean(item[fieldName])) {
                filteredItem[columnTitle] = item[fieldName] ? 'Да' : 'Нет';
                continue;
            }

            filteredItem[columnTitle] = item[fieldName];
        }

        newData.push(filteredItem);
    }

    const dataWS = XLSX.utils.json_to_sheet(newData);

    // A workbook is the name given to an Excel file
    const wb = XLSX.utils.book_new(); // make Workbook of Excel

    // add Worksheet to Workbook
    XLSX.utils.book_append_sheet(wb, dataWS, metaName);

    // export Excel file
    XLSX.writeFile(wb, `${metaName}.xlsx`);
};
