import { Button, Card, Flex, Popover, Skeleton, Space, StepProps, Steps, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { InfoObjectWorkflow } from 'modules/services/backend-api/generated_models';
import { useMemo } from 'react';
import { useAsync, useAsyncRetry } from 'react-use';
import { LazyIcon } from 'smart/ui';
import { metaStore } from 'utils/store/MetaStore';

import './Stepper.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATE_TIME_LOCAL_FORMAT_DOT } from 'utils/helpers/dates';
import { ReloadOutlined } from '@ant-design/icons';

interface StepperProps {
    ids?: string[];
    filters?: string;
    title?: React.ReactNode;
    extra?: React.ReactNode;
    direction?: 'vertical' | 'horizontal';
    loading?: boolean;
    disableWrapper?: boolean;
}

function getContrastYIQ(hexcolor?: string) {
    if (!hexcolor) return 'white';

    const r = parseInt(hexcolor.substring(1, 3), 16);
    const g = parseInt(hexcolor.substring(3, 5), 16);
    const b = parseInt(hexcolor.substring(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000000' : '#ffffff';
}

export const StepperSkeleton: React.FC<{ vertical?: boolean }> = ({ vertical }) => (
    <Flex
        vertical={vertical}
        gap={vertical ? 30 : undefined}
        justify="space-between"
        align={vertical ? 'flex-start' : 'center'}
    >
        <Space>
            <Skeleton.Avatar active size="large" shape="circle" />
            <Skeleton.Input active size="large" />
        </Space>
        <Space>
            <Skeleton.Avatar active size="large" shape="circle" />
            <Skeleton.Input active size="large" />
        </Space>
        <Space>
            <Skeleton.Avatar active size="large" shape="circle" />
            <Skeleton.Input active size="large" />
        </Space>
    </Flex>
);

export const Stepper = observer<StepperProps>(
    ({ title, extra, loading, filters, ids, disableWrapper = false, direction = 'horizontal' }) => {
        const {
            i18n: { language }
        } = useTranslation();

        const serverItems = useAsyncRetry(async () => {
            const result = await metaStore.makeSelect({
                meta: 'InfoObjectWorkflows',
                sort: 'ChildIndex ASC',
                // filters: `Object_Id=in.[${ids.join(',')}]`
                filters: filters ?? `Id=in.[${(ids ?? []).join(',')}]`
            });

            return result?.objects as InfoObjectWorkflow[];
        }, [filters, ids]);

        const items = useMemo<StepProps[]>(() => {
            const lastIndex = (serverItems.value?.length ?? 0) - 1;

            return (
                serverItems.value?.map((source, index) => {
                    const isLastIndex = index === lastIndex;
                    const sourceStatus = source.Status;

                    // console.log(source);
                    // const sourceStatusGroup = source.Status?.ParentStatusGroup;
                    const author = source.CompletedBy?.Name?.[language];
                    const statusName = sourceStatus?.Name?.[language] ?? sourceStatus?.Code;
                    // const statusGroupName =
                    //     sourceStatusGroup?.Name?.[language] ?? sourceStatusGroup?.Code;
                    const sourceIcon = sourceStatus?.ParentStatusGroup?.Icon ?? 'BorderOutlined';

                    // const title = statusGroupName
                    //     ? `${statusGroupName}: ${statusName}`
                    //     : statusName;
                    const title = statusName;
                    const subTitle = source.IsCompleted
                        ? dayjs(source.CompletedAt).format(DATE_TIME_LOCAL_FORMAT_DOT)
                        : undefined;
                    const description = (
                        <Flex vertical>
                            <span style={{ fontSize: '12px', color: 'rgba(0,0,0,.45)' }}>
                                {isLastIndex ? subTitle : subTitle || ' '}
                            </span>
                            <span style={{ fontSize: '12px', color: 'rgba(0,0,0,.45)' }}>
                                {isLastIndex ? author : author || ' '}
                            </span>
                        </Flex>
                    );
                    // const status = source.IsCompleted
                    //     ? 'finish'
                    //     : serverItems[index - 1].IsCompleted
                    //     ? 'process'
                    //     : 'wait';
                    const status = source.IsCompleted ? 'finish' : 'wait';
                    // const disabled = !source.IsCompleted && !serverItems[index - 1].IsCompleted;
                    const disabled = !source.IsCompleted;
                    const color = disabled ? '#d3d3d3' : sourceStatus?.Color || '#3287D3';
                    const iconColor = getContrastYIQ(color);
                    const icon = (
                        <Tooltip
                            trigger="click"
                            title={source.Comment}
                            overlayStyle={{ maxWidth: 500 }}
                            destroyTooltipOnHide
                        >
                            <Button
                                disabled={disabled}
                                style={{
                                    backgroundColor: color,
                                    borderRadius: '50%'
                                }}
                                styles={{
                                    icon: { color: disabled ? `${iconColor}80` : iconColor }
                                }}
                                icon={<LazyIcon icon={sourceIcon} />}
                            />
                        </Tooltip>
                    );

                    return {
                        title: <span style={{ fontSize: 14 }}>{title}</span>,
                        icon,
                        description,
                        status,
                        // subTitle,
                        className: 'stepper_item',
                        // onClick,
                        disabled,
                        style: {
                            minWidth: 300,
                            '--stepper-color': disabled
                                ? 'rgba(0, 0, 0, 0.45)'
                                : 'rgba(0, 0, 0, 0.88)'
                        }
                    } as StepProps;
                }) ?? []
            );
        }, [language, serverItems.value]);

        return (
            <Card
                bordered={!disableWrapper}
                style={disableWrapper ? { boxShadow: 'none' } : undefined}
                size={'small'}
                title={title}
                extra={
                    <Flex gap={5} align="center">
                        {extra}
                        <Button icon={<ReloadOutlined />} onClick={serverItems.retry} />
                    </Flex>
                }
            >
                {loading || serverItems.loading ? (
                    <StepperSkeleton vertical={direction === 'vertical'} />
                ) : (
                    <Steps
                        className="stepper"
                        direction={direction}
                        items={items}
                        style={
                            direction === 'horizontal'
                                ? { maxWidth: '100%', overflowX: 'auto' }
                                : undefined
                        }
                        // current={1}
                        // percent={75}
                    />
                )}
            </Card>
        );
    }
);
