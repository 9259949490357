import { toJS } from 'mobx';

import { fieldEditRender, parseValueType } from 'smart/utils';
import { NumberField, SmartDateRangeField, SmartMultiSelectField } from 'smart/components';
import { i18n } from 'utils/i18n/i18n';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { FilterFieldValue, LocalFilterValueType } from '../../../types';

interface RenderValueProps {
    value: LocalFilterValueType;
    dataSource: IObjectWithId;
    onChange: (filterValue: LocalFilterValueType, changedFilterValue: FilterFieldValue) => void;
    // options: ColumnOptionsType | null;
    language: string;
    rootMeta: string;
}

export const renderValue = ({
    value,
    dataSource,
    onChange,
    // options,
    language,
    rootMeta
}: RenderValueProps) => {
    if (!value.operator) return value.value;

    const field = value?.field;
    const valueType = field?.ValueType ?? '';
    // const isChild = value?.field?.isChild;
    const rootInfo = toJS(metaStore.meta.get(rootMeta)?.info);
    // console.log(rootInfo);

    const { type, options } = parseValueType({ valueType, Options: field?.Options }, language, {
        root: dataSource,
        current: dataSource,
        info: rootInfo,
        self: value.value
    });

    // console.log(valueType);

    const isUtilization =
        type?.includes('utilization') || (type?.includes('int') && type?.includes('Utilization'));

    if (isUtilization) {
        return (
            <NumberField
                value={value.value}
                onChange={(v: any) => {
                    onChange(value, {
                        value: v
                    });
                }}
                min={0}
                style={{ width: 170 }}
                suffix={'%'}
            />
        );
    }

    if (
        value.operator.startsWith('current') ||
        value.operator.startsWith('this') ||
        value.operator.startsWith('last') ||
        value.operator.startsWith('past') ||
        value.operator === 'thisDayToNow' ||
        value.operator === 'nowToThisDay' ||
        value.operator === 'today' ||
        value.operator === 'tomorrow'
    ) {
        return null;
    }

    if (['empty', 'notEmpty'].includes(value.operator)) {
        return null;
    }

    if (['in_list', 'not_in_list'].includes(value.operator)) {
        if (options && options.ref) {
            return (
                <SmartMultiSelectField
                    metaName={options.ref.meta}
                    onChange={(v: any) => {
                        onChange(value, {
                            value: v
                        });
                    }}
                    value={value.value || []}
                    filters={options.filters}
                    treeOptions={{
                        groupKeys: options?.group ? [options.group] : undefined,
                        parentField: 'Parent'
                    }}
                />
            );
        }

        return null;
    }

    if (value.operator === 'dynamic_range') {
        return (
            <SmartDateRangeField
                value={value.value}
                onChange={(dynamicDateRange) => {
                    onChange(value, {
                        value: dynamicDateRange
                    });
                }}
            />
        );
        //     return (
        //         <DynamicDateRangeField
        //             mode="edit"
        //             withLabel={false}
        //             value={value.value}
        //             id={data.field.ColumnName}
        //             onChange={(dynamicDateRange) => {
        //                 onChange(value, {
        //                     value: dynamicDateRange
        //                 });
        //             }}
        //             popoverContainerHtmlId="table_filter_menu"
        //         />
        //     );
    }

    if (value.operator === 'between') {
        return (
            <SmartDateRangeField
                value={value.value}
                onChange={(dynamicDateRange) => {
                    onChange(value, {
                        value: dynamicDateRange
                    });
                }}
            />
        );
    }

    return fieldEditRender({
        data: value.value,
        onChange: (key: string, localValue: any) => {
            onChange(value, {
                value: localValue
            });
        },
        language: i18n.language,
        isEditable: true,
        metaFieldData: value.field,
        rootMeta,
        dataSource
    });
};
