import { TranslationOutlined } from '@ant-design/icons';
import { Dropdown, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useMedia } from 'react-use';
import { ButtonWithTooltips } from 'ui';
import { languagesStore } from 'utils/store/languages/languagesStore';
import { useBaseLanguage } from 'smart/utils/hooks';
import { useUserData } from 'modules/client/useAuthUser';

import './style.scss';

const initialLanguages = [
    {
        value: 'ru',
        label: 'Русский'
    },
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'tech',
        label: 'Technical'
    }
];

type LanguageSwitcherProps = {
    languageCode?: string;
    visible?: boolean;
};

export const LanguageSwitcher = observer(({ languageCode, visible = true }) => {
    const { i18n } = useTranslation();
    const isSmallTablet = useMedia('(max-width: 620px)');
    const isBigMobile = useMedia('(max-width: 480px)');
    const baseLanguage = useBaseLanguage();
    const { isPowerUser } = useUserData();

    const [storageLang, setStorageLang] = useLocalStorage('lang', 'ru');

    useEffect(() => {
        if (storageLang) {
            i18n.changeLanguage(storageLang as string);
        } else {
            setStorageLang('ru');
            i18n.changeLanguage('ru');
        }
    }, [i18n, setStorageLang, storageLang]);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setStorageLang(lang);
    };

    useEffect(() => {
        if (languageCode && storageLang !== 'tech') {
            handleLanguageChange(languageCode.toLowerCase());
        }
    }, [languageCode]);

    // useEffect(() => {
    //     // debugger;

    //     if (languageCode && storageLang !== 'tech') {
    //         handleLanguageChange(languageCode.toLowerCase());
    //     }
    // }, [languageCode]);

    const languages = useMemo(() => {
        if (languagesStore.languages?.length) {
            const languages = languagesStore.languages.map((language) => ({
                type: 'item',
                value: language.Code.toLowerCase(),
                onClick: isSmallTablet
                    ? () => {
                          handleLanguageChange(language.Code.toLowerCase());
                      }
                    : null,
                label:
                    language.Name?.[storageLang === 'tech' ? baseLanguage : i18n.language] ??
                    language.Name?.[baseLanguage] ??
                    language.Code
            }));

            if (isPowerUser) {
                languages.push({
                    value: 'tech',
                    label: 'Technical'
                });
            }

            return languages;
        }

        const copy = JSON.parse(JSON.stringify(initialLanguages));

        if (!isPowerUser) {
            copy.pop();
        }

        return copy;
    }, [
        i18n.language,
        storageLang,
        baseLanguage,
        isSmallTablet,
        isPowerUser,
        languagesStore.languages
    ]);

    return (
        <>
            {visible && (
                <Dropdown
                    open={!isSmallTablet ? false : undefined}
                    trigger={['click']}
                    menu={{ items: languages }}
                >
                    <ButtonWithTooltips
                        size={isBigMobile ? 'large' : 'middle'}
                        id="language-switcher"
                        type="text"
                        icon={<TranslationOutlined />}
                    >
                        {!isSmallTablet && (
                            <Select
                                dropdownStyle={{ width: 'max-content' }}
                                className="header__languages"
                                value={storageLang}
                                options={languages}
                                onChange={handleLanguageChange}
                                size={isBigMobile ? 'large' : 'middle'}
                            />
                        )}
                    </ButtonWithTooltips>
                </Dropdown>
            )}
        </>
    );
});
