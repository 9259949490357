import { useEffect, useState } from 'react';

// const CHECK_INTERVAL = 60000 * 5; // Проверять раз в 5 минут
const CHECK_INTERVAL = 60000; // Проверять раз в минуту

export const useVersionChecker = () => {
    const [isNewVersion, setIsNewVersion] = useState(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        let currentHash = sessionStorage.getItem('appVersionHash');

        const checkForUpdate = async (firstCall?: boolean) => {
            // console.log('!!!!!!');
            try {
                // const response = await fetch('/.vite/manifest.json', { cache: 'no-store' });
                const response = await fetch('/.vite/manifest.json', {
                    method: 'HEAD', // Загружаем только заголовки
                    cache: 'no-store' // Обходим кэширование
                });
                // const manifest = await response.json();
                const newHash = response.headers.get('ETag') ?? ''; // Получаем ETag
                // const newHash = manifest['index.html']?.file; // Путь к главному файлу

                if (!currentHash || firstCall) {
                    sessionStorage.setItem('appVersionHash', newHash);
                    currentHash = newHash;
                } else if (newHash !== currentHash) {
                    setIsNewVersion(true);
                    sessionStorage.setItem('appVersionHash', newHash);
                }
            } catch (error) {
                console.error('App version check error:', error);
            }
        };

        checkForUpdate(true);
        const interval = setInterval(checkForUpdate, CHECK_INTERVAL);
        setIntervalId(interval);

        // return () => clearInterval(interval);
    }, []);

    const reset = () => {
        if (intervalId) clearInterval(intervalId);
    };

    useEffect(() => reset, [intervalId]);

    return { isNewVersion, setIsNewVersion, reset };
};
