import { Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ButtonWithTooltips } from 'ui';
import { routeStore, useStoreNavigate } from 'utils/store';

interface UniversalBoundaryProps extends React.PropsWithChildren {
    title?: string;
    subTitle?: string;
    status?: ResultStatusType;

    enableTryAgain?: boolean;
    onTryAgain?: React.MouseEventHandler | (() => void);

    enableGoBackOrGoHome?: boolean;
    replaceGoBackToGoHome?: boolean;
    enableLogin?: boolean;
    onLoginButtonPress?: () => void;
    extra?: React.ReactNode[]
}

export const UniversalBoundary: React.FC<UniversalBoundaryProps> = ({
    title,
    subTitle,
    onTryAgain,
    children,
    status = 'error',
    enableTryAgain = false,
    enableGoBackOrGoHome = true,
    replaceGoBackToGoHome = false,
    enableLogin = false,
    onLoginButtonPress = () => {},
    extra: extraFromProps = []
}) => {
    const navigate = useStoreNavigate();
    const { pathname, search, state } = useLocation();
    const { t } = useTranslation();

    const goBack = () => {
        routeStore.remove({ pathname, search, state });
        const lastRoute = toJS(routeStore.routes.at(-1));

        if (lastRoute)
            navigate(
                { pathname: lastRoute?.pathname, search: lastRoute?.search },
                { state: lastRoute?.state }
            );
        else navigate('/');
        // navigate(-1);
    };

    const goHome = () => {
        routeStore.remove({ pathname, search, state });
        navigate('/');
    };

    const extraGoHome = [
        <ButtonWithTooltips id="home" tooltipTitle={t('home')} onClick={goHome}>
            {t('home')}
        </ButtonWithTooltips>
    ];

    const extraGoBack = [
        <ButtonWithTooltips id="back" tooltipTitle={t('back')} onClick={goBack}>
            {t('back')}
        </ButtonWithTooltips>
    ];

    const extraTryAgain = [
        <ButtonWithTooltips
            id="try_again"
            type="default"
            tooltipTitle={t('try_again')}
            onClick={onTryAgain}
        >
            {t('try_again')}
        </ButtonWithTooltips>
    ];

    const extraLogin = [
        <ButtonWithTooltips
            id="go_to_login"
            type="default"
            tooltipTitle={t('Войти')}
            onClick={() => {
                onLoginButtonPress();
            }}
        >
            {t('Войти')}
        </ButtonWithTooltips>
    ];

    const extra = [
        ...(enableGoBackOrGoHome ? (replaceGoBackToGoHome ? extraGoHome : extraGoBack) : []),
        ...(enableTryAgain ? extraTryAgain : []),
        ...(enableLogin ? extraLogin : []),
        ...extraFromProps
    ];

    return (
        <Result
            status={status}
            title={title || t(String(status))}
            subTitle={subTitle}
            extra={extra}
        >
            {/* {children} */}
        </Result>
    );
};
