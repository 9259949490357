export function toHexAlpha(value: number): string {
    if (value < 0 || value > 100) {
        throw new Error('Число должно быть в диапазоне от 0 до 100');
    }

    // Переводим диапазон 0-100 в 0-255
    const alpha = Math.round((value / 100) * 255);

    // Преобразуем в HEX и добавляем ведущий ноль при необходимости
    return alpha.toString(16).padStart(2, '0').toUpperCase();
}
