import { isDefined } from 'is-lite/exports';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Input } from 'antd';

import { useDebounce, useMountedState, useUpdateEffect } from 'react-use';

import './PhoneField.scss';
import { Variant } from 'antd/es/config-provider';

interface PhoneFieldProps {
    value: string | null | undefined;
    readOnly?: boolean;
    onChange?: (newValue: string | undefined | null) => void;
    variant?: Variant;
}

// const countryCodes = {
//     "1": "+1 (999) 999-9999", // США, Канада
//     "7": "+7 (999) 999-99-99", // Россия, Казахстан
//     "33": "+33 9 99 99 99 99", // Франция
//     "44": "+44 9999 999999", // Великобритания
//     "49": "+49 999 99999999", // Германия
//     "86": "+86 999-9999-9999", // Китай
//   };

// const detectCountryMask = (rawValue: string) => {
//     const possibleCodes = Object.keys(countryCodes).sort((a, b) => b.length - a.length);
//     for (let code of possibleCodes) {
//       if (rawValue.startsWith(code)) {
//         return countryCodes[code];
//       }
//     }
//     return "+999 (99) 999-99-99"; // Маска по умолчанию
//   };

export const PhoneField = observer(({ value, onChange, readOnly, variant }: PhoneFieldProps) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);
    const [mask, setMask] = useState('+999999999999');

    const isMounted = useMountedState();

    const valueWithMask = useRef(value);

    useUpdateEffect(() => {
        if (onChange && valueWithMask.current === undefined) setInnerValue(undefined);
        else if (
            onChange &&
            valueWithMask.current !== innerValue &&
            valueWithMask.current &&
            innerValue
        )
            setInnerValue(valueWithMask.current);
    }, [valueWithMask.current]);

    useDebounce(
        () => {
            if (isMounted() && onChange && isDefined(innerValue) && innerValue !== value) {
                onChange(innerValue?.replaceAll(`_`, '') ?? undefined);
                valueWithMask.current = innerValue;

                // учитываем '+'
                if (innerValue?.replaceAll('_', '').length === 12) setMask('+99999999999');
                else setMask('+999999999999');
            }
        },
        400,
        [innerValue]
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: newValue } = e.target;

        // const rawValue = newValue.replace(/\D/g, '');

        // // Динамическое обновление маски для поддержки 1-3 цифр кода страны
        // if (rawValue.length) setMask('+9 (999) 999-99-99');
        // else if (rawValue.length <= 3) setMask('+99 (999) 999-99-99');
        // else setMask('+999 (999) 99-99-99');

        if (newValue === '') {
            setInnerValue(undefined);
            onChange(undefined);
            valueWithMask.current = undefined;
        } else {
            setInnerValue(newValue);
        }
    };

    const v = innerValue ?? valueWithMask.current;
    const readOnlyV = valueWithMask.current;

    return (
        <InputMask
            mask={mask}
            alwaysShowMask={false}
            value={(readOnly ? readOnlyV : v) ?? ''}
            onChange={handleChange}
            readOnly={readOnly}
        >
            {/* {(inputProps) => <Input {...inputProps} placeholder={t('no_value') as string} />} */}
            {() => (
                <Input
                    value={readOnly ? readOnlyV : v}
                    readOnly={readOnly}
                    placeholder={t('no_value') as string}
                    variant={variant}
                />
            )}
        </InputMask>
    );
});
