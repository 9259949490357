/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, ConfigProvider, Dropdown, Flex, MenuProps, Radio, Space } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import { isFunction } from 'is-lite/exports';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import BaseTable, { Column, RowKey } from 'react-base-table';
import { useUpdateEffect, useWindowSize } from 'react-use';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

// import { EmptyMarker } from 'ui';
import { Loader } from 'ui/Loader/Loader';

import { buildTreeWithGrouping } from 'smart/utils';
import { MetaField } from 'modules/services/backend-api/generated_info';

import 'react-base-table/styles.css';
import './BaseTable.css';
import { useTranslation } from 'react-i18next';
import { LazyIcon } from 'smart/ui';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    StepBackwardOutlined,
    StepForwardOutlined
} from '@ant-design/icons';
import { useActivate } from 'react-activation';
import { BaseTableExpandIcon } from './components';

type TColumnType = ColumnType<IObjectWithId>;

type BaseTableProps = {
    className?: string;
    loading?: boolean;
    columns: TColumnType[];
    footerRender?: () => React.ReactNode;
    titleRender?: () => React.ReactNode;
    data: IObjectWithId[];
    selectedRows?: IObjectWithId[];
    onRowSelectionChange?: (selectedRows: IObjectWithId[]) => void;
    selectable?: boolean;
    defaultSortOrder?: {
        descend?: string[];
        ascend?: string[];
    };
    doubleClickable?: boolean;
    onDoubleClick?: (row: any, index: number) => (() => void) | undefined;
    editable?: boolean;
    viewMode?: 'inline' | 'modal';
    handleDataSourceChanged?: (row: IObjectWithId) => void;
    // scroll: Record<string, any>;
    scroll: TableProps['scroll'];
    groupFields: MetaField[];
    parentFieldName: string;
    fixedFirstColumnDataIndex?: string;
    rowContextMenuItems?: MenuProps['items'];
    noDataRenderer?: () => React.ReactNode;
    selectionType?: 'checkbox' | 'radio';
};

type CellRendererFunctionProps = {
    cellData: any;
    column: Column;
    rowData: any;
    rowIndex: number;
    isScrolling: boolean;
    selectedIdsMap: Record<string, boolean>;
};

// type CellRendererFunction = (props: ) => React.ReactNode;

// Memoized cell component to prevent unnecessary renders
const MemoizedCellRenderer = memo(
    ({
        cellData,
        column,
        rowData,
        rowIndex,
        isScrolling,
        cellRerender,
        onChange
    }: Record<string, any>) => {
        if (!column.render) {
            return null;
        }

        return column.render(
            cellData,
            rowData as IObjectWithId,
            rowIndex,
            onChange
        ) as React.ReactNode;
    },
    (prevProps, nextProps) => {
        // return false;

        const prevRerender = prevProps?.cellRerender;
        const nextRerender = nextProps?.cellRerender;

        const noNeedRerender =
            prevProps.rowIndex === nextProps.rowIndex && prevRerender === nextRerender;

        // Prevents re-render if cellData and other relevant props don't c hange
        return noNeedRerender;
    }
);

// const ROW_HEIGHT = 38;
// const ROW_HEIGHT_CONDENSED = 32;
// const ROW_HEIGHT_NORMAL = 37;
// const ROW_HEIGHT_EXPANDED = 45;

interface RowClickEventProps extends React.MouseEvent<HTMLElement> {
    ctrlKey: boolean;
    shiftKey: boolean;
}
export enum RowHeight {
    'NORMAL' = 38,
    'EXPANDED' = 45,
    'CONDENSED' = 32.5
}
type DensityType = 'NORMAL' | 'EXPANDED' | 'CONDENSED';
const ROWS_KEY_COLUMN_NAME = 'Id';

function flattenTree(allRowsWithChildren: any[]) {
    const result: any[] = [];

    function recursiveFlatten(rows: any[], parentId: any) {
        rows.forEach((row) => {
            result.push({
                ...row,
                _parentId: parentId
            }); // Add the current row
            if (row.children && row.children.length > 0) {
                recursiveFlatten(row.children, row[ROWS_KEY_COLUMN_NAME]); // Recursively process the children
            }
        });
    }

    recursiveFlatten(allRowsWithChildren, null); // Start recursion with the root array
    return result;
}

const desaturateHexColor = (hex: string, factor: number = 3): string => {
    // Преобразуем HEX в RGB
    const hexToRgb = (hex: string): [number, number, number] => {
      let cleanHex = hex.replace(/^#/, "");
      if (cleanHex.length === 3) {
        cleanHex = cleanHex
          .split("")
          .map((char) => char + char)
          .join("");
      }
      const bigint = parseInt(cleanHex, 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };
  
    // Преобразуем RGB в HEX
    // const rgbToHex = (r: number, g: number, b: number): string =>
    //   `#${[r, g, b]
    //     .map((x) => Math.min(255, Math.max(0, x)).toString(16).padStart(2, "0"))
    //     .join("")}`;
  
    // Функция тускления (приближение к белому)
    const desaturate = (r: number, g: number, b: number, factor: number): [number, number, number] => {
      return [
        Math.min(255, r + ((255 - r) * factor) / 10),
        Math.min(255, g + ((255 - g) * factor) / 10),
        Math.min(255, b + ((255 - b) * factor) / 10),
      ];
    };
  
    const [r, g, b] = hexToRgb(hex);
    const [dr, dg, db] = desaturate(r, g, b, factor);

    // console.log(dr, dg, db);
    // return rgbToHex(dr, dg, db);
    return `rgb(${dr}, ${dg}, ${db})`;
};

// TODO: надо бы причесать
export const Table = memo(
    ({
        columns,
        data,
        footerRender,
        titleRender,
        loading,
        selectable,
        selectedRows,
        onRowSelectionChange,
        onDoubleClick,
        doubleClickable,
        defaultSortOrder = { descend: ['CreatedAt'] },
        editable = false,
        viewMode = 'inline',
        scroll,
        handleDataSourceChanged,
        groupFields,
        parentFieldName,
        fixedFirstColumnDataIndex,
        rowContextMenuItems,
        noDataRenderer,
        selectionType = 'checkbox'
    }: BaseTableProps) => {
        const { t } = useTranslation();

        const { height: windowHeight, width: windowWidth } = useWindowSize();
        const tableDensity = (metaStore.meta.get('all')?.params?.TABLE_DENSITY ??
            'NORMAL') as DensityType;

        const config = useContext(ConfigProvider.ConfigContext);
        const { theme } = config;
        const colorPrimary = theme?.token?.colorPrimary;

        const parentRef = useRef<HTMLDivElement | null>(null);
        const tableRef = useRef<BaseTable<IObjectWithId>>(null);

        const [parentWidth, setParentWidth] = useState<number>(0);

        const [selectedIdsMap, setSelectedIdsMap] = useState<Record<string, boolean>>({});

        const [contextMenuParam, setContextMenuParam] = useState<{
            visible: boolean;
            x: number;
            y: number;
            row?: IObjectWithId;
            rowIndex?: number;
            columnName?: string;
            cell?: HTMLElement;
            // columnValue?: any;
        }>({
            visible: false,
            x: -200,
            y: -200
        });
        // const [contextMenu, setContextMenu] = useState<React.ReactNode>(null);

        const [rowSorting, setRowSorting] = useState<{
            [keys: string]: 'ascend' | 'descend' | undefined;
        }>();

        const [cellRerender, setCellRerender] = useState(false);
        // const [expandedRowKeys, setExpandedRowKeys] = useState<RowKey[]>([]);

        const getInitialRowSorting = useCallback(() => {
            const res = {} as { [keys: string]: 'ascend' | 'descend' | undefined };

            defaultSortOrder?.descend?.forEach((fieldName) => {
                res[fieldName] = 'descend';
            });
            defaultSortOrder?.ascend?.forEach((fieldName) => {
                res[fieldName] = 'ascend';
            });

            return res;
        }, [defaultSortOrder?.ascend, defaultSortOrder?.descend]);

        useEffect(() => {
            if (!rowSorting || !Object.keys(rowSorting).length) {
                setRowSorting(getInitialRowSorting());
            }
        }, [getInitialRowSorting, rowSorting]);

        const setSelectedRowMap = (rows: IObjectWithId[]) => {
            if (!rows) {
                return;
            }

            const map: Record<string, boolean> = {};

            for (let i = 0; i < rows?.length; i++) {
                map[rows[i].Id] = true;
            }

            setSelectedIdsMap(map);
        };

        useEffect(() => {
            setSelectedRowMap(selectedRows || []);
        }, [selectedRows]);

        const selectionCellRenderer = useCallback(
            (props: CellRendererFunctionProps) => {
                const { rowData, container } = props;

                if (rowData.isHardGroup) {
                    return <></>;
                }

                const rowId = rowData[ROWS_KEY_COLUMN_NAME];

                const selectedIds = container?.props?.rowProps?.selectedIdsMap || selectedIdsMap;
                const innerSelectedRows = container?.props?.rowProps?.selectedRows || selectedRows;
                const checked = selectedIds[rowId];

                const Component = selectionType === 'radio' ? Radio : Checkbox;

                return (
                    <div>
                        <Component
                            // type={selectionType}
                            // disabled={rowData.DeletedAt}
                            checked={checked || undefined}
                            disabled={rowData.isHardGroup}
                            onClick={(e) => e.stopPropagation()}
                            onChange={
                                rowData.isHardGroup
                                    ? () => {}
                                    : (e) => {
                                          const checked = e.target.checked;

                                          if (checked) {
                                              if (innerSelectedRows && onRowSelectionChange) {
                                                  if (selectionType === 'radio') {
                                                      onRowSelectionChange([rowData]);
                                                  } else {
                                                      onRowSelectionChange([
                                                          ...innerSelectedRows,
                                                          rowData
                                                      ]);
                                                  }
                                              } else if (selectionType === 'radio') {
                                                  setSelectedIdsMap(() => ({
                                                      [rowId]: true
                                                  }));
                                              } else {
                                                  setSelectedIdsMap((prev) => ({
                                                      ...prev,
                                                      [rowId]: true
                                                  }));
                                              }
                                          } else if (innerSelectedRows && onRowSelectionChange) {
                                              if (selectionType === 'radio') {
                                                  onRowSelectionChange([]);
                                                  setSelectedRowMap([]); // !!
                                              } else {
                                                  const newSelected = innerSelectedRows.filter(
                                                      (row) => row[ROWS_KEY_COLUMN_NAME] !== rowId
                                                  );

                                                  onRowSelectionChange(newSelected);
                                                  setSelectedRowMap(newSelected); // !!
                                              }
                                          } else if (selectionType === 'radio') {
                                              setSelectedIdsMap(() => ({ [rowId]: false }));
                                          } else {
                                              setSelectedIdsMap((prev) => {
                                                  const prevSelection = { ...prev };
                                                  prevSelection[rowId] = false;
                                                  return prevSelection;
                                              });
                                          }
                                      }
                            }
                        />
                    </div>
                );
            },
            [onRowSelectionChange, selectedIdsMap, selectedRows, selectionType]
        );

        // Use effect to listen for parent size changes
        useEffect(() => {
            const resizeObserver = new ResizeObserver(() => {
                if (parentRef.current) {
                    const width = parentRef.current.getBoundingClientRect().width;
                    if (width) {
                        setParentWidth(width);
                    }
                }
            });

            if (parentRef.current) {
                resizeObserver.observe(parentRef.current);
                const width = parentRef.current.getBoundingClientRect().width;
                if (width) {
                    setParentWidth(width);
                }
                // setParentWidth(parentRef.current.getBoundingClientRect().width); // initial width
            }

            return () => {
                resizeObserver.disconnect();
            };
        }, []);

        // Memoize the columns to avoid recalculating if columns haven't changed
        const memoizedColumns = useMemo(() => {
            let leftOffset = selectable ? 40 : 0; // Accumulate width for left-sticky columns
            let rightOffset = 0; // Accumulate width for right-sticky columns
            // const totalWidth = columns.reduce(
            //     (acc, col) => (acc + (col.width as number) ? (col.width as number) : 100),
            //     0
            // );

            return columns.map((col) => {
                if (!col.width) {
                    col.width = 100;
                }

                // If the column is fixed to the left, calculate the left position
                if (col.fixed === 'left') {
                    const styles = {
                        position: 'sticky',
                        left: leftOffset,
                        zIndex: 2,
                        // backgroundColor: '#f8f8f8',
                        backgroundColor: 'inherit',
                        borderRight: '1px solid #d9d9d9'
                    };
                    leftOffset += col.width as number; // Add this column's width to the left offset
                    return { ...col, styles };
                }

                // If the column is fixed to the right, calculate the right position
                if (col.fixed === 'right') {
                    const styles = {
                        position: 'sticky',
                        right: rightOffset,
                        zIndex: 2,
                        backgroundColor: '#f8f8f8',
                        borderLeft: '1px solid #d9d9d9'
                    };
                    rightOffset += col.width as number; // Add this column's width to the right offset
                    return { ...col, styles };
                }

                return col;
            });
        }, [selectable, columns]);

        // const MIN_HEIGHT = 300;

        const scrollOptions = useMemo(() => {
            // 100% - 22*13em
            const y = windowHeight - 20 * 13.48;
            const x = parentWidth;

            // console.log('scroll:', scroll);
            return {
                y, // : y < MIN_HEIGHT ? MIN_HEIGHT : y,
                x,
                ...scroll
            };
        }, [windowHeight, scroll, parentWidth]);
        // }, [columns.length, windowHeight, scroll, parentWidth]);

        const renderOverlay = () => {
            if (loading)
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            margin: '0',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Loader />
                    </div>
                );

            return null;
        };

        // const sortedData = data;

        const sortedData = useMemo(() => {
            if (rowSorting) {
                const sortedFields = Object.keys(rowSorting);

                if (sortedFields.length > 0) {
                    // умеем сортировать только по одному полю
                    const sortedField = sortedFields[0];
                    const sortedColumn = columns.find((col) => col.dataIndex === sortedField);

                    const sorter = sortedColumn?.sorter;
                    const getCompare =
                        sorter && typeof sorter !== 'boolean' ? sorter.getCompare : null;

                    if (getCompare && isFunction(getCompare)) {
                        const compareFunction = getCompare(sortedField);

                        if (isFunction(compareFunction)) {
                            const sortedData = data.sort((a, b) => {
                                let aGreaterThenB = compareFunction(a, b);

                                if (rowSorting[sortedField] === 'descend') {
                                    aGreaterThenB *= -1;
                                }

                                return aGreaterThenB;
                            });

                            return sortedData;
                        }
                    }
                }
            }

            return data;
        }, [data, rowSorting, columns]);

        const treeData = useMemo(() => {
            const result = buildTreeWithGrouping(
                sortedData,
                parentFieldName,
                groupFields.map((f) => f.FieldName),
                fixedFirstColumnDataIndex ?? 'Name'
            );

            return [...result];
        }, [sortedData, rowSorting, parentFieldName, groupFields, fixedFirstColumnDataIndex]);
        // }, [sortedData, parentFieldName, groupFields, fixedFirstColumnDataIndex]);

        // console.log('1.1', treeData);

        const handleSort = useCallback(
            (
                fieldName: string,
                sorting: {
                    [keys: string]: 'ascend' | 'descend' | undefined;
                },
                order?: 'asc' | 'desc' | undefined
            ) => {
                if (order) {
                    setRowSorting({
                        [fieldName]: order === 'asc' ? 'ascend' : 'descend'
                    });
                    return;
                }
                let newRowSorting: {
                    [keys: string]: 'ascend' | 'descend' | undefined;
                } = {};

                const FIRST_DEFAULT_SORTING_WHEN_CLICK = 'ascend';
                const SECOND_DEFAULT_SORTING_WHEN_CLICK = 'descend';

                if (sorting) {
                    const currentSorting = sorting[fieldName];

                    if (currentSorting) {
                        if (currentSorting === FIRST_DEFAULT_SORTING_WHEN_CLICK) {
                            newRowSorting = {
                                [fieldName]: SECOND_DEFAULT_SORTING_WHEN_CLICK
                            };
                        }

                        if (currentSorting === SECOND_DEFAULT_SORTING_WHEN_CLICK) {
                            // TODO: сбросить на дефолтную?
                            // там выше сбрасывается, тут норм
                            newRowSorting = {};
                        }
                    } else {
                        newRowSorting = {
                            [fieldName]: FIRST_DEFAULT_SORTING_WHEN_CLICK
                        };
                    }
                } else {
                    newRowSorting = {
                        [fieldName]: FIRST_DEFAULT_SORTING_WHEN_CLICK
                    };
                }

                setRowSorting(newRowSorting);
            },
            []
        );

        useEffect(() => {
            setCellRerender((prev) => !prev);
        }, [rowSorting, treeData, editable]);

        const onClick = useCallback(
            ({
                rowData,
                rowIndex,
                rowKey,
                event
            }: {
                rowData: IObjectWithId;
                rowIndex: number;
                rowKey: RowKey;
                event: RowClickEventProps;
            }) => {
                // console.log('event:', event);

                if (rowData?.isHardGroup) {
                    // TODO: тут код который выбирает целиком группу ?
                    return;
                }

                if (event?.ctrlKey) {
                    const rowId = rowData[ROWS_KEY_COLUMN_NAME];
                    const selectedIds = selectedIdsMap;
                    const innerSelectedRows = selectedRows;

                    const checked = selectedIds[rowId];

                    if (!checked) {
                        if (innerSelectedRows && onRowSelectionChange) {
                            onRowSelectionChange([...innerSelectedRows, rowData]);
                        } else {
                            setSelectedIdsMap((prev) => {
                                const newSelectedIdMap = {
                                    ...prev,
                                    [rowId]: true
                                };

                                return newSelectedIdMap;
                            });
                        }
                    } else if (innerSelectedRows && onRowSelectionChange) {
                        const newSelected = innerSelectedRows.filter(
                            (row) => row[ROWS_KEY_COLUMN_NAME] !== rowId
                        );
                        onRowSelectionChange(newSelected);
                        setSelectedRowMap(newSelected); // !!
                    } else {
                        setSelectedIdsMap((prev) => {
                            const prevSelection = { ...prev };
                            prevSelection[rowId] = false;
                            return prevSelection;
                        });
                    }
                } else if (event?.shiftKey) {
                    const rowId = rowData[ROWS_KEY_COLUMN_NAME];
                    // const selectedIds = selectedIdsMap;
                    const innerSelectedRows = selectedRows;

                    if (!innerSelectedRows || innerSelectedRows.length === 0) {
                        if (onRowSelectionChange) {
                            onRowSelectionChange([rowData] as IObjectWithId[]);
                        } else {
                            // No previous selection, behave like a normal click
                            setSelectedIdsMap({
                                [rowId]: true
                            });
                        }
                    } else {
                        // Find the first and last selected row indexes
                        const selectedRowIndexes = innerSelectedRows.map((row) =>
                            data.findIndex(
                                (d) => d[ROWS_KEY_COLUMN_NAME] === row[ROWS_KEY_COLUMN_NAME]
                            )
                        );
                        const minSelectedIndex = Math.min(...selectedRowIndexes);
                        const maxSelectedIndex = Math.max(...selectedRowIndexes);

                        // Get the clicked row index
                        const clickedRowIndex = rowIndex;

                        // Determine the range of rows to select
                        const rangeStart = Math.min(clickedRowIndex, minSelectedIndex);
                        const rangeEnd = Math.max(clickedRowIndex, maxSelectedIndex);

                        // Create the new selection
                        const newSelectedRows = data.slice(rangeStart, rangeEnd + 1);

                        if (onRowSelectionChange) {
                            onRowSelectionChange(newSelectedRows);
                        } else {
                            // Update selection state
                            setSelectedIdsMap((prev) => {
                                const newSelectedIdMap = { ...prev };
                                newSelectedRows.forEach((row) => {
                                    newSelectedIdMap[row[ROWS_KEY_COLUMN_NAME]] = true;
                                });
                                return newSelectedIdMap;
                            });
                        }
                    }
                } else {
                    const rowId = rowData[ROWS_KEY_COLUMN_NAME];
                    const selectedIds = selectedIdsMap;
                    const innerSelectedRows = selectedRows;

                    const checked = selectedIds[rowId];

                    if (!checked) {
                        if (innerSelectedRows && onRowSelectionChange) {
                            onRowSelectionChange([rowData]);
                        } else {
                            setSelectedIdsMap((prev) => ({
                                [rowId]: true
                            }));
                        }
                    } else if (innerSelectedRows && onRowSelectionChange) {
                        onRowSelectionChange(innerSelectedRows.length > 1 ? [rowData] : []);
                        setSelectedRowMap(innerSelectedRows.length > 1 ? [rowData] : []);
                    } else {
                        setSelectedIdsMap((prev) => ({
                            [rowId]: Object.keys(prev).length > 1
                        }));
                    }
                }
            },
            [data, selectedRows, selectedIdsMap, onRowSelectionChange]
        );

        const onDoubleClickOrTap = useCallback(
            ({
                rowData,
                rowIndex,
                rowKey
            }: {
                rowData: IObjectWithId;
                rowIndex: number;
                rowKey: RowKey;
            }) => {
                const doubleClickFunction = onDoubleClick?.(rowData, rowIndex);
                if (doubleClickFunction) doubleClickFunction();
            },
            []
        );

        const isDoubleClickable = doubleClickable && !!onDoubleClick;
        const [lastTap, setLastTap] = useState(0);
        const timer = useRef(null);

        const rowEventHandlers = useMemo(
            () => ({
                onClick: (props) => {
                    if (props.event.detail === 1) {
                        // Первый клик: ждем, будет ли второй
                        timer.current = setTimeout(() => {
                            if (props.event.detail === 1) {
                                onClick(props);
                            }
                        }, 210);
                    } else if (timer.current) {
                        // если был первый клик не отрабатываем
                        clearTimeout(timer.current);
                    }
                },

                onTouchStart: (props) => {
                    const now = Date.now();
                    if (now - lastTap < 210) {
                        onDoubleClickOrTap(props);
                    }
                    setLastTap(now);
                },

                onDoubleClick: isDoubleClickable ? onDoubleClickOrTap : undefined,

                onContextMenu: (props: Record<string, any>) => {
                    const { rowData, rowIndex, rowKey, event } = props;

                    event.preventDefault(); // Prevent the default browser context menu
                    event.stopPropagation(); // Stop propagation to avoid conflicts

                    if (rowData.isHardGroup) {
                        // TODO: тут код который строит особую контексное меню для группы ?
                        return;
                    }
                    // console.log('event:', event);

                    const cell = event.target;

                    if (cell) {
                        setContextMenuParam({
                            visible: true,
                            x: event.clientX,
                            y: event.clientY,
                            row: rowData,
                            rowIndex,
                            cell
                        });

                        const rowId = rowData[ROWS_KEY_COLUMN_NAME];
                        const selectedIds = selectedIdsMap;
                        const innerSelectedRows = selectedRows;

                        const checked = selectedIds[rowId];

                        if (!checked) {
                            if (innerSelectedRows && onRowSelectionChange) {
                                onRowSelectionChange([rowData]);
                            } else {
                                setSelectedIdsMap((prev) => {
                                    const newSelectedIdMap = {
                                        [rowId]: true
                                    };

                                    return newSelectedIdMap;
                                });
                            }
                        }
                    }
                }
            }),
            [doubleClickable, onDoubleClick, onClick, lastTap]
        );

        const expandColumnKey =
            memoizedColumns && memoizedColumns.length > 0 ? memoizedColumns[0].key : undefined;

        const getColumnWidth = useCallback(
            (columnWidth: number) => {
                const notHiddenColumns = memoizedColumns.filter((col) => {
                    const columnWidth =
                        typeof col.width === 'number' ? col.width : parseFloat(col.width ?? '0');

                    return !col.hidden && columnWidth > 100;
                });
                const columnsCount = notHiddenColumns.length;

                // console.log(scrollOptions.x, columnsCount, scrollOptions.x / columnsCount);

                return columnWidth < 100
                    ? columnWidth
                    : columnsCount * 200 > scrollOptions.x
                    ? columnWidth || 100
                    : scrollOptions.x / (columnsCount || 1);
            },
            [memoizedColumns, scrollOptions.x]
        );

        const rowsGlobalContextMenu = rowContextMenuItems ? (
            <Dropdown
                menu={
                    contextMenuParam.visible
                        ? {
                              items: rowContextMenuItems.map((i) => {
                                   if (!i) return i;

                                    const item = { ...i, onClick: () => {
                                        if (i.onClick) {
                                            const value =
                                                contextMenuParam.cell?.innerText;
                                            const row = contextMenuParam.row;
                                            i.onClick(value, row);
                                        }
                                    }}

                                    switch (item.key) {
                                        case 'edit':
                                            if (contextMenuParam.row?.DeletedAt) {
                                                return { ...item, disabled: true };
                                            }
                                            return item;
                                        default:
                                            return item;
                                    }
                              })
                          }
                        : undefined
                }
                trigger={['contextMenu']}
                open={contextMenuParam.visible}
                onOpenChange={(open) => {
                    if (!open) {
                        setContextMenuParam({
                            visible: false,
                            x: -200,
                            y: -200
                        });
                    }
                }}
                overlayStyle={{
                    position: 'absolute',
                    top: contextMenuParam.y,
                    left:
                        // This fix error then popup going out from window borders and break page scroll after that
                        // xCoord - delta(xCoord, (windowWitdth - popoverWidth)) - customWindowBorderGap
                        contextMenuParam.x > windowWidth - 200
                            ? contextMenuParam.x - (contextMenuParam.x - (windowWidth - 200)) - 13
                            : contextMenuParam.x
                }}
            >
                <div></div>
            </Dropdown>
        ) : null;

        const tableBodyElement = tableRef.current?.tableNode?.querySelector(
            '.BaseTable__body'
        ) as HTMLDivElement;

        const [recalcScroll, setRecalcScroll] = useState(false);
        const [noScroll, setNoScroll] = useState(true);

        useEffect(() => {
            setNoScroll(
                (tableBodyElement?.scrollHeight ?? 0) - (tableBodyElement?.clientHeight ?? 0) < 3
            );
        }, [tableBodyElement?.scrollHeight, tableBodyElement?.clientHeight, recalcScroll]);

        useActivate(() => {
            setNoScroll(
                (tableBodyElement?.scrollHeight ?? 0) - (tableBodyElement?.clientHeight ?? 0) < 3
            );
        });

        // let noScroll =
        //     // (tableBodyElement?.scrollHeight ?? 0) - (tableBodyElement?.clientHeight ?? 0) < 3;
        //     (tableBodyElement?.scrollHeight ?? 0) - (tableBodyElement?.clientHeight ?? 0) < 3;

        useEffect(() => {
            if (noScroll && tableBodyElement)
                tableBodyElement?.style?.setProperty?.('overflow-y', 'hidden');
            else tableBodyElement?.style?.setProperty?.('overflow-y', 'auto');
        }, [noScroll, tableBodyElement]);

        const [isDisabledTop, setIsDisabledTop] = useState(true);
        const [isDisabledBottom, setIsDisabledBottom] = useState(false);

        const scrollToRow = (rowIndex: number) => {
            if (tableRef.current) {
                tableRef.current.scrollToRow(rowIndex);
            }
        };

        const scrollByOffset = (offset: number) => {
            if (tableRef.current) {
                const { scrollTop, clientHeight } = tableRef.current.tableNode.querySelector(
                    '.BaseTable__body'
                ) as HTMLDivElement;
                const currentRowIndex = Math.floor(scrollTop / RowHeight[tableDensity]);
                const rowsPerPage = Math.floor(clientHeight / RowHeight[tableDensity]);

                let newRowIndex = 0;

                if (offset === 1) {
                    newRowIndex = currentRowIndex + rowsPerPage * 2 - offset;
                } else if (offset === -1) {
                    newRowIndex = currentRowIndex - rowsPerPage;
                } else {
                    newRowIndex = offset;
                }

                scrollToRow(newRowIndex);

                if (newRowIndex <= 0) {
                    setIsDisabledTop(true);
                    setIsDisabledBottom(false);
                    // } else if (newRowIndex === treeData.length - 1) {
                } else if (newRowIndex >= treeData.length - 1) {
                    setIsDisabledBottom(true);
                    setIsDisabledTop(false);
                } else {
                    setIsDisabledBottom(false);
                    setIsDisabledTop(false);
                }
            }
        };

        const scrollButtons = (
            <Space.Compact className="scroll-buttons" style={{ marginLeft: 'auto' }}>
                <Button disabled={noScroll || isDisabledTop} onClick={() => scrollByOffset(0)}>
                    <StepBackwardOutlined style={{ transform: 'rotate(90deg)' }} />
                </Button>
                <Button disabled={noScroll || isDisabledTop} onClick={() => scrollByOffset(-1)}>
                    <CaretUpOutlined />
                </Button>
                <Button disabled={noScroll || isDisabledBottom} onClick={() => scrollByOffset(1)}>
                    <CaretDownOutlined />
                </Button>
                <Button
                    disabled={noScroll || isDisabledBottom}
                    onClick={() => scrollByOffset(treeData.length - 1)}
                >
                    <StepForwardOutlined style={{ transform: 'rotate(90deg)' }} />
                </Button>
            </Space.Compact>
        );

        useUpdateEffect(() => {
            tableRef.current?.forceUpdate();
        }, [data.length]);

        return (
            <div style={{ width: '100%', overflow: 'hidden', paddingBottom: 1 }} ref={parentRef}>
                {rowsGlobalContextMenu}

                {titleRender ? (
                    <div style={{ backgroundColor: 'white', padding: '8px' }}>{titleRender()}</div>
                ) : (
                    <></>
                )}

                <BaseTable
                    data={treeData} // {treeData}
                    fixed={memoizedColumns.length > 6}
                    ref={tableRef}
                    disabled={loading}
                    width={scrollOptions.x}
                    rowProps={{
                        selectedIdsMap,
                        selectedRows,
                        rowSorting,
                        cellRerender,
                        handleDataSourceChanged
                    }}
                    rowKey={ROWS_KEY_COLUMN_NAME}
                    expandColumnKey={expandColumnKey}
                    expandIconProps={(props) => props}
                    components={{
                        ExpandIcon: (props) => {
                            const onExpand = (...args) => {
                                props.onExpand(...args);
                                setRecalcScroll((s) => !s);
                            };

                            return !props.expandable && props.rowData?.Indicator?.icon ? (
                                <LazyIcon
                                    icon={props.rowData.Indicator.icon}
                                    className="BaseTable__indicator"
                                    size={1.377}
                                    style={{
                                        color: props.rowData.Indicator.color ?? 'gray',
                                        userSelect: 'none',
                                        width: '1.450em',
                                        minWidth: '1.379em',
                                        height: '1.379em',
                                        lineHeight: 16,
                                        margin: '0.5rem',
                                        marginLeft: 0
                                    }}
                                />
                            ) : (
                                <BaseTableExpandIcon {...props} onExpand={onExpand} />
                            );
                        }
                    }}
                    // overscanRowCount={25}
                    overscanRowCount={Math.floor(RowHeight[tableDensity] / 2)}
                    maxHeight={scrollOptions.y}
                    overlayRenderer={renderOverlay}
                    rowHeight={RowHeight[tableDensity]}
                    rowEventHandlers={rowEventHandlers}
                    headerHeight={RowHeight[tableDensity]}
                    rowClassName={({ rowData }) => {
                        const rowCSSclasses = ['base-table-row'];

                        const rowId = rowData[ROWS_KEY_COLUMN_NAME];
                        const selectedIds = selectedIdsMap;

                        if (selectedIds[rowId] || selectedRows?.find((row) => row.Id === rowId)) {
                            rowCSSclasses.push('base-table-selected-row');
                        }

                        if (rowData.isHardGroup) {
                            rowCSSclasses.push('base-table-hardgroup-row');
                        }

                        if (rowData.DeletedAt) {
                            rowCSSclasses.push('base-table-deleted-row');
                        }

                        return rowCSSclasses.join(' ');
                    }}
                    style={{
                        border: '1px solid rgba(217, 217, 217, 0.5)',
                        '--selection-color': desaturateHexColor(colorPrimary ?? '', 7),
                        '--selection-color-hover': desaturateHexColor(colorPrimary ?? '', 6)
                    }}
                >
                    {selectable && (
                        <Column
                            width={40}
                            key="__selection__"
                            className="base-table-select-cell"
                            headerClassName="base-table-header-cell base-table-select-cell"
                            headerRenderer={({ container }) => {
                                // const Component = selectionType === 'radio' ? Radio : Checkbox;
                                if (selectionType === 'radio') return null;

                                const selectedIds =
                                    container?.props?.rowProps?.selectedIdsMap ||
                                    selectedIdsMap ||
                                    selectedRows;

                                const sourceData = data;

                                const tableTreedata = container.props?.data ?? [];

                                return (
                                    <Checkbox
                                        checked={
                                            !!data.length &&
                                            Object.keys(selectedIds).length === sourceData.length
                                        }
                                        // type={selectionType}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                const allRows = flattenTree(tableTreedata).filter(
                                                    (row) => !row.isHardGroup
                                                );

                                                if (onRowSelectionChange) {
                                                    onRowSelectionChange(
                                                        allRows as IObjectWithId[]
                                                    );
                                                } else {
                                                    setSelectedRowMap(allRows as IObjectWithId[]);
                                                }
                                            } else if (onRowSelectionChange) {
                                                onRowSelectionChange([]);
                                            } else {
                                                setSelectedIdsMap({});
                                            }
                                        }}
                                    />
                                );
                            }}
                            cellRenderer={(props) => {
                                return selectionCellRenderer(props);
                            }}
                        />
                    )}

                    {memoizedColumns.map((column, i) => (
                        <Column
                            key={column.key as string}
                            title={column.title as string}
                            dataKey={column.dataIndex as string}
                            style={column.styles}
                            hidden={column.hidden}
                            flexGrow={
                                memoizedColumns.length < 6 &&
                                column?.width &&
                                (column?.width as number) > 100
                                    ? 1
                                    : undefined
                            }
                            headerClassName={'base-table-header-cell'}
                            className={'base-table-cell'}
                            // width={(column.width as number) || 100}
                            width={
                                selectable && !data.length
                                    ? getColumnWidth(column.width) - 10
                                    : getColumnWidth(column.width)
                            }
                            headerRenderer={({ column, container }) => {
                                return (
                                    <div
                                        onClick={() => {
                                            const currentRowSorting =
                                                container?.props?.rowProps?.rowSorting ||
                                                rowSorting;

                                            handleSort(column.dataKey as string, currentRowSorting);
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'stretch',
                                            width: '100%',
                                            userSelect: 'none'
                                            // maxHeight: ROW_HEIGHT
                                        }}
                                    >
                                        {/* <div> */}

                                        {/* <SmartTooltip> */}
                                        <span
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                wordBreak: 'keep-all',
                                                whiteSpace: 'nowrap', // Prevent line breaks,
                                                maxWidth: '86%'
                                            }}
                                            title={column.title}
                                        >
                                            {column.title}
                                        </span>
                                        {/* </SmartTooltip> */}
                                        {/* </div> */}
                                        <div style={{ marginLeft: '8px', userSelect: 'none' }}>
                                            <span
                                                style={{
                                                    fontSize: '0.6rem',
                                                    opacity: '0.5',

                                                    color: container?.props?.rowProps?.rowSorting
                                                        ? container?.props?.rowProps?.rowSorting[
                                                              column.dataKey
                                                          ] === 'ascend'
                                                            ? 'blue'
                                                            : 'gray'
                                                        : 'gray'
                                                }}
                                            >
                                                ▲
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: '0.6rem',
                                                    opacity: '0.5',

                                                    color: container?.props?.rowProps?.rowSorting
                                                        ? container?.props?.rowProps?.rowSorting[
                                                              column.dataKey
                                                          ] === 'descend'
                                                            ? 'blue'
                                                            : 'gray'
                                                        : 'gray'
                                                }}
                                            >
                                                ▼
                                            </span>
                                        </div>
                                    </div>
                                );
                            }}
                            // Use memoized cell renderer
                            cellRenderer={(props) => {
                                const rerender = props?.container?.props?.rowProps?.cellRerender;

                                // console.log('rerender:', rerender);

                                if (
                                    editable &&
                                    viewMode === 'inline' &&
                                    column &&
                                    column?.onCell &&
                                    props.rowData
                                ) {
                                    const row = props.rowData;
                                    const rowIndex = props.rowIndex;
                                    const columnEditRender = column?.onCell(
                                        row as unknown,
                                        rowIndex
                                    );

                                    const onChangeFunc =
                                        props?.container?.props?.rowProps?.handleDataSourceChanged;

                                    if (
                                        columnEditRender &&
                                        columnEditRender.render &&
                                        columnEditRender.onTableDataChange &&
                                        isFunction(columnEditRender.render) &&
                                        onChangeFunc &&
                                        isFunction(onChangeFunc)
                                    ) {
                                        return (
                                            <div style={{ width: '100%' }}>
                                                {columnEditRender.render(
                                                    props.cellData,
                                                    props.rowData as IObjectWithId,
                                                    rowIndex,
                                                    (paramName, paramValue) => {
                                                        onChangeFunc({
                                                            ...row,
                                                            [paramName]: paramValue
                                                        });
                                                    }
                                                )}
                                            </div>
                                        );
                                    }
                                }

                                return (
                                    <MemoizedCellRenderer
                                        {...props}
                                        column={column}
                                        cellRerender={rerender}
                                    />
                                );
                            }}
                        />
                    ))}
                </BaseTable>
                {/* )} */}

                {!data?.length && (
                    <>
                        {noDataRenderer ? (
                            noDataRenderer()
                        ) : (
                            <div style={{ padding: 20, textAlign: 'center' }}>{t('no_data')}</div>
                        )}
                    </>
                )}

                {footerRender ? (
                    <Flex
                        align="center"
                        justify="space-between"
                        gap={10}
                        style={{ backgroundColor: 'white', padding: '8px' }}
                    >
                        {footerRender()}
                        {!noScroll && scrollButtons}
                    </Flex>
                ) : (
                    <div
                        style={{
                            display: !noScroll ? 'flex' : 'none',
                            width: '100%',
                            backgroundColor: 'white',
                            padding: '8px'
                        }}
                    >
                        {scrollButtons}
                    </div>
                )}
            </div>
        );
    }
);
