import { PlainObject } from '@gilbarbara/types';
import { ConfigProvider, Dropdown, Flex, MenuProps, QRCode, Space } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, useMedia, useWindowSize } from 'react-use';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ButtonWithTooltips } from 'ui';
import { LANGUAGES } from 'utils/i18n/i18n';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

import { Response, Route } from 'modules/services/backend-api/generated_api';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { LazyIcon } from 'smart/ui';

import { useLocation } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import { JSONSafeParse } from 'smart/utils';
import {
    InfoCircleOutlined,
    InsertRowAboveOutlined,
    InsertRowLeftOutlined,
    QrcodeOutlined,
    VerticalAlignBottomOutlined
} from '@ant-design/icons';
import { useStoreNavigate } from 'utils/store';
import { useNotifications } from 'utils/hooks';
import { useBaseLanguage } from 'smart/utils/hooks';
import { LocalSearchComponent } from '../LocalSearchComponent/LocalSearchComponent';
import { SmartTableFilterMenuToolbarButton } from '../SmartTableFilterMenu/SmartTableFilterMenuToolbarButton';
import { FilterField } from '../SmartTableFilterMenu/types';
import { MobileCardToolbar } from './components';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRows?: IObjectWithId[];
    setSelectedRows?: Dispatch<SetStateAction<IObjectWithId[]>>;
    selectedRowKeys: string[];
    hasSelected: boolean;
    searchFilter?: string;
    setSearchFilter: (filter: string) => void;
    filters: FilterField[];
    filterString: string;
    setFilters: Dispatch<SetStateAction<FilterField[]>>;
    onRefresh: () => any;
    onCreate: () => void | Promise<void>;
    onCopy: () => void | Promise<void>;
    onDelete: () => void | Promise<void>;
    navigateAfterRun: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    onClickAction: (
        action: Action,
        hardIds?: string[],
        hardArgs?: PlainObject
    ) => Promise<Response | undefined>;

    detailsRenderer?: () => React.ReactNode;

    // setContextMenuActions?: Dispatch<SetStateAction<MenuProps['items']>>;
    showComponents?: {
        isReload?: boolean;
        isSearch?: boolean;
        isFilter?: boolean;
        isRightPart?: boolean;
        isDisplayViewPreferences?: boolean;
        isCreate?: boolean;
        isDelete?: boolean;
        isDescription?: boolean;
        isActions?: boolean;
        isFollows?: boolean;
        isReports?: boolean;
        isActionButtons?: boolean;
        isCopyLink?: boolean;
        isQRCodeButton?: boolean;
    };

    isMobile?: boolean;

    filtersFromLayout: FilterField[];
}

interface SmartTablePageToolbarDesktopProps extends SmartTablePageToolbarProps {
    currentNavItem: Route | undefined;
    actionsMenuItems: MenuItem[];
    reportsMenuItems: MenuItem[];
    followsMenuItems: MenuItem[];
}

const initComponentsDisplay = {
    isReload: true,
    isSearch: true,
    isFilter: true,
    isRightPart: true,
    isDisplayViewPreferences: true,
    isCreate: true,
    isDelete: true,
    isDescription: true,
    isActions: true,
    isFollows: true,
    isReports: true,
    isActionButtons: true,
    isDetails: true,
    isCopyLink: true,
    isQRCodeButton: true
};

const SmartTablePageHeaderToolbarDesktop = observer<SmartTablePageToolbarDesktopProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        searchFilter,
        setSearchFilter,
        filters,
        filterString,
        setFilters,
        onRefresh,
        onCreate,
        onCopy,
        onDelete,
        showComponents: showComponentsLocal,
        onClickAction,
        actionsMenuItems,
        reportsMenuItems,
        followsMenuItems,
        currentNavItem,
        navigateAfterRun,
        detailsRenderer,
        filtersFromLayout,
        setSelectedRows
        // setContextMenuActions
    }) => {
        // const location = useLocation();
        // console.log(hasSelected);
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');
        // const { isPowerUser } = useUserData();
        const navigate = useStoreNavigate();
        const { modal } = useNotifications();
        const baseLanguage = useBaseLanguage();

        const metaData = metaStore.meta.get(meta);
        const info = metaData?.info;
        const fields = info?.Fields;
        const isMetaReadOnly = info?.IsReadOnly || false;
        const uiAllowCreate = info?.UiAllowCreate;
        const uiAllowDelete = info?.UiAllowDelete;
        const metaDescription = info?.Description?.[language] ?? info?.Description?.[baseLanguage];
        const config = useContext(ConfigProvider.ConfigContext);
        const { theme } = config;
        const logoSmall = theme?.token?.logoSmall;

        const actions = useMemo(
            () => toJS(info?.Actions)?.sort((action) => (action.Type_Code === 'FOLDER' ? -1 : 1)),
            [info?.Actions]
        );

        // REESPONSIVE

        const storedMenuOpenState = sessionStorage.getItem('menuOpen');

        const menuOpenState = useMemo(() => JSONSafeParse(storedMenuOpenState) ?? false, []);
        const { width } = useWindowSize();
        const isShowActionButtons = menuOpenState ? width > 1060 : width > 660;
        const isShowActionButtonsLabel = menuOpenState ? width > 1300 : width > 980;
        const isSmallTablet = useMedia('(max-width: 620px)');

        const showComponents = useMemo(
            () => ({ ...initComponentsDisplay, ...showComponentsLocal }),
            [showComponentsLocal]
        );

        // const handleOpenDpModal = async () => {
        //     setDpModal(true);
        // };

        const actionButtons = useMemo(() => {
            if (actions) {
                return actions
                    .filter((action) => {
                        const cond =
                            action.IsButton &&
                            action.IsVisibleInList &&
                            action.IsActive &&
                            (!action.AllowedForNavItems?.length ||
                                action.AllowedForNavItems?.includes(currentNavItem?.id as string));

                        if (!uiAllowDelete) {
                            return (
                                cond &&
                                action.Handler_Code !== 'Delete' &&
                                action.Handler_Code !== 'SoftDelete'
                            );
                        }

                        return cond; // ID есть всегда - это я подразумеваю
                    })
                    .map((action) => {
                        const isDisabled =
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            (action.IsSingle && selectedRowKeys?.length > 1);

                        return (
                            <ButtonWithTooltips
                                type={'default'}
                                disabled={isDisabled}
                                tooltipTitle={
                                    isShowActionButtonsLabel && !action.IsTitleHidden
                                        ? action.Name?.[language] || action.Name?.[baseLanguage]
                                        : undefined
                                }
                                id={action.Id || ''}
                                onClick={async () => {
                                    if (action.Association) {
                                        const association = await metaStore.getAssociation({
                                            association: action.Association.Code,
                                            meta: action.Meta_Code,
                                            ids: selectedRowKeys
                                        });

                                        if (action.Handler) {
                                            onClickAction(action, association?.ids ?? undefined);
                                        } else {
                                            const actionResponse = {
                                                session_id: '',
                                                request_id: '',
                                                status_code: 200
                                            };

                                            const filters = [
                                                {
                                                    column: 'id',
                                                    operator: 'in_list',
                                                    value: association.ids ?? []
                                                }
                                            ];

                                            const metaField = fields?.find(
                                                (field) => field.FieldName === 'Id'
                                            );

                                            navigateAfterRun(
                                                actionResponse,
                                                action,
                                                filters,
                                                metaField ? [toJS(metaField)] : []
                                            );
                                        }
                                    } else {
                                        onClickAction(action);
                                    }
                                }}
                                icon={<LazyIcon icon={action.Icon} />}
                            >
                                {isShowActionButtonsLabel && !action.IsTitleHidden
                                    ? action.Name?.[language]
                                    : null}
                            </ButtonWithTooltips>
                        );
                    });
            }

            return null;
        }, [
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            hasSelected,
            selectedRowKeys,
            isShowActionButtonsLabel,
            language,
            fields,
            navigateAfterRun,
            onClickAction
        ]);

        return (
            <Flex justify="space-between" className="smart_table__header">
                {/* ### Дейсвия (мета) ### */}
                <Space.Compact className="smart_table__header_left">
                    {actionsMenuItems.length && showComponents.isActions && !isBigMobile ? (
                        <Dropdown
                            menu={{
                                items: actionsMenuItems,
                                style: { maxHeight: '400px', overflow: 'auto' },
                                triggerSubMenuAction: 'click'
                            }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} id="actions">
                                {isSmallTablet ? <LazyIcon icon={'MenuOutlined'} /> : t('actions')}
                                {!isBigMobile && <LazyIcon icon={'CaretDownOutlined'} />}
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Отчеты (мета) ### */}
                    {reportsMenuItems.length && showComponents.isReports && !isBigMobile ? (
                        <Dropdown
                            menu={{
                                items: reportsMenuItems,
                                style: { maxHeight: '400px', overflow: 'auto' },
                                triggerSubMenuAction: 'click'
                            }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} id="reports">
                                {isSmallTablet ? (
                                    <LazyIcon icon={'FilePdfOutlined'} />
                                ) : (
                                    t('reports')
                                )}
                                {!isBigMobile && <LazyIcon icon={'CaretDownOutlined'} />}
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Переходы (мета) ### */}
                    {followsMenuItems.length && showComponents.isFollows && !isBigMobile ? (
                        <Dropdown
                            menu={{
                                items: followsMenuItems,
                                style: { maxHeight: '400px', overflow: 'auto' },
                                triggerSubMenuAction: 'click'
                            }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} id="follows">
                                {isSmallTablet ? (
                                    <LazyIcon icon={'ShareAltOutlined'} />
                                ) : (
                                    t('follows')
                                )}
                                {!isBigMobile && <LazyIcon icon={'CaretDownOutlined'} />}
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {!!detailsRenderer && !isBigMobile && detailsRenderer()}

                    {/* ### Обновить ### */}
                    {showComponents.isReload ? (
                        <ButtonWithTooltips
                            id="reload"
                            tooltipTitle={t('reload')}
                            tooltipPlacement="top"
                            onClick={onRefresh}
                            icon={<LazyIcon icon={'ReloadOutlined'} />}
                            type={'default'}
                        />
                    ) : null}

                    {/* ### Удалить ### */}
                    {uiAllowDelete && showComponents.isDelete ? (
                        <ButtonWithTooltips
                            id="delete"
                            tooltipTitle={t('delete')}
                            tooltipPlacement="top"
                            onClick={onDelete}
                            icon={<LazyIcon icon={'DeleteOutlined'} />}
                            disabled={!hasSelected || isMetaReadOnly}
                            type={'default'}
                        />
                    ) : null}

                    {/* ### Создать ### */}
                    {uiAllowCreate && showComponents.isCreate ? (
                        <ButtonWithTooltips
                            id="create"
                            tooltipTitle={t('create')}
                            onClick={onCreate}
                            icon={<LazyIcon icon={'PlusOutlined'} />}
                            type={'default'}
                            disabled={isMetaReadOnly}
                        />
                    ) : null}

                    {/* ### Скопировать ### */}
                    {uiAllowCreate && showComponents.isCreate ? (
                        <ButtonWithTooltips
                            id="copy_object"
                            tooltipTitle={t('copy_object')}
                            onClick={onCopy}
                            disabled={
                                (selectedRowKeys ? selectedRowKeys.length !== 1 : !hasSelected) ||
                                isMetaReadOnly
                            }
                            icon={<LazyIcon icon={'FileAddOutlined'} />}
                            type={'default'}
                        />
                    ) : null}

                    {isShowActionButtons && showComponents.isActionButtons ? actionButtons : null}
                </Space.Compact>

                <Flex style={{ display: 'flex' }} className="smart_table__header_center">
                    {/* TODO: center part of toolbar */}
                    <></>
                </Flex>

                {showComponents.isRightPart && (
                    <Space.Compact className="smart_table__header_right">
                        {showComponents.isSearch ? (
                            <LocalSearchComponent
                                searchFilter={searchFilter}
                                setFilter={(filter) => {
                                    setSearchFilter(filter);
                                    if (setSelectedRows) setSelectedRows([]);
                                }}
                                placeholder={`${t('search')}...`}
                            />
                        ) : null}

                        <div style={{ display: showComponents.isFilter ? 'block' : 'none' }}>
                            <SmartTableFilterMenuToolbarButton
                                meta={meta}
                                filters={filters}
                                setFilters={setFilters}
                                filtersFromLayout={filtersFromLayout}
                            />
                        </div>

                        {showComponents.isQRCodeButton ? (
                            <ButtonWithTooltips
                                tooltipTitle={t('qr_code')}
                                tooltipPlacement={'bottomLeft'}
                                type={'default'}
                                icon={<QrcodeOutlined />}
                                onClick={() => {
                                    modal.info({
                                        title:
                                            info?.PluralName?.[language] ??
                                            info?.PluralName?.[baseLanguage] ??
                                            info?.Code,
                                        content: (
                                            <QRCode
                                                style={{ maxWidth: 400, maxHeight: 400 }}
                                                size={400}
                                                iconSize={50}
                                                type="svg"
                                                errorLevel="H"
                                                value={`${window.location.href}${
                                                    filterString
                                                        ? `?${filterString}&meta=eq.${meta}`
                                                        : `?meta=eq.${meta}`
                                                }`}
                                                icon={logoSmall}
                                            />
                                        ),
                                        centered: true,
                                        width: 450,
                                        icon: null
                                    });
                                }}
                            />
                        ) : null}

                        {showComponents.isCopyLink ? (
                            <ButtonWithTooltips
                                tooltipTitle={t('copy_link')}
                                className={''}
                                tooltipPlacement={'bottomLeft'}
                                type={'default'}
                                icon={<LazyIcon icon={'mdiLinkVariant'} />}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${window.location.href}${
                                            filterString ? `?${filterString}` : ''
                                        }`
                                    );
                                    Toast.show({
                                        content: t('copied')
                                    });
                                }}
                            />
                        ) : null}

                        {/* ### Описание ### */}
                        {/* {showComponents.isDescription && metaDescription ? (
                            <HelpIcon
                                text={metaDescription}
                                style={{ width: 32 }}
                                // iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                                trigger="click"
                                meta={meta}
                                isButton
                            />
                        ) : null} */}

                        {
                            // (showComponents.isDescription && isPowerUser) ||
                            // (!isPowerUser && showComponents.isDescription && metaDescription) ? (
                            // <HelpIcon
                            //     text={metaDescription}
                            //     style={{ width: 32 }}
                            //     // iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                            //     trigger="click"
                            //     meta={meta}
                            //     isButton
                            // />
                            metaDescription ? (
                                <ButtonWithTooltips
                                    icon={<InfoCircleOutlined />}
                                    tooltipTitle={t('notes')}
                                    type="default"
                                    onClick={() => {
                                        navigate(
                                            { pathname: `/notes/${meta}` },
                                            {
                                                state: {
                                                    // pageTitle: `${t('notes')} (${
                                                    //     info?.PluralName?.[language]
                                                    // })`
                                                    pageTitle: 'notes',
                                                    extraPageTitle: toJS(info?.PluralName)
                                                }
                                            }
                                        );
                                    }}
                                />
                            ) : null
                        }
                    </Space.Compact>
                )}

                {/* {showComponents.isDisplayViewPreferences && (
                <ViewDisplayPreferencesModal
                    open={dpModal}
                    onCancel={() => setDpModal(false)}
                    onOk={() => setDpModal(false)}
                />
            )} */}
            </Flex>
        );
    }
);

export const SmartTablePageHeaderToolbar = observer<SmartTablePageToolbarProps>(
    ({
        meta,
        selectedRows,
        setSelectedRows,
        selectedRowKeys,
        hasSelected,
        filters,
        onClickAction,
        navigateAfterRun,
        isMobile,
        ...props
    }) => {
        // const isBigMobile = useMedia('(max-width: 480px)');
        const location = useLocation();
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const baseLanguage = useBaseLanguage();
        const config = useContext(ConfigProvider.ConfigContext);
        const { theme } = config;
        const logoSmall = theme?.token?.logoSmall;
        const { modal } = useNotifications();

        const metaData = useMemo(
            () => toJS(metaStore.meta.get(meta)),
            [meta, metaStore.meta.get(meta)]
        );
        const actions = useMemo(
            () =>
                metaData?.info?.Actions?.sort((action) => (action.Type_Code === 'FOLDER' ? -1 : 1)),
            [metaData?.info?.Actions]
        );

        const currentNavItem = useMemo(
            () =>
                toJS(metaStore.meta.get('all')?.routes)?.find(
                    (route) =>
                        route.path ===
                        `${location.pathname}${
                            location.state?.filterString ? `?${location.state?.filterString}` : ''
                        }`
                ),
            [location.pathname, location.state?.filterString, metaStore.meta.get('all')?.routes]
        );

        // const isMetaReadOnly = metaData?.info?.IsReadOnly || false;
        // const uiAllowCreate = metaData?.info?.UiAllowCreate;
        const uiAllowDelete = metaData?.info?.UiAllowDelete;

        const { width } = useWindowSize();
        const isShowActionButtons = width > 660;

        const fields = metaData?.info?.Fields;

        const { value: isShowFramedAssocInFollowsMenu } = useAsync(
            async () =>
                metaStore.getParam({
                    param_name: 'SHOW_FRAMED_ASSOC_IN_FOLLOWS_MENU',
                    default_value: false
                }),
            []
        );

        const actionsMenuItems = useMemo(() => {
            const folders: MenuItem[] = [];
            const folderless: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    (action.IsFooterButton && !isMobile) ||
                    !action.IsVisibleInList ||
                    (isShowActionButtons && action.IsButton) ||
                    // (isShowActionButtons && action.IsButton && !isMobile) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                // if (action.IsButton) console.log(action);

                if ((action.IsFolder || action.Type_Code === 'FOLDER') && !isMobile) {
                    folders.push({
                        key: action.Id!,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES],
                        disabled: action.IsDisabled,
                        icon: <LazyIcon icon={action.Icon} />,
                        children: []
                    });
                }

                if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                    const parentId = action.Parent_Id;

                    const folderIndex = !isMobile
                        ? folders.findIndex((folder) => folder && folder.key === parentId)
                        : -1;

                    const item = {
                        isButton: action.IsButton,
                        key: action.Id,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES],
                        // icon: <LazyIcon icon={action.Icon} />,
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled ||
                            (action.IsSingle && selectedRowKeys.length > 1),

                        onClick: () => onClickAction(action, isMobile ? selectedRowKeys : undefined)
                        // onClick: async () => {
                        //     const response = await onClickAction(
                        //         action,
                        //         isMobile ? selectedRowKeys : undefined
                        //     );

                        // if (response && action.NavItem_Id && !action.IsNavigationFirst) {
                        //     navigateAfterRun(response, action, filters);
                        // }
                        // }
                    };

                    // если экшн кнопка, то на МОБИЛЕ вынимаем ее из папки
                    // if (isMobile && item.isButton) {
                    //     folderless.push(item);
                    // } else
                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }

                if (!action.IsFolder && action.Type_Code === 'EVENT') {
                    const parentId = action.Parent_Id;

                    const folderIndex = !isMobile
                        ? folders.findIndex((folder) => folder && folder.key === parentId)
                        : -1;

                    const item = {
                        isButton: action.IsButton,
                        key: action.Id,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES],
                        // icon: <LazyIcon icon={action.Icon} />,
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled ||
                            (action.IsSingle && selectedRowKeys.length > 1),
                        onClick: () =>
                            onClickAction(
                                {
                                    ...action,
                                    Handler_Code: 'RaiseEvent',
                                    Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                },
                                isMobile ? selectedRowKeys : undefined,
                                {
                                    event_type: action.EventType_Code
                                }
                            )
                    };

                    // if (isMobile && item.isButton) {
                    //     folderless.push(item);
                    // } else
                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }
            }

            if (isMobile) {
                folderless.unshift({
                    key: 'qr_code',
                    label: t('qr_code'),
                    onClick: () => {
                        modal.info({
                            title:
                                // metaData?.info?.PluralName?.[language] ??
                                metaData?.info?.SingularName?.[language] ??
                                // metaData?.info?.PluralName?.[baseLanguage] ??
                                metaData?.info?.SingularName?.[baseLanguage] ??
                                metaData?.info?.Code,
                            content: (
                                <QRCode
                                    style={{
                                        maxWidth: 400,
                                        maxHeight: 400,
                                        width: '103.5%',
                                        height: '103.5%'
                                    }}
                                    type="svg"
                                    // size={400}
                                    iconSize={25}
                                    errorLevel="H"
                                    value={`${window.location.href}/${selectedRowKeys[0]}${
                                        location.state?.filterString
                                            ? `?${location.state?.filterString}&meta=eq.${meta}`
                                            : `?meta=eq.${meta}`
                                    }`}
                                    icon={logoSmall}
                                />
                            ),
                            okButtonProps: { block: true },
                            centered: true,
                            width: 450,
                            icon: null
                        });
                    }
                });
            }

            return [
                ...folders
                    .filter((folder) => folder.children?.length)
                    .sort((a, b) => Number(a?.index) - Number(b?.index)),
                ...folderless.sort((a, b) => Number(a?.index) - Number(b?.index))
            ];
        }, [
            actions,
            isShowActionButtons,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            hasSelected,
            selectedRowKeys,
            onClickAction,
            isMobile
        ]);

        const reportsMenuItems = useMemo(() => {
            const reports: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    (action.IsFooterButton && !isMobile) ||
                    !action.IsVisibleInList ||
                    // (isShowActionButtons && action.IsButton && !isMobile) ||
                    (isShowActionButtons && action.IsButton) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'REPORT') {
                    // console.log(action);
                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES],
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled,
                        onClick: async () => {
                            const response = await onClickAction(
                                action,
                                isMobile ? selectedRowKeys : undefined
                            );
                        }
                    };

                    reports.push(item);
                }

                if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
                    // console.log(action);
                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES],
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled,
                        onClick: async () => {
                            const response = await onClickAction(
                                action,
                                isMobile ? selectedRowKeys : undefined
                            );

                            // if (response) {
                            //     navigateAfterRun(
                            //         {
                            //             session_id: '',
                            //             request_id: '',
                            //             status_code: 200,
                            //             run: response.run
                            //         },
                            //         action,
                            //         filters
                            //     );
                            // }
                        }
                    };

                    reports.push(item);
                }
            }

            return reports.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            actions,
            isShowActionButtons,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            hasSelected,
            onClickAction,
            navigateAfterRun,
            filters,
            isMobile
        ]);

        const followsMenuItems = useMemo(() => {
            const follows: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    (action.IsFooterButton && !isMobile) ||
                    !action.IsVisibleInList ||
                    // (isShowActionButtons && action.IsButton && !isMobile) ||
                    (isShowActionButtons && action.IsButton) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete) ||
                    (!isShowFramedAssocInFollowsMenu && // пропускаем фреймы, если задан флаг это разрешающий
                        action.IsVisibleInFrame)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES],
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled,
                        onClick: async () => {
                            // const response = await onClickAction(
                            //     action,
                            //     isMobile ? selectedRowKeys : undefined
                            // );

                            // return;
                            const metaField = fields?.find((field) => field.FieldName === 'Id');

                            let filters: Filter[] = [
                                {
                                    column: 'id',
                                    operator: 'in_list',
                                    value: selectedRowKeys
                                }
                            ];
                            let association;
                            let actionResponse = {
                                session_id: '',
                                request_id: '',
                                status_code: 200
                            };

                            if (action.Association) {
                                association = await metaStore.getAssociation({
                                    association: action.Association.Code,
                                    meta: action.Meta_Code,
                                    ids: selectedRowKeys
                                });

                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: association?.ids
                                    }
                                ];
                            }
                            if (action.Handler) {
                                const response = await onClickAction(
                                    action,
                                    isMobile
                                        ? association
                                            ? association.ids
                                            : selectedRowKeys
                                        : association?.ids
                                );

                                if (action.IsNavigationFirst || action.NavItem) return;

                                if (response) {
                                    actionResponse = response;
                                }
                            }

                            if (actionResponse && !action.Handler) {
                                console.log('filters:', filters);

                                navigateAfterRun(
                                    actionResponse,
                                    action,
                                    filters,
                                    metaField ? [metaField] : []
                                );
                            }
                        }
                    };

                    follows.push(item);
                }
            }

            return follows.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            onClickAction,
            isShowActionButtons,
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            hasSelected,
            fields,
            navigateAfterRun,
            selectedRowKeys,
            isShowFramedAssocInFollowsMenu
        ]);

        if (isMobile) {
            return (
                <MobileCardToolbar
                    actionsMenuItems={actionsMenuItems}
                    reportsMenuItems={reportsMenuItems}
                    followsMenuItems={followsMenuItems}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    actionButtonsColor="#3185d2"
                    actionButtonsTextColor="#ffffff"
                    // buttonActions={actionsMenuItems}
                />
            );
        }

        return (
            <SmartTablePageHeaderToolbarDesktop
                meta={meta}
                selectedRowKeys={selectedRowKeys}
                hasSelected={hasSelected}
                filters={filters}
                onClickAction={onClickAction}
                actionsMenuItems={actionsMenuItems}
                reportsMenuItems={reportsMenuItems}
                followsMenuItems={followsMenuItems}
                currentNavItem={currentNavItem}
                navigateAfterRun={navigateAfterRun}
                setSelectedRows={setSelectedRows}
                {...props}
            />
        );
    }
);
