import { CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { Dropdown, Flex, Layout, MenuProps, Tabs, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ButtonWithTooltips } from 'ui';
import { routeStore, RouteStoreItemType, useStoreNavigate } from 'utils/store';
import { isDefined } from 'is-lite/exports';
import { useEffectOnce, useMedia } from 'react-use';
import { toJS } from 'mobx';
import { useAliveController } from 'react-activation';

import { ID_REGX, USER_ID_REGX } from '../constants';
import './Footer.scss';
import { getPageTitle } from 'smart/utils';
import { useBaseLanguage } from 'smart/utils/hooks';
import { useDetailFormChanged } from '../CommonPage/CommonPage';

// import christmasBorderBottom from 'assets/images/christmas-border-bottom.png';

interface FooterProps {
    style?: React.CSSProperties;
}

interface ChipLabelType {
    route: RouteStoreItemType;
    contextMenuItems: MenuProps['items'];
}

const ChipLabel = memo<ChipLabelType>(({ route, contextMenuItems }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const spanRef = useRef<HTMLSpanElement>(null);
    const isTouchScreen = useMedia('(hover: none)');
    const baseLanguage = useBaseLanguage();

    const [disableTooltip, setDisableTooltip] = useState(false);

    const renderChipContent = useCallback(
        (data: string, state?: any) => {
            const titleArr = data.split('/');

            const title =
                state && state.pageTitle
                    ? getPageTitle(
                          { title: state.pageTitle, extra: state.extraPageTitle },
                          language,
                          baseLanguage,
                          t
                      )
                    : titleArr[titleArr.length - 1];

            const isTitleId = /^\d+$/.test(title);

            const isTitleUserId =
                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(title);

            const isCreate = title.includes('?mode=create');

            if (isTitleId || isTitleUserId)
                return `${isTitleUserId ? title.split('-')[0] : title} (${t(
                    titleArr[titleArr.length - 2]
                )})`;

            if (isCreate) return `${t('creation')} (${t(titleArr[titleArr.length - 2])})`;

            const isTitleHasObjectStruct = t(title).includes('returned an object');

            return isTitleHasObjectStruct ? t(`${title}.title`) : t(title) || t('home');
        },
        [baseLanguage, language, t]
    );

    return (
        <Dropdown
            onOpenChange={(open) => setDisableTooltip(open)}
            trigger={['contextMenu']}
            menu={{ items: contextMenuItems }}
            destroyPopupOnHide
        >
            <Tooltip
                open={
                    disableTooltip ||
                    spanRef?.current?.clientWidth === spanRef?.current?.scrollWidth
                        ? false
                        : undefined
                }
                mouseEnterDelay={0.7}
                destroyTooltipOnHide
                title={
                    isTouchScreen
                        ? undefined
                        : renderChipContent(`${route.pathname}${route.search || ''}`, route.state)
                }
            >
                <Flex className="footer__chip" justify="space-between">
                    <Flex align="center" gap={5}>
                        {route.pathname === '/' ? (
                            <HomeOutlined className="footer__chip_home" />
                        ) : undefined}
                        <span
                            ref={spanRef}
                            style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {renderChipContent(
                                `${route.pathname}${route.search || ''}`,
                                route.state
                            )}
                        </span>
                    </Flex>
                </Flex>
            </Tooltip>
        </Dropdown>
    );
});

const initialDetailFormChanged = {
    changedMap: new Map<string, boolean>(),
    model: null
};

export const Footer = observer<FooterProps>(({ style }) => {
    const { t } = useTranslation();
    const navigate = useStoreNavigate();
    const { pathname, search, state } = useLocation();
    const { dropScope, clear: dropAllScope } = useAliveController();
    const isBigMobile = useMedia('(max-width: 480px)');

    const [{ changedMap, modal }, setIsChanged] = useDetailFormChanged();

    const isChanged = changedMap.get(`${pathname}_${state?.cacheKey}`) || false;

    useEffectOnce(() => {
        setIsChanged((prev) => ({ ...prev, changedMap: initialDetailFormChanged.changedMap }));
    });

    const pathnameLastElem = pathname.split('/').at(-1)?.trim() as string;

    const isBookingSlotsPage = pathnameLastElem === 'resource-planner';
    const isNotesPage = pathname.includes('notes');
    const isChangelogPage = pathname.includes('changelog');
    const isReportPage = pathnameLastElem === 'report';
    const isPlannerPage = pathnameLastElem === 'planner';
    const isGridPage = pathnameLastElem === 'grid';
    const isDetailPage =
        ID_REGX.test(pathnameLastElem) ||
        USER_ID_REGX.test(pathnameLastElem) ||
        pathnameLastElem === ':' ||
        pathnameLastElem === 'new';

    const handleDropScope = useCallback(() => {
        let scope = `tablePage_${state?.cacheKey || pathname}`;

        if (isDetailPage) scope = `detailPage_${state?.cacheKey || pathname}`;
        else if (isBookingSlotsPage) scope = `resourcePlanner_${state?.cacheKey || pathname}`;
        else if (isNotesPage) scope = `notesPage${pathnameLastElem !== 'notes' ? `_${pathnameLastElem}` : ''}`;
        else if (isChangelogPage) scope = `changelogPage${pathnameLastElem !== 'changelog' ? `_${pathnameLastElem}` : ''}`;
        else if (isReportPage) scope = `reportPage_${state?.cacheKey ?? pathname}`;
        else if (isPlannerPage) scope = `planner_${state?.cacheKey || pathname}`;
        else if (isGridPage) scope = `gridTable_${state?.cacheKey ?? pathname}`;

        dropScope(scope);
    }, [
        dropScope,
        isDetailPage,
        isBookingSlotsPage,
        state?.cacheKey,
        isNotesPage,
        isChangelogPage,
        isReportPage,
        isGridPage,
        isPlannerPage,
        pathname
    ]);

    const handleClear = useCallback(() => {
        routeStore.routes.forEach((route) => {
            const cacheKey = route.state?.cacheKey;
            const pathname = route.pathname;

            const pathnameLastElem = pathname.split('/').at(-1)?.trim() as string;

            const isHomePage = pathname === '/';
            const isBookingSlotsPage = pathnameLastElem === 'resource-planner';
            const isNotesPage = pathname.includes('notes');
            const isChangelogPage = pathname.includes('changelog');
            const isReportPage = pathnameLastElem === 'report';
            const isPlannerPage = pathnameLastElem === 'planner';
            const isGridPage = pathnameLastElem === 'grid';
            const isDetailPage =
                ID_REGX.test(pathnameLastElem) ||
                USER_ID_REGX.test(pathnameLastElem) ||
                pathnameLastElem === ':' ||
                pathnameLastElem === 'new';

            let scope = `tablePage_${cacheKey || pathname}`;

            if (isDetailPage) scope = `detailPage_${cacheKey || pathname}`;
            else if (isBookingSlotsPage) scope = `resourcePlanner_${cacheKey || pathname}`;
            else if (isNotesPage) scope = `notesPage${pathnameLastElem !== 'notes' ? `_${pathnameLastElem}` : ''}`;
            else if (isChangelogPage) scope = `changelogPage${pathnameLastElem !== 'changelog' ? `_${pathnameLastElem}` : ''}`;
            else if (isReportPage) scope = `reportPage_${cacheKey ?? pathname}`;
            else if (isPlannerPage) scope = `planner_${cacheKey || pathname}`;
            else if (isGridPage) scope = `gridTable_${cacheKey ?? pathname}`;

            if (!isHomePage) dropScope(scope);
        });

        routeStore.clear();
        navigate({ pathname: '/', search: '' }, { state: null });

        // Не показывает главную, из-за этой функции
        // dropAllScope();
    }, [navigate]);

    const handleClickChip = useCallback(
        (jsonValue: string) => {
            const { pathname, search, state } = JSON.parse(jsonValue);

            navigate(
                { pathname, search },
                {
                    state
                }
            );
        },
        [navigate]
    );

    const handleCloseChip = useCallback(
        async (route: RouteStoreItemType) => {
            if (isChanged && modal) {
                await modal.open();
                return;
            }

            const currentRouteIndex = routeStore.findIndex({ pathname, search, state });

            if (
                currentRouteIndex !== -1 &&
                routeStore.isEqual(route, { pathname, search, state })
            ) {
                if (isDefined(routeStore.routes[currentRouteIndex + 1])) {
                    const { pathname, state, search } = routeStore.routes[currentRouteIndex + 1];

                    navigate({ pathname, search: search || '' }, { state: toJS(state) });
                } else {
                    const { pathname, state, search } = routeStore.routes[currentRouteIndex - 1];

                    navigate({ pathname, search: search || '' }, { state: toJS(state) });
                }
            }
            handleDropScope();
            routeStore.remove(route);
        },
        [handleDropScope, navigate, pathname, search, state, isChanged, modal]
    );

    // console.log('Footer Tabs', toJS(routeStore.routes), { pathname, search, state });

    return (
        <Layout.Footer className="footer" style={style}>
            <Flex
                vertical={isBigMobile}
                className="footer__container"
                justify={isBigMobile ? 'space-between' : 'flex-start'}
                style={{
                    height: isBigMobile ? '100vh' : undefined,
                    width: isBigMobile ? '250px' : undefined,
                    alignItems: isBigMobile ? 'flex-start' : undefined
                    // backgroundImage: `url(${christmasBorderBottom})`,
                    // backgroundSize: 'contain',
                    // backgroundPosition: 'center',
                    // backgroundColor: '#EDE7DD'
                }}
            >
                <Tabs
                    size={isBigMobile ? 'large' : 'middle'}
                    tabPosition={isBigMobile ? 'right' : undefined}
                    className="footer__chip_list"
                    type="editable-card"
                    hideAdd
                    onChange={handleClickChip}
                    defaultActiveKey={JSON.stringify({ pathname, search, state })}
                    activeKey={JSON.stringify({ pathname, search, state })}
                    onEdit={(targetKey, action) => {
                        if (action === 'remove') {
                            const route = JSON.parse(targetKey as string);
                            handleCloseChip(route);
                        }
                    }}
                    items={routeStore.routes.map((route, index, array) => {
                        return {
                            key: JSON.stringify(route),
                            label: (
                                <ChipLabel
                                    route={route}
                                    contextMenuItems={[
                                        {
                                            key: 'close',
                                            label: t('close'),
                                            onClick: () => handleCloseChip(route),
                                            disabled: route.pathname === '/'
                                        },
                                        {
                                            key: 'close_all',
                                            label: t('close_all'),
                                            onClick: handleClear,
                                            disabled:
                                                array.length === 1 && array[0].pathname === '/'
                                        },
                                        {
                                            key: 'close_other',
                                            label: t('close_other'),
                                            onClick: () => {
                                                handleClear();
                                                handleClickChip(JSON.stringify(route));
                                            },
                                            disabled:
                                                ((array.length === 2 && index === 1) ||
                                                    array.length === 1) &&
                                                array[0].pathname === '/'
                                        }
                                    ]}
                                />
                            ),
                            value: JSON.stringify(route),
                            closable: route.pathname !== '/'
                        };
                    })}
                />

                <ButtonWithTooltips
                    className="footer__clear"
                    style={{ margin: isBigMobile ? '10px auto' : undefined }}
                    type="text"
                    id="clear-chips"
                    onClick={handleClear}
                    icon={<CloseOutlined />}
                />
            </Flex>
        </Layout.Footer>
    );
});
