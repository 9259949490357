import { memo } from 'react';
import KeepAlive from 'react-activation';
import { useLocation, useParams } from 'react-router-dom';
import { NotesPage } from 'smart/modules';

export const KeepAlivedNotesPage = memo<{ rootMeta: string }>(({ rootMeta }) => {
    const { pathname, state } = useLocation();
    const { meta } = useParams();

    const pageType = pathname.includes('notes') ? 'notes' : 'changelog';
    const cacheKey = `${pageType}Page${meta ? `_${meta}` : ''}`;

    return (
        <KeepAlive
            name={cacheKey}
            key={cacheKey}
            cacheKey={cacheKey} // Исправлена опечатка в имени свойства
        >
            <NotesPage rootMeta={rootMeta} meta={meta ?? state?.meta} />
        </KeepAlive>
    );
});
