import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, InputRef, Popover, Space } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import { useMedia, useUpdateEffect } from 'react-use';
import { ButtonWithTooltips } from 'ui';

interface LocalSearchComponentProps {
    searchFilter?: string;
    setFilter: (filter: string) => void;
    placeholder: string;
}

export const LocalSearchComponent = memo<LocalSearchComponentProps>(
    ({ searchFilter, setFilter, placeholder }) => {
        const [isLocalFilter, setIsLocalFilter] = useState(!!searchFilter);
        const [localFilterChange, setLocalFilterChange] = useState('');

        const inputRef = useRef<InputRef>(null);

        useEffect(() => {
            setLocalFilterChange(searchFilter ?? '');
        }, [searchFilter]);
        // const [isMobileSearchOpen, setIsMobileSearchOpen] = useBoolean(false);

        const isMiddleTablet = useMedia('(max-width: 720px)');

        const handleSearch = (value: string) => {
            setFilter(value);
            inputRef.current?.blur();
        };

        useUpdateEffect(() => {
            inputRef.current?.focus();
        }, [isLocalFilter]);

        return isMiddleTablet ? (
            <>
                <Popover
                    placement={'bottomLeft'}
                    destroyTooltipOnHide
                    content={
                        <Input.Search
                            ref={inputRef}
                            size={isMiddleTablet ? 'large' : 'middle'}
                            allowClear
                            enterButton
                            placeholder={placeholder}
                            value={localFilterChange}
                            onChange={({ target }) => {
                                setLocalFilterChange(target.value);
                            }}
                            onSearch={handleSearch}
                            onBlur={() => setFilter(localFilterChange)}
                        />
                    }
                >
                    <Button
                        icon={<SearchOutlined />}
                        // onClick={() => setIsMobileSearchOpen(true)}
                    />
                </Popover>
            </>
        ) : (
            <Flex>
                {/* {isLocalFilter ? ( */}
                <Space.Compact style={{ display: isLocalFilter ? undefined : 'none' }}>
                    <Input.Search
                        ref={inputRef}
                        allowClear
                        enterButton
                        placeholder={placeholder}
                        value={localFilterChange}
                        onChange={({ target }) => {
                            setLocalFilterChange(target.value);
                        }}
                        onSearch={handleSearch}
                        onBlur={() => setFilter(localFilterChange)}
                    />
                    <ButtonWithTooltips
                        id="filter-local-hide"
                        // icon={isLocalFilter ? <CloseOutlined /> : <SearchOutlined />}
                        icon={<CloseOutlined />}
                        type="default"
                        className="filter-local"
                        onClick={() => setIsLocalFilter((prev) => !prev)}
                    />
                </Space.Compact>
                {/* ) : ( */}
                {!isLocalFilter && (
                    <ButtonWithTooltips
                        id="filter-local"
                        // icon={isLocalFilter ? <CloseOutlined /> : <SearchOutlined />}
                        icon={<SearchOutlined />}
                        type="default"
                        className="filter-local"
                        onClick={() => setIsLocalFilter((prev) => !prev)}
                    />
                )}
                {/* )} */}
            </Flex>
        );
    }
);
