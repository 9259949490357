import { Filter } from 'modules/supabase/utils/supabaseClient';

import { ConvertFilterFunctionType } from '../../../types';
import { convertUIFilterColumnName } from './convertUIFilterColumnName';
// import { mapMUITableFilterOperationAndSupabaseFilterOperation } from './mapMUITableFilterOperationAndSupabaseFilterOperation';

export const convertDatetimeIntervalFilter = (
    rawFilterSetting: ConvertFilterFunctionType
): Filter => {
    if (!rawFilterSetting.complexFilterValue.value) {
        return {
            column: rawFilterSetting.complexFilter.field.FieldName,
            operator: 'eq',
            value: undefined
        };
    }

    // const rangeKeys = Object.keys(rawFilterSetting.complexFilterValue.value);

    // rangeKeys.sort();

    // const lessThanOrEqualTo =
    //     mapMUITableFilterOperationAndSupabaseFilterOperation('lessThanOrEqualTo');

    // const greaterThanOrEqualTo =
    //     mapMUITableFilterOperationAndSupabaseFilterOperation('greaterThanOrEqualTo');

    // const filterValueFrom: Filter = {
    //     column: convertUIFilterColumnName(
    //         rawFilterSetting.complexFilter.field,
    //         greaterThanOrEqualTo,
    //         rawFilterSetting.language
    //     ),
    //     operator: greaterThanOrEqualTo,
    //     value: rawFilterSetting.complexFilterValue.value[rangeKeys[0]],
    //     ANDCondition: filterValueTo
    // };

    return {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            'dti',
            rawFilterSetting.language
        ),
        operator: 'dti',
        value: rawFilterSetting.complexFilterValue.value
    };
};
