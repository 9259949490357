import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { RegBookedLocationSlot } from 'modules/services/backend-api/generated_models';
import { CSSProperties } from 'react';

dayjs.extend(timezone);

export interface ColorMarkerRevertProps {
    bookedSlots: RegBookedLocationSlot[];
    hour: number;
    multiplicator: number;
    status: 'filled' | 'partial_filled' | 'free' | 'closed';
    isIndicator?: boolean;
    text?: number | string;
    color?: CSSProperties['color'];
    border?: CSSProperties['border'];
}

function extractTimezone(input: string): string | null {
    // Регулярное выражение для поиска таймзоны в формате +HH:MM или -HH:MM
    const timezoneRegex = /[+-]\d{2}:\d{2}$/;
    const match = input.match(timezoneRegex);

    // Если таймзона найдена, вернуть ее, иначе вернуть null
    return match ? match[0] : null;
}

export const ColorMarkerRevert = ({
    hour,
    multiplicator,
    bookedSlots,
    status,
    isIndicator,
    color,
    border,
    text = ''
}: ColorMarkerRevertProps) => {
    const getColor = () => {
        switch (status) {
            case 'filled':
                return 'rgba(255,182,193,0.9)';
            case 'partial_filled':
                return 'rgba(255,182,193,0.9)';
            case 'free':
                return 'skyblue';
            case 'closed':
                return 'lightgray';
            default:
                return 'white';
        }
    };

    const slotEndAt = bookedSlots?.at(-1)?.IntervalEndAt;

    const slotEnd = dayjs(slotEndAt).tz(extractTimezone(slotEndAt ?? '') || undefined);

    return (
        <div
            className={'color_marker'}
            style={{
                margin: !isIndicator ? -7 : undefined,
                width: !isIndicator ? 'calc(100% + 0.9rem)' : 4.5,
                height: !isIndicator ? 'calc(44px - 2 * 0.7rem)' : 15,
                padding: !isIndicator ? '0.7rem 0rem' : undefined,
                backgroundColor: 'lightgray',
                color: 'white',
                textAlign: 'center',
                border,
                boxSizing: 'content-box',
                position: 'relative'
            }}
        >
            <span
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: '-50%',
                    transform: 'translateX(50%)',
                    zIndex: 10
                }}
            >
                {text}
            </span>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '43px',
                    backgroundColor: status !== 'closed' ? 'skyblue' : 'transparent',
                    marginTop: -11
                }}
            >
                {!isIndicator ? (
                    bookedSlots?.map((bookedSlotsData, index) => {
                        // const slotFillPercent = bookedSlotsData.SlotPercent;

                        const start = dayjs(bookedSlotsData.StartAt).tz(
                            extractTimezone(bookedSlotsData.StartAt) || undefined
                        );
                        const end = dayjs(bookedSlotsData.EndAt).tz(
                            extractTimezone(bookedSlotsData.EndAt) || undefined
                        );

                        const sH = start.get('hours');
                        const startHour = sH - (sH % multiplicator);
                        const eH = end.get('hours');
                        const endHour = eH - (eH % multiplicator);

                        const startMinutes =
                            // startHour === hour + multiplicator ? start.get('minutes') : 0;
                            startHour === hour ? start.get('minutes') : 0;
                        const endMinutes =
                            // endHour === hour + multiplicator ? end.get('minutes') : 0;
                            // endHour === hour ? end.get('minutes') : 0;
                            end.diff(start, 'minutes');

                        let startPercents = (startMinutes / (60 * multiplicator)) * 100;
                        let endPercents = ((endMinutes || 60) / (60 * multiplicator)) * 100;
                        // let endPercents =
                        //     ((endMinutes + (eH - startHour) * 60) / (60 * multiplicator)) * 100;

                        const ensStartDiff = end.diff(start, 'hours');

                        const endGlobalEndDiff = end.diff(slotEnd);

                        if (ensStartDiff !== 0 && ensStartDiff % multiplicator === 0) {
                            startPercents = (startMinutes / (60 * multiplicator)) * 100;
                            endPercents = 100;
                        }

                        // const slotFillPercent = endPercents - startPercents;
                        const slotFillPercent = endPercents;

                        return (
                            <div
                                style={{
                                    // boxSizing: 'content-box',
                                    position: 'absolute',
                                    width: '100%',
                                    height:
                                        // endHour === hour + 1 ||
                                        endHour === hour || endGlobalEndDiff === 0
                                            ? `${slotFillPercent}%`
                                            : `calc(${slotFillPercent}% + 2px)`,
                                    boxShadow:
                                        index === 0 && status !== 'closed' && status !== 'free'
                                            ? '8px 5px 8px 0px rgba(34, 60, 80, 0.3)'
                                            : undefined,
                                    backgroundColor:
                                        status !== 'closed' && status !== 'free'
                                            ? color ?? getColor()
                                            : 'transparent',
                                    // top: `${startPercents}%`,
                                    top: `${
                                        startPercents + (sH % multiplicator) * (100 / multiplicator)
                                    }%`,
                                    padding: '10px 0'
                                }}
                            />
                        );
                    })
                ) : (
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: getColor()
                        }}
                    />
                )}
            </div>
        </div>
    );
};
