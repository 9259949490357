import { InsertRowAboveOutlined, InsertRowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Button, Divider, Flex, MenuProps, Skeleton, Splitter } from 'antd';
import { Toast } from 'antd-mobile';
import { isArray, isDefined, isEmpty } from 'is-lite/exports';
import { t } from 'i18next';
import * as JsSearch from 'js-search';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useActivate, useUnactivate } from 'react-activation';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UniversalBoundary } from 'components';
import { Response } from 'modules/services/backend-api/generated_api';
import { Action, Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { fnv1aHash } from 'smart/utils';
// import { useNotifications } from 'utils/hooks';
// import { LANGUAGES } from 'utils/i18n/i18n';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import {
    useAsync,
    useBoolean,
    useFirstMountState,
    useMedia,
    useUpdateEffect,
    useWindowSize
} from 'react-use';
import { useAsideWidth } from 'modules/layouts/DefaultLayout/components/Aside/Aside';
import { SmartTablePageFooterToolbar, SmartTablePageHeaderToolbar } from './components';
import { FilterField } from './components/SmartTableFilterMenu/types';
import { useAdvancedViewData, useFilters, useTableQueryAndActions } from './utils/hooks';
import { IHandlerWithId } from '../SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';
import { useHandlerRun } from '../../utils/hooks';

import { LazyIcon } from '../../ui';
import {
    HandlerRunModalLoaderOnly,
    RelatedObjectsModal,
    Stepper,
    StepperSkeleton
} from '../../components';
import './SmartTablePage.scss';
import { useLoadingFixedSavedQuery } from './components/SmartTableFilterMenu/SmartTableFilterMenuModal';

const SmartTable = lazy(() =>
    import('../../components').then((module) => ({ default: module.SmartTable }))
);
const GanttDiagram = lazy(() =>
    import('../../components').then((module) => ({ default: module.GanttDiagram }))
);
const KeepAlivedMapPage = lazy(() =>
    import('../../../pages/maps/MapPage').then((module) => ({ default: module.KeepAlivedMapPage }))
);
const Loader = lazy(() =>
    import('../../../ui/Loader/Loader').then((module) => ({ default: module.Loader }))
);
const HandlerRunModal = lazy(() =>
    import('../../components').then((module) => ({ default: module.HandlerRunModal }))
);

export interface SmartTablePageProps {
    meta: string;
    preFilters?: string;
    disableHeader?: boolean;
    disableFooter?: boolean;
    isReload?: boolean;
    isBuiltInAssociation?: boolean;
}

const SplitterEmptyBox = ({ children, ...props }) => {
    return <>{children}</>;
};

const customComponents = {
    body: () => <div style={{ padding: 20, textAlign: 'center' }}>{t('filter_first_message')}</div>
};

let initialScreensCount = 1;
let initialScreenDetails;

if (Object.hasOwn(window, 'getScreenDetails')) {
    (window as any).getScreenDetails().then((screenDetails) => {
        console.log(`Количество экранов: ${screenDetails.screens.length}`);
        console.log('Текущий экран:', screenDetails.currentScreen);
        initialScreensCount = screenDetails.screens.length;
        initialScreenDetails = screenDetails.screens;
    });
} else {
    console.log('API getScreenDetails() не поддерживается вашим браузером');
    window.navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
        const screens = stream.getVideoTracks().filter((track) => track.enabled);
        console.log('Мониторы:', screens.length);
        initialScreensCount = screens.length;
        initialScreenDetails = screens;
    });
}

export const SmartTablePage = observer<SmartTablePageProps>(
    ({ meta, disableHeader, disableFooter, isReload, isBuiltInAssociation, preFilters = '' }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const location = useLocation();
        const storeNavigate = useStoreNavigate();
        const isBigMobile = useMedia('(max-width: 480px)');
        // const layoutContainerElement = document.querySelector('main.ant-layout-content');
        // const layutContainerWidth = layoutContainerElement?.clientWidth;
        // const [layutContainerWidth, setLay] = layoutContainerElement?.clientWidth;
        const [windowWidth, setWindowWidth] = useState(0);
        const { height: windowHeight } = useWindowSize();
        // const windowWidth = layutContainerWidth;

        useEffect(() => {
            const element = document.querySelector('main.ant-layout-content') as HTMLDivElement;
            if (!element) return;

            const resizeObserver = new ResizeObserver(([entry]) => {
                setWindowWidth(entry.contentRect.width);
            });

            resizeObserver.observe(element);

            return () => resizeObserver.disconnect();
        }, []);
        // console.log(layoutContainerElement);

        const currentPath = `${location.pathname}${preFilters ? `?${preFilters}` : ''}`;

        const routesMap = metaStore.meta.get('all')?.routesMap;

        const metaData = metaStore.meta.get(meta);
        // const metaDataInfo = useRef(metaData?.info);
        const [metaDataInfo, setMetaDataInfo] = useState(metaData?.info);
        const viewFieldName = metaDataInfo?.ViewFieldName;
        const fields = metaDataInfo?.Fields;

        const hasFilterFromState =
            isDefined(location.state?.filters) &&
            isArray(location.state?.filters) &&
            !isEmpty(location.state?.filters);

        const hasFilterStringFromState =
            isDefined(location.state?.filterString) && !isEmpty(location.state?.filterString);

        const isFilterFirst = metaDataInfo?.IsFilterFirst ?? true;
        const isMetaReadOnly = metaDataInfo?.IsReadOnly ?? false;
        const uiAllowCreate = metaDataInfo?.UiAllowCreate ?? false;
        const uiAllowDelete = metaDataInfo?.UiAllowDelete ?? false;
        const uiAllowEdit = metaDataInfo?.UiAllowEdit ?? false;
        const uiAllowView = metaDataInfo?.UiAllowView ?? true;
        const uiAllowSelect = metaDataInfo?.UiAllowSelect ?? true;

        // ### ДАННЫЕ (fields, data)
        const [dataSource, setDataSource] = useState<any[]>([]);
        const [fieldsSource, setFieldsSource] = useState<MetaField[]>([]);
        const [localSearchFilter, setLocalSearchFilter] = useState('');
        const [selectedRows, setSelectedRows] = useState<IObjectWithId[]>([]);
        // const [contextMenuActions, setContextMenuActions] = useState([]);
        const [handlerModal, setHandlerModal] = useState(false);
        const [handlerModalLoader, setHandlerModalLoader] = useState(false);
        const [showRelatedObjectsModal, setShowRelatedObjectsModal] = useState(false);
        const [handlerModalData, setHandlerModalData] = useState<Action | null>(null);
        const [handlerModalHardIds, setHandlerModalHardIds] = useState<string[] | undefined>();
        const [splitterLayout, setSplitterLayout] = useState<'vertical' | 'horizontal'>('vertical');
        const [splitterSizes, setSplitterSizes] = useState<(number | string)[]>([
            // windowHeight - 22 * 13.48,
            windowHeight - 15 * 13.48,
            (windowHeight - 15 * 13.48) / 4
        ]);
        // const [dpModal, setDpModal] = useState(false);
        const [loadingFixedSavedQuery] = useLoadingFixedSavedQuery();

        const tableLayout = useMemo(() => {
            const layout = toJS(
                routesMap?.get(meta)?.find((r) => r.path === currentPath)?.layout?.TablePage
            );

            const mobile = layout?.TablePageMobile;
            const desktop = layout?.TablePageDesktop;
            const any = layout?.TablePage;

            return { any, mobile, desktop };
        }, [currentPath, meta, routesMap]);

        const filtersFromLayout = useMemo(() => {
            const platformCategory = isBigMobile ? 'mobile' : 'desktop';

            const layoutFields =
                tableLayout?.[platformCategory]?.Fields ?? tableLayout?.any?.Fields;

            if (layoutFields) {
                const result: FilterField[] = [];

                for (const layoutField of layoutFields) {
                    if (layoutField.Filter) {
                        const field = fieldsSource.find(
                            (fs) => fs.FieldName === layoutField.FieldName
                        );

                        if (field) {
                            result.push({
                                field,
                                values: [
                                    {
                                        column: field?.ColumnName,
                                        operator: layoutField.Filter.operator,
                                        value: layoutField.Filter.value
                                    }
                                ]
                            });
                        }
                    }
                }

                return result;
            }

            return [];
        }, [fieldsSource, isBigMobile, tableLayout]);

        // console.log(filtersFromLayout);

        const selectedRowKeys = useMemo(
            () => selectedRows.map(({ Id }) => String(Id)),
            [selectedRows]
        );
        const hasSelected = selectedRows.length > 0;

        const { run } = useHandlerRun();
        const { filters, setFilters, filterString } = useFilters(preFilters, isBuiltInAssociation);
        // console.log(filters);
        const { renderer, associations, dataArray, clearAssociations, setAssociations } =
            useAdvancedViewData(meta, selectedRowKeys);
        // console.log(isFilterFirst, filters?.length, hasFilterFromState, hasFilterStringFromState);

        const isFetchibleInit = Boolean(
            !isFilterFirst ||
                (isFilterFirst && filters?.length) ||
                hasFilterFromState ||
                hasFilterStringFromState
        );

        const [isFetchable, setIsFetchable] = useBoolean(isFetchibleInit);
        const {
            fetch,
            isLoading,
            refresh,
            remove: commonRemove,
            create,
            copy,
            edit
        } = useTableQueryAndActions({
            meta,
            selectedRows,
            filterString,
            preFilters,
            currentPathForGetMetaInfo: currentPath,
            setSelectedRows
        });

        const { value: checkDepenciesBeforeDelete } = useAsync(
            async () =>
                metaStore.meta.get('all')?.params?.CHECK_DEPENDENCIES_BEFORE_DELETE?.param_value ??
                metaStore.meta.get('all')?.params?.CHECK_DEPENDENCIES_BEFORE_DELETE ??
                metaStore.getParam({
                    param_name: 'CHECK_DEPENDENCIES_BEFORE_DELETE',
                    default_value: false
                }),
            []
        );

        const remove = useCallback((value?: string, rowData?: IObjectWithId) => {
            if (checkDepenciesBeforeDelete) {
                setShowRelatedObjectsModal(true);
            } else {
                commonRemove(value, rowData);
            }
        }, [checkDepenciesBeforeDelete, commonRemove]);

        useUpdateEffect(() => {
            if (isReload) {
                refresh();
            }
        }, [isReload]);

        const refreshAndUpdateSelectedRows = useCallback(async () => {
            const updatedRows = await refresh(true);

            if (
                Array.isArray(updatedRows.select?.objects) &&
                updatedRows.select?.objects.length > 0
            ) {
                const updatedDataSource: Record<string, IObjectWithId> = {}; // ;
                updatedRows.select?.objects.forEach((row) => {
                    updatedDataSource[(row as IObjectWithId).Id] = row as IObjectWithId;
                });
                setDataSource((prev) => {
                    return prev.map((row) => {
                        const rowId = row.Id;
                        const updatedRow = updatedDataSource[rowId];

                        if (updatedRow) {
                            return updatedRow;
                        }

                        return row;
                    });
                });
            }

            // console.log('updatedRows:', updatedRows);
        }, [refresh, setDataSource]);

        useUpdateEffect(() => {
            setIsFetchable(isFetchibleInit);
        }, [isFetchibleInit]);

        if (!uiAllowSelect)
            return (
                <UniversalBoundary
                    status="403"
                    // title={t('no_access') as string}
                    title="403"
                    subTitle={t('no_access_message') as string}
                    replaceGoBackToGoHome
                />
            );

        const isMount = useFirstMountState();

        // ### ЗАПРОС ДАННЫХ С СЕРВЕРА (info, select) ###
        useAsync(async () => {
            const { info, select } = await fetch({
                fetchInfo: isMount,
                fetchSelect: isFetchable
            });

            if (isMount) {
                setMetaDataInfo(info);
            }

            console.log('[SmartTablePage] meta info:', info);
            // console.log('[SmartTablePage] meta select data:', select);
        }, [meta, isFetchable, filterString]);
        // }, [fetch, meta, isFetchable, filterString]);

        // console.log(metaStore.meta.get(meta)?.info);

        useEffect(() => {
            const platformCategory = isBigMobile ? 'mobile' : 'desktop';

            const layoutFields =
                tableLayout?.[platformCategory]?.Fields ?? tableLayout?.any?.Fields;
            // console.log('EFFECT INFO');
            const result = (toJS(fields) ?? [])?.map((field) => ({
                ...field,
                Options: {
                    ...field.Options,
                    UIWidth: layoutFields?.find((lfield) => lfield.FieldName === field.FieldName)
                        ?.Width
                }
            }));

            // console.log(result);
            setFieldsSource(result);
        }, [meta, fields, isBigMobile, tableLayout]);

        useEffect(() => {
            const cachedFilterString = metaData?.select?.filters;

            // console.log(cachedFilterString, filterString, toJS(metaData?.select?.objects));

            if (cachedFilterString === filterString) {
                // console.log('SET DATASOURCE');
                setDataSource([...(toJS(metaData?.select?.objects) || [])]);
            }
        }, [meta, filterString, metaData?.select?.objects]);

        const handleNavigateAfterAction = useCallback(
            (
                actionResponse: Response,
                action: Action,
                filters: FilterField[] | Filter[],
                metaFields?: MetaField[]
            ) => {
                const locationPathName = location.pathname;

                const actionName = action.Name;
                const actionNavItem = action.NavItem;
                const actionNavItemPath = actionNavItem?.Path;
                // console.log('AFTER ACTION', actionResponse, action);

                const pathnameLastElem = locationPathName.split('/').at(-1)?.trim();

                let pageTitle = location.state?.pageTitle || pathnameLastElem;
                if (actionName) {
                    // pageTitle = actionName[language];
                    pageTitle = actionName;
                }

                let pathname = locationPathName;

                const cacheKeyBodyPermanent = selectedRowKeys ?? action?.Id ?? {};
                const cacheKeyBody = JSON.stringify(
                    filters?.length ? filters : cacheKeyBodyPermanent
                );
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${cacheKeyBody}`);

                let filterString;

                if (actionNavItemPath) {
                    // pathname = action.NavItem.Path;
                    pathname = actionNavItemPath.includes('?')
                        ? actionNavItemPath.split('?')[0]
                        : actionNavItemPath; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    filterString = actionNavItemPath.includes('?')
                        ? actionNavItemPath.split('?')[1]
                        : undefined;
                    cacheKey = fnv1aHash(`${actionNavItem.Id}_${cacheKeyBody}`);
                } else if (action.Association?.TargetMeta_Code) {
                    pathname = `/other/${action.Association?.TargetMeta_Code}`;
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(`${pathname}_filtered_${cacheKeyBody}`);
                }

                const runResponse = actionResponse.run?.[0];

                if (action?.Handler?.MethodName === 'CreateBasedOn') {
                    pathname = `${pathname}/copy`;
                    const id = runResponse?.Object_Ids[0];
                    cacheKey = fnv1aHash(
                        `${pathnameLastElem}_filtered_${JSON.stringify(filters)}_from_${id}`
                    );
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            responseData: {
                                ...runResponse?.Result,
                                ids: selectedRowKeys
                            },
                            metaFields,
                            data: selectedRows?.at(-1),
                            filterString
                        }
                    }
                );
            },
            [location.pathname, location.state?.pageTitle, selectedRowKeys, storeNavigate]
        );

        const handleNavigateBeforeAction = useCallback(
            ({
                action,
                filters,
                metaFields,
                hardIds,
                hardArgs
            }: {
                action: Action;
                filters: FilterField[] | Filter[];
                metaFields?: MetaField[];
                hardIds?: string[];
                hardArgs?: PlainObject;
            }) => {
                const locationPathName = location.pathname;

                const actionName = action.Name;
                const actionNavItem = action.NavItem;
                const actionNavItemPath = actionNavItem?.Path;

                const pathnameLastElem = locationPathName.split('/').at(-1)?.trim();

                let pageTitle = location.state?.pageTitle || pathnameLastElem;
                if (actionName) {
                    // pageTitle = action.Name[language];
                    pageTitle = action.Name;
                }

                let pathname = locationPathName;
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                const cacheKeyBodyPermanent = hardIds ?? selectedRowKeys ?? action?.Id ?? {};
                const cacheKeyBody = JSON.stringify(
                    filters?.length ? filters : cacheKeyBodyPermanent
                );

                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${cacheKeyBody}`);
                let filterString;

                if (actionNavItemPath) {
                    // pathname = action.NavItem.Path;
                    pathname = actionNavItemPath.includes('?')
                        ? actionNavItemPath.split('?')[0]
                        : actionNavItemPath; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    filterString = actionNavItemPath.includes('?')
                        ? actionNavItemPath.split('?')[1]
                        : undefined;
                    cacheKey = fnv1aHash(`${actionNavItem.Id}_${cacheKeyBody}`);
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            requestData: {
                                action,
                                ids: hardIds ?? selectedRowKeys,
                                args: hardArgs ?? {}
                            },
                            metaFields,
                            data: selectedRows?.at(-1),
                            filterString
                        }
                    }
                );
            },
            [location.pathname, location.state?.pageTitle, selectedRowKeys, storeNavigate]
        );

        const actionClickHandler = useCallback(
            async (
                action: Action,
                hardIds?: string[],
                hardArgs?: PlainObject
            ): Promise<Response | undefined> => {
                // console.log('HANDLER CLICK', action);
                const handlerCode = action.Handler_Code;

                if (action.Association) {
                    const metaField = fields?.find((field) => field.FieldName === 'Id');

                    let association;
                    const actionResponse = {
                        session_id: '',
                        request_id: '',
                        status_code: 200
                    };

                    association = await metaStore.getAssociation({
                        association: action.Association.Code,
                        meta: meta || action.Meta_Code,
                        ids: selectedRowKeys
                    });

                    const filters = [
                        {
                            column: 'id',
                            operator: 'in_list',
                            value: association?.ids
                        }
                    ];

                    handleNavigateAfterAction(
                        actionResponse,
                        action,
                        filters,
                        metaField ? [toJS(metaField)] : []
                    );
                    return;
                }

                if (action.IsNavigationFirst) {
                    handleNavigateBeforeAction({ action, hardArgs, hardIds, filters });
                } else if (handlerCode) {
                    const handler = action.Handler;
                    const params = handler?.ChildParams ?? [];

                    setHandlerModalData({
                        ...action,
                        Handler: { ...handler, Id: action.Id! } as IHandlerWithId
                    });

                    const isShowModal =
                        handler &&
                        ((params.length && !params.every((param) => !param.IsRequested)) ||
                            handlerCode === 'MassUpdate' ||
                            handlerCode === 'MassChildUpsert');

                    if (isShowModal) {
                        setHandlerModal(true);
                        setHandlerModalHardIds(hardIds);
                    } else {
                        setHandlerModalLoader(true);
                        const response = await run(
                            {
                                Action_Id: action.Id!,
                                meta: action.Meta_Code,
                                ids: hardIds || selectedRowKeys,
                                handler: handlerCode,
                                args: hardArgs || {}
                            },
                            action.IsLogResultHidden
                        );
                        setHandlerModalLoader(false);

                        if (action.NavItem) {
                            handleNavigateAfterAction(response, action);
                        }

                        // const updatedRows = await refresh(true);
                        await refreshAndUpdateSelectedRows();

                        // console.log('updatedRows:', updatedRows);
                        return response;
                    }
                }

                return undefined;
            },
            // [refresh, run, selectedRowKeys, setLogMessages]
            [
                filters,
                handleNavigateBeforeAction,
                handleNavigateAfterAction,
                refresh,
                run,
                fields,
                meta,
                selectedRowKeys
            ]
        );

        const { value: caseSensitiveSearch } = useAsync(
            async () =>
                metaStore.meta.get('all')?.params?.CASE_SENSITIVE_SEARCH?.param_value ??
                metaStore.meta.get('all')?.params?.CASE_SENSITIVE_SEARCH ??
                metaStore.getParam({ param_name: 'CASE_SENSITIVE_SEARCH', default_value: true }),
            []
        );

        // ### НАЙДЕННЫЕ по ПОИСКУ данные из dataSource
        const searchedDataSource = useMemo(() => {
            if (localSearchFilter) {
                const index = new JsSearch.Search('Id');
                // index.tokenizer = new JsSearch.
                if (caseSensitiveSearch) {
                    index.sanitizer = new JsSearch.CaseSensitiveSanitizer();
                }
                index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

                fieldsSource
                    .filter((field) => !field.IsHiddenOnTable)
                    .forEach((item) => {
                        index.addIndex(item.FieldName);
                        index.addIndex([item.FieldName, 'Name', language]);
                        index.addIndex([item.FieldName, 'DisplayName', language]);
                        index.addIndex([item.FieldName, 'PluralName', language]);
                        index.addIndex([item.FieldName, 'SingularName', language]);
                        index.addIndex([item.FieldName, 'Key']);
                        index.addIndex([item.FieldName, language]);
                    });

                index.addDocuments(
                    // dataSource.map((d) => ({ ...d, Parent: undefined, children: undefined })) || []
                    // dataSource.map((d) => ({ ...d, children: undefined })) || []
                    // dataSource.map((d) => ({ ...d })) || []
                    dataSource || []
                );

                return index.search(localSearchFilter);
            }

            return dataSource || [];
        }, [dataSource, fieldsSource, language, localSearchFilter, caseSensitiveSearch]);

        useUpdateEffect(() => {
            setSelectedRows([]);
        }, [meta, filterString, isFetchable]);

        const [screensCount, setScreensCount] = useState(initialScreensCount);
        const [screens, setScreens] = useState(initialScreenDetails);
        const [primaryScreen, setPrimaryScreen] = useState();

        const [asideWidth] = useAsideWidth();

        useAsync(async () => {
            if (Object.hasOwn(window, 'getScreenDetails')) {
                const screenDetails = await (window as any).getScreenDetails();
                const screens = screenDetails.screens;

                // const primaryScreen = screens.find((s: Screen) => s.isPrimary);

                console.log(`Количество экранов: ${screens.length}`);
                console.log('Текущий экран:', screenDetails.currentScreen);
                // console.log("Главный экран:", primaryScreen);
                setScreensCount(screens.length);
                setScreens(screens);
                setPrimaryScreen(screenDetails.currentScreen);
            }
        }, []);

        const enableRightSplitterFrame = true;
        // metaStore.meta.get('all')?.params?.ENABLE_RIGHT_SPLITTER_FRAME && screensCount > 1;

        // TODO: переделать на кастом без коллбэка
        const titleRender = useCallback(
            (isMobile?: boolean, row?: IObjectWithId) => {
                const mobile = typeof isMobile !== 'boolean' ? false : isMobile;

                let showComponents;
                let disableRootComponent = !mobile;

                if (!mobile) {
                    const platformCategory = isBigMobile ? 'mobile' : 'desktop';
                    const layoutToolbarSettings =
                        tableLayout?.[platformCategory]?.Toolbar ?? tableLayout?.any?.Toolbar;

                    const defaultToolbarElementStatus = !isBigMobile;

                    const isCreate =
                        layoutToolbarSettings?.ShowCreate ?? defaultToolbarElementStatus;
                    const isReload =
                        layoutToolbarSettings?.ShowReload ?? defaultToolbarElementStatus;
                    const isDelete =
                        layoutToolbarSettings?.ShowDelete ?? defaultToolbarElementStatus;
                    const isSearch =
                        layoutToolbarSettings?.ShowSearch ?? defaultToolbarElementStatus;
                    const isFilter =
                        layoutToolbarSettings?.ShowFilters ?? defaultToolbarElementStatus;
                    const isCopyLink =
                        layoutToolbarSettings?.ShowLink ?? defaultToolbarElementStatus;
                    const isQRCodeButton =
                        layoutToolbarSettings?.ShowQRCode ?? defaultToolbarElementStatus;
                    const isDescription =
                        layoutToolbarSettings?.ShowDescription ?? defaultToolbarElementStatus;
                    const isActionButtons =
                        layoutToolbarSettings?.ShowActionButtons ?? defaultToolbarElementStatus;

                    disableRootComponent =
                        !isCreate &&
                        !isReload &&
                        !isDelete &&
                        !isSearch &&
                        !isFilter &&
                        !isCopyLink &&
                        !isDescription &&
                        !isActionButtons &&
                        !isQRCodeButton;

                    showComponents = {
                        isCreate,
                        isDelete,
                        isSearch,
                        isFilter,
                        isCopyLink,
                        isReload,
                        isDescription,
                        isActionButtons,
                        isQRCodeButton
                    };
                }

                const component = (
                    <SmartTablePageHeaderToolbar
                        meta={meta}
                        selectedRows={row ? [row] : selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedRowKeys={row ? [row.Id] : selectedRowKeys}
                        hasSelected={row ? true : hasSelected}
                        searchFilter={localSearchFilter}
                        setSearchFilter={setLocalSearchFilter}
                        setFilters={setFilters}
                        filters={filters}
                        filtersFromLayout={filtersFromLayout}
                        filterString={filterString}
                        onRefresh={async () => {
                            await refresh();
                            setIsFetchable(true);
                        }}
                        onCreate={create}
                        onCopy={copy}
                        onDelete={remove}
                        navigateAfterRun={handleNavigateAfterAction}
                        onClickAction={actionClickHandler}
                        detailsRenderer={renderer}
                        isMobile={mobile}
                        showComponents={showComponents}
                    />
                );

                if (disableRootComponent) return <div style={{ display: 'none' }}>{component}</div>;

                return component;
            },
            [
                meta,
                selectedRowKeys,
                hasSelected,
                localSearchFilter,
                filters,
                filterString,
                refresh,
                create,
                copy,
                remove,
                handleNavigateAfterAction,
                actionClickHandler,
                filtersFromLayout,
                isBigMobile,
                tableLayout
                // handleSelectAdvancedView,
                // handleDeselectAdvancedView,
                // advancedViewData
            ]
        );

        const footerRender = useCallback(
            () => (
                <SmartTablePageFooterToolbar
                    meta={meta}
                    selectedRows={selectedRows}
                    selectedRowKeys={selectedRowKeys}
                    hasSelected={hasSelected}
                    totalDataCount={isFetchable ? searchedDataSource.length : 0}
                    fieldsSource={fieldsSource}
                    onClickAction={actionClickHandler}
                />
            ),
            [
                actionClickHandler,
                fieldsSource,
                hasSelected,
                meta,
                searchedDataSource.length,
                selectedRowKeys,
                selectedRows,
                isFetchable
            ]
        );

        const mobileCardToolbarRender = useCallback(
            (row: IObjectWithId) => titleRender(true, row),
            [titleRender]
        );

        const rowContextMenuItems: MenuProps['items'] = useMemo(() => {
            const upFromDivider: MenuProps['items'] = [];
            const downFromDivider: MenuProps['items'] = [];

            upFromDivider.push({
                key: 'view',
                label: t('view'),
                icon: <LazyIcon className="context_item" icon={'EyeOutlined'} />,
                onClick: (value: string, rowData: IObjectWithId) => edit(value, rowData, { openViewModeFirst: true })
            });
            upFromDivider.push({
                key: 'reload',
                label: t('reload'),
                icon: <LazyIcon className="context_item" icon={'ReloadOutlined'} />,
                onClick: async () => refresh()
            });
            downFromDivider.push({
                key: 'copy_link',
                label: t('copy_link'),
                icon: <LazyIcon className="context_item" icon={'mdiLinkVariant'} />,
                onClick: () => {
                    navigator.clipboard.writeText(`${window.location.href}/${selectedRows[0]?.Id}`);
                    Toast.show({
                        content: t('copied')
                    });
                }
            });
            downFromDivider.push({
                key: 'copy_value',
                label: t('copy_value'),
                icon: <LazyIcon className="context_item" icon={'mdiContentCopy'} />,
                // @ts-ignore
                onClick: (value: string) => {
                    // alert(value);
                    navigator.clipboard.writeText(value);
                    Toast.show({
                        content: t('copied')
                    });
                    // navigator.clipboard.writeText(`${window.location.href}/${selectedRows[0]?.Id}`);
                    // Toast.show({
                    //     content: t('copied')
                    // });
                }
            });

            if (uiAllowCreate) {
                upFromDivider.push({
                    key: 'create',
                    label: t('create'),
                    icon: <LazyIcon className="context_item" icon={'PlusOutlined'} />,
                    onClick: create,
                    disabled: isMetaReadOnly
                });
                downFromDivider.push({
                    key: 'copy_object',
                    label: t('copy_object'),
                    icon: <LazyIcon className="context_item" icon={'FileAddOutlined'} />,
                    onClick: copy,
                    disabled: isMetaReadOnly
                });
            }

            if (uiAllowEdit) {
                downFromDivider.push({
                    key: 'edit',
                    label: t('edit'),
                    icon: <LazyIcon className="context_item" icon={'EditOutlined'} />,
                    onClick: edit,

                    disabled: !uiAllowEdit || isMetaReadOnly
                });
            }

            if (uiAllowDelete) {
                downFromDivider.push({
                    key: 'delete',
                    label: t('delete'),
                    icon: <LazyIcon className="context_item" icon={'DeleteOutlined'} />,
                    onClick: remove,
                    disabled: !uiAllowDelete || isMetaReadOnly
                });
            }

            return [...upFromDivider, { type: 'divider' }, ...downFromDivider];
        }, [
            copy,
            create,
            edit,
            isMetaReadOnly,
            // selectedRows,
            remove,
            t,
            uiAllowCreate,
            uiAllowDelete,
            uiAllowEdit,
            checkDepenciesBeforeDelete
        ]);

        // Карта не работает с Keep Alive. поэтому руками реагируем на замораживание и обходим его для карты
        const cachedAssociations = useRef<PlainObject[]>([]);
        useActivate(() => {
            if (cachedAssociations.current.length) {
                setAssociations(cachedAssociations.current);
                cachedAssociations.current = [];
            }
        });
        useUnactivate(() => {
            if (associations[0]?.data?.action?.NavItem?.Component?.includes('MapPage')) {
                cachedAssociations.current = associations;
                clearAssociations();
            }
        });

        const frame = useMemo(() => {
            const frame = associations[0];
            const frameData = frame?.data;
            const frameAction = frameData?.action;
            const associationCode = frameAction?.Association_Code;
            const navItemComponent = frameAction?.NavItem?.Component;
            const targetMeta = frameData?.targetMetaCode;
            const frameLabel = frame?.label as string;
            const isGantt = navItemComponent?.includes('Gantt');
            const isMap = navItemComponent?.includes('MapPage');
            const isStepper = navItemComponent?.includes('Stepper');

            const tableSource = dataArray.value?.[0];
            const fields = (tableSource?.info as Meta)?.Fields ?? [];
            const viewFieldName = (tableSource?.info as Meta)?.ViewFieldName;
            const tableData = tableSource?.select?.objects ?? [];

            return {
                data: frameData,
                action: frameAction,
                label: frameLabel,
                targetMeta,
                associationCode,
                isGantt,
                isMap,
                isStepper,
                tableData,
                viewFieldName,
                tableFields: fields
            };
        }, [associations, dataArray.value]);

        const mapAndGanttRequestParams = useMemo(() => {
            const action = associations[0]?.data?.action;

            if (action?.Association_Code) {
                return {
                    action,
                    ids: frame.tableData.map((d) => d.Id),
                    args: {}
                };
            }

            return {
                action,
                ids: selectedRowKeys,
                args: {}
            };
        }, [associations, frame.tableData, selectedRowKeys]);

        const loadingRequestParams = frame.associationCode
            ? dataArray.loading || !dataArray.value?.length
            : false;

        const enableFrameSplitter = metaStore.meta.get('all')?.params?.ENABLE_FRAME_SPLITTER;

        const fullSplitterHeight = windowHeight - 11 * 13.48;
        const fullSplitterWidth = windowWidth;
        const fullSplitterSize =
            splitterLayout === 'horizontal' ? fullSplitterWidth : fullSplitterHeight;

        const mainTableScrollHeight = enableFrameSplitter
            ? splitterLayout === 'horizontal'
                ? fullSplitterHeight - 7 * 13.48
                : splitterSizes[0] - 7 * 13.7
            : frame.isStepper
            ? windowHeight - 22 * 13.48 - 140
            : searchedDataSource?.length > 50
            ? ((windowHeight - 22 * 13.48) / 3.25) * 2
            : ((windowHeight - 18 * 13.48) / 3.25) * 2;

        const frameGantHeight = enableFrameSplitter
            ? splitterLayout === 'horizontal'
                ? fullSplitterHeight
                : splitterSizes[1] - 8 * 13.7
            : (windowHeight - 22 * 13.48) / 4;

        const frameMapHeight = enableFrameSplitter
            ? splitterLayout === 'horizontal'
                ? fullSplitterHeight - 49
                : splitterSizes[1] - 49
            : 'calc((100vh - 13rem) / 3)';

        const frameTableScrollHeight = enableFrameSplitter
            ? splitterLayout === 'horizontal'
                ? fullSplitterHeight - 7 * 13.48
                : splitterSizes[1] - 7 * 13.7
            : searchedDataSource?.length > 50
            ? (windowHeight - 22 * 13.48) / 4
            : (windowHeight - 18 * 13.48) / 4; // 'calc((100vh - 18.5rem) / 4)';

        const settedSplitterSizes = useRef(false);

        useUpdateEffect(() => {
            setSplitterSizes([(fullSplitterWidth / 3) * 2, fullSplitterWidth / 3]);
        }, [splitterLayout]);

        useEffect(() => {
            if (associations.length && selectedRowKeys.length && !settedSplitterSizes.current) {
                setSplitterSizes([(fullSplitterSize / 3) * 2, fullSplitterSize / 3]);
                settedSplitterSizes.current = true;
            }
        }, [associations.length, selectedRowKeys.length, fullSplitterSize]);

        const SplitterContainter = useMemo(
            () => (enableFrameSplitter ? (props) => <Splitter {...props} /> : SplitterEmptyBox),
            [enableFrameSplitter, splitterLayout]
        );
        const SplitterPanel = enableFrameSplitter ? Splitter.Panel : SplitterEmptyBox;

        const splitterButton = enableRightSplitterFrame ? (
            <Button
                type={'default'}
                icon={
                    splitterLayout === 'horizontal' ? (
                        <InsertRowLeftOutlined />
                    ) : (
                        <InsertRowAboveOutlined />
                    )
                }
                onClick={() =>
                    setSplitterLayout((prev) => (prev === 'vertical' ? 'horizontal' : 'vertical'))
                }
            />
        ) : null;

        const asideWidthWithPaddings = asideWidth + 40;
        const isSizedAboutOtherScreens =
            screensCount > 1 && windowWidth > primaryScreen?.width - asideWidthWithPaddings;
        const isFullSplitterScreenOnly =
            isSizedAboutOtherScreens &&
            (!associations.length || !selectedRowKeys.length || splitterLayout !== 'horizontal');
        const maximumCurrentScrrenSize = Math.max(
            windowWidth / screensCount - asideWidthWithPaddings,
            primaryScreen?.width - asideWidthWithPaddings
        );
        const maxMainFrameSize =
            splitterLayout === 'horizontal'
                ? isSizedAboutOtherScreens
                    ? maximumCurrentScrrenSize
                    : fullSplitterWidth
                : fullSplitterSize;

        return (
            <Suspense fallback={<Skeleton />}>
                <SplitterContainter
                    style={
                        splitterLayout
                            ? {
                                  height: fullSplitterHeight,
                                  width: isFullSplitterScreenOnly
                                      ? maximumCurrentScrrenSize
                                      : fullSplitterWidth
                              }
                            : { height: fullSplitterHeight }
                    }
                    layout={splitterLayout}
                    onResize={setSplitterSizes}
                >
                    <SplitterPanel
                        // max={fullSplitterSize}
                        max={maxMainFrameSize}
                        // min={fullSplitterSize / 4}
                        min={maxMainFrameSize / 4}
                        size={splitterSizes[0]}
                        style={{ overflow: 'hidden' }}
                    >
                        <SmartTable
                            components={!isFetchable ? customComponents : undefined}
                            doubleClickable
                            onDoubleClick={!uiAllowView ? () => {} : undefined}
                            meta={meta}
                            loading={isLoading || loadingFixedSavedQuery}
                            data={searchedDataSource}
                            fields={fieldsSource}
                            selectedRows={selectedRows}
                            onRowSelectionChange={setSelectedRows}
                            titleRender={!disableHeader ? titleRender : undefined}
                            footerRender={!disableFooter ? footerRender : undefined}
                            mobileCardToolbarRender={
                                isBigMobile ? mobileCardToolbarRender : undefined
                            }
                            leftFixedField={viewFieldName}
                            rowContextMenuItems={rowContextMenuItems}
                            noDataRenderer={() => (
                                <div style={{ padding: 20, textAlign: 'center' }}>
                                    {t('filter_first_message')}
                                </div>
                            )}
                            scroll={
                                associations.length && selectedRowKeys.length
                                    ? {
                                          y: mainTableScrollHeight
                                      }
                                    : undefined
                            }
                        />
                    </SplitterPanel>

                    {!enableFrameSplitter && (
                        <Divider style={{ margin: '3px 0', borderBlockStart: 'none' }} />
                    )}

                    {!!associations.length && !!selectedRowKeys.length && (
                        <SplitterPanel
                            max={
                                splitterLayout === 'horizontal'
                                    ? (fullSplitterSize / 3) * 2
                                    : ((windowHeight - 15 * 13.48) / 3) * 2
                            }
                            min={
                                splitterLayout === 'horizontal'
                                    ? fullSplitterSize / 4
                                    : (windowHeight - 15 * 13.48) / 4
                            }
                            size={splitterSizes[1]}
                            style={{ overflow: 'hidden' }}
                        >
                            {frame.isGantt ? (
                                <Suspense fallback={<Skeleton />}>
                                    <GanttDiagram
                                        height={frameGantHeight}
                                        meta={meta}
                                        loadingRequestParams={loadingRequestParams}
                                        requestParams={mapAndGanttRequestParams}
                                        title={<strong>{frame.label}</strong>}
                                        extra={splitterButton}
                                    />
                                </Suspense>
                            ) : frame.isMap ? (
                                <Suspense fallback={<Skeleton />}>
                                    <KeepAlivedMapPage
                                        // title={<strong>{frameLabel}</strong>}
                                        loadingRequestParams={loadingRequestParams}
                                        mapContainerStyles={{ height: frameMapHeight }}
                                        preRequestData={mapAndGanttRequestParams}
                                        title={<strong>{frame.label}</strong>}
                                        extra={splitterButton}
                                        disableWrapper={false}
                                    />
                                </Suspense>
                            ) : frame.isStepper ? (
                                <Suspense fallback={<StepperSkeleton />}>
                                    <Stepper
                                        loading={loadingRequestParams}
                                        ids={mapAndGanttRequestParams?.ids}
                                        title={<strong>{frame.label}</strong>}
                                        extra={splitterButton}
                                    />
                                </Suspense>
                            ) : (
                                <Suspense fallback={<Skeleton />}>
                                    <Loader status={dataArray.loading}>
                                        <SmartTable
                                            titleRender={() => (
                                                <Flex justify="space-between">
                                                    <strong>{frame.label} </strong>
                                                    <Flex align="senter" gap={5}>
                                                        {splitterButton}
                                                        <Button
                                                            icon={<ReloadOutlined />}
                                                            onClick={dataArray.retry}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            )}
                                            meta={frame.targetMeta}
                                            fields={frame.tableFields}
                                            data={frame.tableData}
                                            defaultSortOrder={{ ascend: ['ChildIndex'] }}
                                            virtual
                                            selectable={false}
                                            doubleClickable
                                            leftFixedField={frame.viewFieldName}
                                            scroll={
                                                associations.length && selectedRowKeys.length
                                                    ? {
                                                          y: frameTableScrollHeight
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </Loader>
                                </Suspense>
                            )}
                        </SplitterPanel>
                    )}
                </SplitterContainter>

                {handlerModal && (
                    <Suspense fallback={<Skeleton />}>
                        <HandlerRunModal
                            open={handlerModal}
                            onCancel={() => {
                                setHandlerModal(false);
                                setHandlerModalData(null);
                                setHandlerModalHardIds(undefined);
                            }}
                            action={handlerModalData}
                            ids={handlerModalHardIds || selectedRowKeys}
                            onRefresh={async () => refreshAndUpdateSelectedRows()} // refresh(true)}
                            metaName={meta}
                            navigateAfterRun={handleNavigateAfterAction}
                            filters={filters}
                            meta={meta}
                            row={selectedRows[0]}
                            onResponseCallback={(handlerRun) => {
                                // refresh(true);
                            }}
                        />
                    </Suspense>
                )}

                {handlerModalLoader && (
                    <Suspense fallback={<Skeleton />}>
                        <HandlerRunModalLoaderOnly
                            open={handlerModalLoader}
                            onCancel={() => {
                                setHandlerModalLoader(false);
                                setHandlerModalData(null);
                            }}
                            action={handlerModalData}
                        />
                    </Suspense>
                )}

                {showRelatedObjectsModal && (
                    <Suspense fallback={<Skeleton />}>
                        <RelatedObjectsModal
                            open={showRelatedObjectsModal}
                            setOpen={setShowRelatedObjectsModal}
                            sourceObjects={selectedRows}
                            meta={meta}
                            commonDelete={commonRemove}
                            refresh={async () => {
                                await refresh();
                                setIsFetchable(true);
                            }}
                        />
                    </Suspense>
                )}
            </Suspense>
        );
    }
);
