import { createItvecturaClient } from './itvecturaClient';
import { ApiClient, ApiClientConfig } from './types';

export const createApiClient = (config: ApiClientConfig): ApiClient => {
    const { session } = config;

    // if (authProvider === 'itvectura') {
    const itvecturaClient = createItvecturaClient();
    if (session) {
        // Восстанавливаем сессию, если передана
        itvecturaClient.session = session;
    }
    return itvecturaClient;
    // }

    // throw new Error(`Unsupported auth provider: ${authProvider}`);
};
