import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './SmartDurationField.scss';
import { useMedia } from 'react-use';
import { Variant } from 'antd/es/config-provider';

interface SmartDurationFieldProps {
    value: number | null;
    onChange: (newValue: number | null) => void;
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
    variant?: Variant;
}

type DurationComponentsType = {
    isNegative: boolean;
    minutes: number;
    days: number;
    hours: number;
    seconds: number;
};

const parseSecondsValue = (value: number): DurationComponentsType => {
    let isNegative = false;
    if (value < 0) {
        isNegative = true;
    }

    const absSeconds = Math.abs(value);

    const days = Math.floor(absSeconds / 86400) || 0;
    const hours = Math.floor((absSeconds % 86400) / 3600) || 0;
    const minutes = Math.floor((absSeconds % 3600) / 60) || 0;
    const remainingSeconds = absSeconds % 60 || 0;

    return {
        isNegative,
        days,
        hours,
        minutes,
        seconds: remainingSeconds
    };
};

const getDayPrefix = (i18nTranslateFunc?: (key: string) => string) => {
    return i18nTranslateFunc ? i18nTranslateFunc('д.') : 'д.';
};

export const durationPrettyOutput = (
    value: number,
    i18nTranslateFunc?: (key: string) => string
): string => {
    const duration = parseSecondsValue(value);

    let durationString = '';

    const hmsValue = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    for (let i = 0; i < Object.keys(duration).length; i++) {
        const durationComponent = Object.keys(duration)[i];

        if (durationComponent === 'isNegative') {
            continue;
        }

        const durationValue = (duration as unknown as Record<string, number>)[durationComponent];

        let durationComponentPrefix = '';

        switch (durationComponent) {
            case 'days':
                durationComponentPrefix = getDayPrefix(i18nTranslateFunc);
                if (durationValue > 0) {
                    durationString += `${durationValue} ${durationComponentPrefix}`;
                }
                break;
            case 'hours':
                hmsValue.hours = durationValue;
                break;

            case 'minutes':
                hmsValue.minutes = durationValue;
                break;

            case 'seconds':
                hmsValue.seconds = durationValue;
                break;

            default:
                break;
        }
    }

    if (hmsValue.hours > 0 || hmsValue.minutes > 0 || hmsValue.seconds > 0) {
        if (durationString === '') {
            durationString = `${hmsValue.hours.toString().padStart(2, '0')}:${hmsValue.minutes
                .toString()
                .padStart(2, '0')}:${hmsValue.seconds.toString().padStart(2, '0')}`;
        } else {
            durationString = `${durationString} ${hmsValue.hours
                .toString()
                .padStart(2, '0')}:${hmsValue.minutes
                .toString()
                .padStart(2, '0')}:${hmsValue.seconds.toString().padStart(2, '0')}`;
        }
    }

    if (duration.isNegative) {
        return `-${durationString}`;
    } else {
        return durationString;
    }
};

export const parsePrettyDurationToSeconds = (
    durationStr: string,
    i18TranslateFunc?: (key: string) => string
): number => {
    let isNegative = false;
    if (durationStr && durationStr[0] === '-') {
        durationStr = durationStr.substring(1, durationStr.length);
        isNegative = true;
    }

    let days = 0;
    // const dayPrefix = getDayPrefix(i18TranslateFunc);
    const match = durationStr.match(/^(\d+)\s/);

    if (match) {
        const number = parseInt(match[1], 10);
        days = number;

        durationStr = durationStr.replace(/^\d+\s+\S+\s+/, '');
    }

    // format HH:MM:SS
    const durationComponents = durationStr.split(':');

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    durationComponents.forEach((durationComponent, i) => {
        if (i === 0) {
            hours = parseInt(durationComponent, 10);
        }
        if (i === 1) {
            minutes = parseInt(durationComponent, 10);
        }
        if (i === 2) {
            seconds = parseInt(durationComponent, 10);
        }
    });

    const secondsFull = hours * 3600 + minutes * 60 + seconds;

    return isNegative ? -1 * secondsFull : secondsFull;
};

const DurationEditModal = ({
    value,
    setValue,
    open,
    setOpen
}: {
    value: number; // 3 years 2 months 2 days 02:02:02	postgres format
    setValue: Function;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const isBigTablet = useMedia('(max-width: 1080px)');
    const isBigMobile = useMedia('(max-width: 480px)');

    const [form] = useForm();

    const parseDurationToPostgresValue = (dur: DurationComponentsType): number => {
        return (
            86400 * (dur.days || 0) +
            3600 * (dur.hours || 0) +
            60 * (dur.minutes || 0) +
            (dur.seconds || 0)
        );
    };

    useEffect(() => {
        if (value) {
            const duration = parseSecondsValue(value);
            form.setFieldsValue(duration);
        }
    }, [value]);

    return (
        <Modal
            centered
            title={t('edit_duration_modal_title')}
            open={open}
            width={isBigMobile ? '99vw' : isBigTablet ? '50vw' : '25vw'}
            footer={null}
            onCancel={() => setOpen(false)}
        >
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 5 }}
                form={form}
                onFinish={(duration: DurationComponentsType) => {
                    debugger;

                    const durationStringValue = parseDurationToPostgresValue(duration);
                    setValue(durationStringValue);
                    setOpen(false);
                }}
            >
                <Form.Item style={{ width: '100%' }} name={'days'} label={t('days')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <Form.Item style={{ width: '100%' }} name={'hours'} label={t('hour')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <Form.Item style={{ width: '100%' }} name={'minutes'} label={t('minute')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <Form.Item style={{ width: '100%' }} name={'seconds'} label={t('second')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <div style={{ justifyContent: 'end', display: 'flex', marginTop: 5 }}>
                    <Button
                        type="default"
                        style={{ marginRight: '0.2rem' }}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('apply')}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export const SmartDurationField = memo(
    ({ value, onChange, disabled, style, className, variant }: SmartDurationFieldProps) => {
        const { t } = useTranslation();

        const [modalOpen, setModalOpen] = useState<boolean>(false);

        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const outputValue = useMemo(() => {
            return value ? durationPrettyOutput(value, t) : '';
        }, [value]);

        const Wrapper = variant === 'borderless' ? Space : Space.Compact;

        return (
            <>
                <DurationEditModal
                    open={modalOpen}
                    setOpen={setModalOpen}
                    value={value || 0}
                    setValue={(seconds: number) => {
                        onChange(seconds);
                    }}
                />

                <Wrapper className="smart_duration_field">
                    <Input
                        variant={variant}
                        placeholder={t('no_value') as string}
                        value={outputValue || ''}
                        style={style}
                        className={className}
                        disabled={disabled}
                        readOnly
                    />
                    <Button
                        className="edit_button"
                        icon={<EditOutlined />}
                        type="default"
                        onClick={() => {
                            setModalOpen(true);
                        }}
                    />
                </Wrapper>
            </>
        );
    }
);
