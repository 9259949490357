import { Modal } from 'antd';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useTransition
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, useEffectOnce, useFirstMountState, useMedia } from 'react-use';
import { HamsterLoader } from 'smart/ui';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { SmartTable } from '../SmartTable/SmartTable';
import { useTableQueryAndActions } from 'smart/modules/SmartTablePage/utils/hooks';
import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { SmartSelectField } from '../SmartSelectField/SmartSelectField';
import { useHandlerRun } from 'smart/utils/hooks';

type RelatedObjectsModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    sourceObjects: IObjectWithId[];
    meta: string;
    refresh?: Function;
    commonDelete?: Function;
};

const RELATED_OBJECTS_META = 'RegRelatedObjects';
const RELATED_OBJECTS_ASSOCIATION = '';
const DELETE_WITH_RESOLVE_HANDLER = 'DeleteWithResolveRelatedObjects';

type DeleteBehaviorChanges = IObjectWithId & {
    Id: string;
    OnDeleteBehavior?: IObjectWithId;
};

export const RelatedObjectsModal = ({
    open,
    setOpen,
    sourceObjects,
    meta,
    refresh,
    commonDelete
}: RelatedObjectsModalProps) => {
    const { t } = useTranslation();

    const [relatedObjectsData, setRelatedObjectsData] = useState<IObjectWithId[]>([]);

    const [metadata, setMetadata] = useState<Meta>();
    const [loading, setLoading] = useState<boolean>(false);

    const { run } = useHandlerRun();

    useEffectOnce(() => {
        const loadMetaFields = async () => {
            const metaData = metaStore.meta.get(RELATED_OBJECTS_META);
            // const cachedFields = metaData?.info?.Fields;
            if (metaData) {
                setMetadata(metaData.info);
            } else {
                const metaObj = await metaStore.getInfo(RELATED_OBJECTS_META);
                setMetadata(metaObj);
            }
        };

        loadMetaFields();
    });

    useEffect(() => {
        const loadRelatedObjects = async () => {
            const relatedObjectsSelectResult = await metaStore.makeSelect({
                meta: RELATED_OBJECTS_META,
                filters: `ContextId=in.[${sourceObjects
                    .map((obj) => obj.Id)
                    .join(',')}]&ContextMeta=eq.${meta}`
            });
            if (relatedObjectsSelectResult.objects.length > 0) {
                setRelatedObjectsData(relatedObjectsSelectResult.objects);
                setLoading(false);
            } else {
                if (commonDelete) {
                    setLoading(true);
                    await commonDelete();

                    if (refresh) {
                        refresh();
                    }
                    setLoading(false);
                    setOpen(false);
                }
            }
        };

        if (sourceObjects && sourceObjects.length > 0) {
            setLoading(true);
            loadRelatedObjects();
        }
    }, [sourceObjects, meta, commonDelete]);

    const fields = useMemo(() => {
        if (metadata?.Fields) {
            return metadata?.Fields.map((field) => {
                field.IsReadOnly = true;

                if (field.FieldName === 'OnDeleteBehavior') {
                    field.IsHiddenOnTable = false;
                    // field.IsReadOnly = false;
                }

                return field;
            });
        }

        return [];
    }, [metadata?.Fields]);

    const renderDeleteBehavior = useCallback(
        (val: IObjectWithId, row: IObjectWithId) => {
            if (row.is_group) {
                return <></>;
            }

            if (!row.RefByField || row.RefByField === '') {
                return <></>;
            }

            if (val?.Code === 'REPLACE' && row?.SourceMeta) {
                return (
                    <div style={{ display: 'flex', width: '100%' }}>
                        <SmartSelectField
                            meta="InfoEnumValues"
                            value={val}
                            filters="Type=eq.ON_DELETE_BEHAVIOR"
                            variant={'borderless'}
                            onChange={(newVal) => {
                                setRelatedObjectsData((prevArray) => {
                                    const copy = [...prevArray];

                                    const rowIndex = copy.findIndex(
                                        (copyRow) => copyRow.Id === row.Id
                                    );

                                    if (rowIndex >= 0) {
                                        copy[rowIndex] = {
                                            ...copy[rowIndex],
                                            OnDeleteBehavior: newVal
                                        };
                                    }

                                    return copy;
                                });
                            }}
                        />

                        <SmartSelectField
                            meta={row?.SourceMeta?.Code}
                            value={row?.ReplaceValue}
                            variant={'borderless'}
                            filters={`Id=neq.${row.SourceObjectId}`}
                            onChange={(newVal) => {
                                setRelatedObjectsData((prevArray) => {
                                    const copy = [...prevArray];

                                    const rowIndex = copy.findIndex(
                                        (copyRow) => copyRow.Id === row.Id
                                    );

                                    if (rowIndex >= 0) {
                                        copy[rowIndex] = {
                                            ...copy[rowIndex],
                                            ReplaceValue: newVal
                                        };
                                    }

                                    return copy;
                                });
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <SmartSelectField
                        meta="InfoEnumValues"
                        value={val}
                        filters="Type=eq.ON_DELETE_BEHAVIOR"
                        variant={'borderless'}
                        onChange={(newVal) => {
                            setRelatedObjectsData((prevArray) => {
                                const copy = [...prevArray];

                                const rowIndex = copy.findIndex((copyRow) => copyRow.Id === row.Id);

                                if (rowIndex >= 0) {
                                    copy[rowIndex] = {
                                        ...copy[rowIndex],
                                        OnDeleteBehavior: newVal
                                    };
                                }

                                return copy;
                            });
                        }}
                    />
                );
            }
        },
        [relatedObjectsData]
    );

    const deleteWithResolve = useCallback(async () => {
        if (relatedObjectsData && relatedObjectsData.length > 0) {
            const deleteWithResolveResult = await run({
                meta: meta,
                handler: DELETE_WITH_RESOLVE_HANDLER,
                ids: [],
                Action_Id: '',
                args: {
                    ResolvedRelatedObjects: relatedObjectsData,
                    SourceObjects: sourceObjects
                }
            });

            setLoading(false);

            if (!deleteWithResolveResult?.error_text) {
                if (refresh) {
                    refresh();
                }

                setOpen(false);
            }

            return;
        }
    }, [relatedObjectsData, sourceObjects, meta]);
    return (
        <Modal
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            // okButtonProps={{ style: { display: 'none' } }}
            okText={t('apply')}
            destroyOnClose
            cancelText={t('cancel')}
            title={t('related_objects')}
            width={'60%'} //{isBigMobile ? '95%' : '75%'}
            zIndex={2000}
            onOk={() => {
                setLoading(true);
                deleteWithResolve();
            }}
            centered
        >
            {loading || !metadata ? (
                <HamsterLoader />
            ) : (
                <SmartTable
                    meta={RELATED_OBJECTS_META}
                    data={relatedObjectsData}
                    setData={setRelatedObjectsData}
                    fields={fields}
                    editable={false}
                    selectable={false}
                    fieldsRenderOverwrite={{
                        OnDeleteBehavior: renderDeleteBehavior
                    }}
                    leftFixedField={metadata.ViewFieldName}
                />
            )}
        </Modal>
    );
};
