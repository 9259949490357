import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { QRCodeScanner } from './QRCodeScanner';

interface QRCodeScannerModalProps {
    open?: boolean;
    onClose?: () => void;
}

export const QRCodeScannerModal: React.FC<QRCodeScannerModalProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const isBigMobile = useMedia('(max-width: 480px');

    return (
        <Modal
            open={open}
            onCancel={onClose}
            destroyOnClose
            centered
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ block: isBigMobile }}
            width={450}
            title={t('qr_code_scanner_modal.title')}
        >
            <QRCodeScanner scannerWidth={'100%'} onAfterScan={onClose} />
        </Modal>
    );
};
