import { PlainObject } from '@gilbarbara/types';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { EmptyMarker } from 'ui';

import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from 'modules/services/backend-api/generated_models';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { Loader } from 'ui/Loader/Loader';

import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { HandlerRunModal, HandlerRunModalLoaderOnly } from 'smart/components';
import { IHandlerWithId } from 'smart/modules/SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';
import { useBaseLanguage, useHandlerRun } from 'smart/utils/hooks';
import { Response } from 'modules/services/backend-api/generated_api';
import { LANGUAGES } from 'utils/i18n/i18n';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { fnv1aHash } from 'smart/utils';
import { useStoreNavigate } from 'utils/store';
// import { BookedSlotsDataType } from '../../types';
import './SmartTimeline.scss';
import { useAsync, useMedia, useUpdateEffect } from 'react-use';
import { observer } from 'mobx-react-lite';
import {
    getColumns,
    getColumnsRevert,
    getData,
    getDataRevert,
    getUniqItemsFromSlots,
    splitIntervalsByDay,
    transformSlots
} from './utils';
import { LazyIcon } from '../../ui';

interface SmartTimelineProps {
    daysCount: number;
    selectedDate: dayjs.Dayjs;
    // selectedLocations: any[];
    // bookedSlots: BookedSlotsDataType[];
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    selectedResources: IObjectWithId[];
    selectedLocations: IObjectWithId[];
    selectedTrips: IObjectWithId[];
    selectedVisits: IObjectWithId[];
    fetchBookedSlots: () => void;
    loading: boolean;
    firstColumnFieldName: string;
    displayMode: 'horizontal' | 'vertical';
    meta: string;
    extraContexts?: PlainObject<PlainObject<any>>;
    multiplicator?: number;
}

export const SmartTimeline = observer<SmartTimelineProps>(
    ({
        daysCount,
        selectedDate,
        freeSlots,
        bookedSlots,
        selectedResources,
        selectedLocations,
        selectedTrips,
        selectedVisits,
        fetchBookedSlots,
        loading,
        firstColumnFieldName,
        displayMode,
        meta,
        extraContexts,
        multiplicator = 1
    }) => {
        const {
            i18n: { language }
        } = useTranslation();
        const location = useLocation();
        const storeNavigate = useStoreNavigate();
        const isBigMobile = useMedia('(max-width: 480px)');
        const baseLanguage = useBaseLanguage();

        const { run } = useHandlerRun();

        const [handlerModal, setHandlerModal] = useState(false);
        const [handlerModalLoader, setHandlerModalLoader] = useState(false);
        const [handlerModalData, setHandlerModalData] = useState<Action | null>(null);
        const [handlerModalHardIds, setHandlerModalHardIds] = useState<string[] | undefined>();
        const [handlerModalRow, setHandlerModalRow] = useState<IObjectWithId | undefined>();

        const handleNavigateAfterAction = useCallback(
            (
                actionResponse: Response,
                action: Action,
                filters: FilterField[] | Filter[],
                metaFields?: MetaField[]
            ) => {
                // if (!uiAllowSelect) return;

                const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                let pageTitle = location.state?.pageTitle || pathnameLastElem;
                // const extraPageTitle = location.state?.extraPageTitle;
                if (action.Name) {
                    // pageTitle = action.Name[language];
                    pageTitle = action.Name;
                }

                let pathname = location.pathname;
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(
                    `${pathnameLastElem}_filtered_${JSON.stringify(
                        filters ?? location.state ?? {}
                    )}`
                );
                let filterString;

                if (action.NavItem && action.NavItem.Path) {
                    // pathname = action.NavItem.Path;
                    pathname = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[0]
                        : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    filterString = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[1]
                        : undefined;
                    cacheKey = fnv1aHash(
                        `${action.NavItem.Id}_${JSON.stringify(filters ?? action?.Id ?? {})}`
                    );
                } else if (action.Association?.TargetMeta_Code) {
                    pathname = `/other/${action.Association?.TargetMeta_Code}`;
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(
                        `${pathname}_filtered_${JSON.stringify(filters ?? action?.Id ?? {})}`
                    );
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            // extraPageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            responseData: { ...actionResponse.run?.[0]?.Result, ids: [] },
                            metaFields,
                            filterString
                        }
                    }
                );
            },
            [
                location.pathname,
                // location.state?.extraPageTitle,
                location.state?.pageTitle,
                storeNavigate
            ]
        );

        const handleNavigateBeforeAction = useCallback(
            ({
                action,
                filters,
                metaFields,
                hardIds,
                hardArgs
            }: {
                action: Action;
                filters: FilterField[] | Filter[];
                metaFields?: MetaField[];
                hardIds?: string[];
                hardArgs?: PlainObject;
            }) => {
                // if (!uiAllowSelect) return;

                const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                let pageTitle = location.state?.pageTitle || pathnameLastElem;
                let pathname = location.pathname;
                const cacheKeyBodyPermanent = hardIds ?? action?.Id ?? {};
                const cacheKeyBody = JSON.stringify(
                    filters?.length ? filters : cacheKeyBodyPermanent
                );
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${cacheKeyBody}`);

                let filterString;
                if (action.NavItem && action.NavItem.Path) {
                    if (action.Name) {
                        // pageTitle = action.Name[language];
                        pageTitle = action.Name;
                    }
                    // pathname = action.NavItem.Path;
                    pathname = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[0]
                        : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    filterString = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[1]
                        : undefined;
                    cacheKey = fnv1aHash(`${action.NavItem.Id}_${cacheKeyBody}`);
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            requestData: {
                                action,
                                ids: hardIds ?? [],
                                args: hardArgs ?? {}
                            },
                            metaFields,
                            filterString
                        }
                    }
                );
            },
            [location.pathname, location.state?.pageTitle, storeNavigate]
        );

        const actionClickHandler = useCallback(
            async (
                action: Action,
                hardIds?: string[],
                hardArgs?: PlainObject,
                row?: IObjectWithId,
                contexts?: PlainObject<PlainObject<any>>
            ): Promise<Response | undefined> => {
                if (action.IsNavigationFirst) {
                    handleNavigateBeforeAction({ action, hardArgs, hardIds, filters: [] });
                } else if (action.Handler_Code) {
                    setHandlerModalData({
                        ...action,
                        Handler: {
                            ...action.Handler,
                            Id: action.Id!,
                            ChildParams: action.Handler?.ChildParams.map((p) => {
                                if (hardArgs && Object.keys(hardArgs).includes(p.ParamName)) {
                                    return {
                                        ...p,
                                        DefaultValue: hardArgs[p.ParamName]
                                        // IsRequested: false
                                    };
                                }

                                return p;
                            })
                        } as IHandlerWithId,
                        ExtraContexts: contexts
                    });

                    const isShowModal =
                        action.Handler &&
                        ((action.Handler.ChildParams?.length &&
                            !action.Handler?.ChildParams?.every((param) => !param.IsRequested)) ||
                            action.Handler_Code === 'MassUpdate' ||
                            action.Handler_Code === 'MassChildUpsert');
                    if (isShowModal) {
                        setHandlerModal(true);
                        setHandlerModalHardIds(hardIds);
                        setHandlerModalRow(row);
                    } else {
                        setHandlerModalLoader(true);

                        const response = await run(
                            {
                                Action_Id: action.Id!,
                                meta: action.Meta_Code,
                                ids: hardIds ?? [],
                                handler: action.Handler_Code,
                                args: hardArgs || {}
                            },
                            action.IsLogResultHidden
                        );

                        setHandlerModalLoader(false);

                        return response;
                    }
                }

                return undefined;
            },
            // [refresh, run, selectedRowKeys, setLogMessages]
            [handleNavigateBeforeAction, run]
        );

        useAsync(
            async () =>
                Promise.all([
                    metaStore.getInfo('DocTrips'),
                    metaStore.getInfo('DocDowntimeEvents'),
                    metaStore.getInfo('DocTerminalVisits'),
                    metaStore.getInfo(meta)
                ]),
            [meta]
        );

        const metaActions = metaStore.meta.get(meta)?.info?.Actions;
        const metaTripActions = metaStore.meta.get('DocTrips')?.info?.Actions;
        const metaVisitActions = metaStore.meta.get('DocTerminalVisits')?.info?.Actions;
        const metaDowntimeEventActions = metaStore.meta.get('DocDowntimeEvents')?.info?.Actions;

        const tripActions = metaTripActions;
        const visitActions = metaVisitActions;
        const downtimeEventActions = metaDowntimeEventActions;
        const slotActions = metaActions;

        const getSlotActionsMenuItems = useCallback(
            (ids: string[], row: IObjectWithId, args?: PlainObject) => {
                const action = slotActions?.find(
                    (a) =>
                        a.Handler_Code === 'DocDowntimeEvents.Create' ||
                        a.Handler_Code === 'DocDowntimeEvents.CreateForLocationResource'
                );

                if (action) {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: () => actionClickHandler(action, ids, args, row)
                    };

                    return [item];
                }

                return [];
            },
            [slotActions, actionClickHandler, language]
        );

        const getDowntimeEventActionsMenuItems = useCallback(
            (ids: string[], row: IObjectWithId, args?: PlainObject) => {
                const folderless: MenuItem[] = [];

                for (const action of downtimeEventActions ?? []) {
                    if (!action.Handler_Code?.includes('ProcessCancellation') || !action.IsActive)
                        continue;

                    if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                        const item = {
                            key: action.Id,
                            icon: <LazyIcon icon={action.Icon} />,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled: action.IsDisabled,
                            onClick: () => actionClickHandler(action, ids, args, row)
                        };

                        folderless.push(item);
                    }

                    if (!action.IsFolder && action.Type_Code === 'EVENT') {
                        const item = {
                            key: action.Id,
                            icon: <LazyIcon icon={action.Icon} />,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled: action.IsDisabled,
                            onClick: () =>
                                actionClickHandler(
                                    {
                                        ...action,
                                        Handler_Code: 'RaiseEvent',
                                        Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                    },
                                    ids,
                                    {
                                        ...args,
                                        event_type: action.EventType_Code
                                    },
                                    row
                                )
                        };

                        folderless.push(item);
                    }
                }

                return folderless;
            },
            [downtimeEventActions, actionClickHandler, language]
        );

        const getTripActionsMenuItems = useCallback(
            (
                ids: string[],
                row: IObjectWithId,
                args?: PlainObject,
                contexts?: PlainObject<PlainObject<any>>
            ) => {
                const folderless: MenuItem[] = [];

                for (const action of tripActions ?? []) {
                    if (
                        (action.Handler_Code !== 'DocTrips.RiseExecutionEvent' &&
                            action.Handler_Code !== 'DocTrips.ChangeStartTime' &&
                            // action.Handler_Code !== 'DocTrips.ChangeOperationBooking' &&
                            action.Handler_Code !== 'DocTrips.ChangeOperationBooking_Planner' &&
                            action.Handler_Code !== 'DocTrips.ChangeServiceStartTime') ||
                        !action.IsActive
                    )
                        continue;

                    if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                        const item = {
                            key: action.Id,
                            icon: <LazyIcon icon={action.Icon} />,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled: action.IsDisabled,
                            onClick: () => actionClickHandler(action, ids, args, row, contexts)
                        };

                        folderless.push(item);
                    }

                    if (!action.IsFolder && action.Type_Code === 'EVENT') {
                        const item = {
                            key: action.Id,
                            icon: <LazyIcon icon={action.Icon} />,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled: action.IsDisabled,

                            onClick: () =>
                                actionClickHandler(
                                    {
                                        ...action,
                                        Handler_Code: 'RaiseEvent',
                                        Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                    },
                                    ids,
                                    {
                                        ...args,
                                        event_type: action.EventType_Code
                                    },
                                    row,
                                    contexts
                                )
                        };

                        folderless.push(item);
                    }
                }

                return folderless.sort((a, b) => Number(a?.index) - Number(b?.index));
            },
            [tripActions, actionClickHandler, language]
        );

        const getVisitActionsMenuItems = useCallback(
            (ids: string[], row: IObjectWithId, args?: PlainObject) => {
                const folderless: MenuItem[] = [];

                // for (const action of visitActions ?? []) {
                //     if (
                //         (action.Handler_Code !== 'DocTrips.RiseExecutionEvent' &&
                //             action.Handler_Code !== 'DocTrips.ChangeStartTime' &&
                //             action.Handler_Code !== 'DocTrips.ChangeOperationBooking' &&
                //             action.Handler_Code !== 'DocTrips.ChangeServiceStartTime') ||
                //         !action.IsActive
                //     )
                //         continue;

                //     if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                //         const item = {
                //             key: action.Id,
                //             icon: <LazyIcon icon={action.Icon} />,
                //             label: action.Name[language as LANGUAGES] || action.Id,
                //             disabled: action.IsDisabled,
                //             onClick: () => actionClickHandler(action, ids, args, row)
                //         };

                //         folderless.push(item);
                //     }

                //     if (!action.IsFolder && action.Type_Code === 'EVENT') {
                //         const item = {
                //             key: action.Id,
                //             icon: <LazyIcon icon={action.Icon} />,
                //             label: action.Name[language as LANGUAGES] || action.Id,
                //             disabled: action.IsDisabled,

                //             onClick: () =>
                //                 actionClickHandler(
                //                     {
                //                         ...action,
                //                         Handler_Code: 'RaiseEvent',
                //                         Handler: { ...action.Handler, Code: 'RaiseEvent' }
                //                     },
                //                     ids,
                //                     {
                //                         ...args,
                //                         event_type: action.EventType_Code
                //                     },
                //                     row
                //                 )
                //         };

                //         folderless.push(item);
                //     }
                // }

                return folderless.sort((a, b) => Number(a?.index) - Number(b?.index));
            },
            [visitActions, actionClickHandler, language]
        );

        const { value: showTripInfoOnSlots } = useAsync(
            async () =>
                metaStore.meta.get('all')?.params?.SHOW_TRIP_INFO_ON_SLOTS?.param_value ??
                metaStore.meta.get('all')?.params?.SHOW_TRIP_INFO_ON_SLOTS ??
                metaStore.getParam({ param_name: 'SHOW_TRIP_INFO_ON_SLOTS', default_value: false }),
            []
        );

        // console.log(bookedSlots);
        // console.log(splitIntervalsByDay(bookedSlots));

        const transformedBookedSlots = useMemo(
            () => transformSlots(splitIntervalsByDay(bookedSlots), undefined, multiplicator),
            [bookedSlots, multiplicator]
        );

        // console.log(transformedBookedSlots);

        // test

        const horizontalColumns = useMemo(
            () =>
                getColumns({
                    daysCount,
                    selectedDate,
                    freeSlots,
                    bookedSlots: transformedBookedSlots,
                    fetchBookedSlots,
                    getSlotActionsMenuItems,
                    getTripActionsMenuItems,
                    getVisitActionsMenuItems,
                    getDowntimeEventActionsMenuItems,
                    language,
                    baseLanguage,
                    selectedTripsIds: selectedTrips?.map((st) => st.Id) ?? [],
                    selectedVisitsIds: selectedVisits?.map((st) => st.Id) ?? [],
                    firstColumnFieldName,
                    meta,
                    showTripInfoOnSlots,
                    multiplicator
                }),
            [
                daysCount,
                fetchBookedSlots,
                firstColumnFieldName,
                freeSlots,
                getSlotActionsMenuItems,
                getTripActionsMenuItems,
                getVisitActionsMenuItems,
                getDowntimeEventActionsMenuItems,
                language,
                meta,
                selectedDate,
                selectedTrips,
                selectedVisits,
                transformedBookedSlots,
                showTripInfoOnSlots,
                baseLanguage,
                multiplicator
            ]
        );

        const verticalColumns = useMemo(
            () =>
                getColumnsRevert({
                    items: getUniqItemsFromSlots({
                        slots: freeSlots,
                        bookedSlots: transformedBookedSlots,
                        selectedTripIds: selectedTrips?.map((st) => st.Id) ?? [],
                        selectedVisitIds: selectedVisits?.map((st) => st.Id) ?? [],
                        firstColumnFieldName
                    }),
                    language,
                    baseLanguage,
                    selectedTripsIds: selectedTrips?.map((st) => st.Id) ?? [],
                    selectedVisitsIds: selectedVisits?.map((st) => st.Id) ?? [],
                    getSlotActionsMenuItems,
                    getTripActionsMenuItems,
                    getVisitActionsMenuItems,
                    getDowntimeEventActionsMenuItems,
                    fetchBookedSlots,
                    bookedSlots: transformedBookedSlots,
                    freeSlots,
                    firstColumnFieldName,
                    meta,
                    isMobile: isBigMobile,
                    showTripInfoOnSlots,
                    multiplicator
                }),
            [
                fetchBookedSlots,
                firstColumnFieldName,
                freeSlots,
                getSlotActionsMenuItems,
                getTripActionsMenuItems,
                getVisitActionsMenuItems,
                getDowntimeEventActionsMenuItems,
                isBigMobile,
                language,
                meta,
                selectedTrips,
                selectedVisits,
                transformedBookedSlots,
                showTripInfoOnSlots,
                baseLanguage,
                multiplicator
            ]
        );

        const horizontalData = useMemo(
            () =>
                getData({
                    freeSlots,
                    bookedSlots: transformedBookedSlots,
                    selectedResources,
                    language,
                    daysCount,
                    selectedTrips,
                    selectedVisits,
                    selectedLocations,
                    firstColumnFieldName,
                    multiplicator
                    // selectedDate
                }),
            [
                daysCount,
                firstColumnFieldName,
                freeSlots,
                language,
                selectedLocations,
                selectedResources,
                selectedTrips,
                selectedVisits,
                transformedBookedSlots,
                multiplicator
            ]
        );

        const verticalData = useMemo(
            () =>
                getDataRevert({
                    freeSlots,
                    daysCount,
                    selectedDate,
                    bookedSlots: transformedBookedSlots,
                    firstColumnFieldName,
                    selectedTrips,
                    selectedVisits,
                    multiplicator
                }),
            [
                daysCount,
                firstColumnFieldName,
                freeSlots,
                selectedDate,
                selectedTrips,
                selectedVisits,
                transformedBookedSlots,
                multiplicator
            ]
        );

        return (
            <>
                <Table
                    // rowKey={'Id'}
                    rowKey={'RowId'}
                    columns={displayMode === 'horizontal' ? horizontalColumns : verticalColumns}
                    // dataSource={getData(selectedResources, language)}
                    dataSource={
                        loading ? [] : displayMode === 'horizontal' ? horizontalData : verticalData
                    }
                    bordered
                    size="small"
                    pagination={false}
                    className="smart_timeline_table"
                    virtual={displayMode !== 'horizontal' && daysCount / multiplicator > 1}
                    // scroll={{ y: 'calc(100vh - 27rem)' }}
                    scroll={{ y: 'calc(100vh - 21rem)' }}
                    locale={{ emptyText: <EmptyMarker /> }}
                    loading={
                        loading ? { spinning: loading, indicator: <Loader />, delay: 100 } : loading
                    }
                />
                {handlerModal && (
                    <HandlerRunModal
                        open={handlerModal}
                        onCancel={() => {
                            setHandlerModal(false);
                            setHandlerModalData(null);
                            setHandlerModalHardIds(undefined);
                            setHandlerModalRow(undefined);
                        }}
                        action={handlerModalData}
                        ids={handlerModalHardIds ?? []}
                        onRefresh={() => {}}
                        metaName={handlerModalData?.Meta_Code ?? 'DocTrips'}
                        navigateAfterRun={handleNavigateAfterAction}
                        // filters={filters}
                        meta={handlerModalData?.Meta_Code ?? 'DocTrips'}
                        row={handlerModalRow}
                        extraContexts={extraContexts}
                    />
                )}
                {handlerModalLoader && (
                    <HandlerRunModalLoaderOnly
                        open={handlerModalLoader}
                        onCancel={() => {
                            setHandlerModalLoader(false);
                            setHandlerModalData(null);
                        }}
                        action={handlerModalData}
                    />
                )}
            </>
        );
    }
);
