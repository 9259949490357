import { CloseCircleFilled } from '@ant-design/icons';
import { TimePicker } from 'antd';
import { Variant } from 'antd/es/config-provider';
import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { REGEX_REPLACE_GMT, UI_TIME_FORMAT, UI_TIME_FORMAT_FULL } from 'utils/helpers/dates';

interface SmartTimeFieldProps {
    value: string | null; // ISO 8601 time string format, e.g. '12:34:56'
    onChange: (newValue: string | null) => void;
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    utc?: boolean;
    showSeconds?: boolean;
    style?: React.CSSProperties;
    className?: string;
    variant?: Variant;
}

// SmartTimeField TimePicker с секундами HH:MM:SS TODO: возможно стоит переменовать в SmartTimeFieldFull
export const SmartTimeField = memo(
    ({
        value,
        popoverContainerHtmlId,
        onChange,
        disabled,
        utc,
        showSeconds,
        style,
        className,
        variant
    }: SmartTimeFieldProps) => {
        const { t } = useTranslation();
        const format = showSeconds ? UI_TIME_FORMAT_FULL : UI_TIME_FORMAT;

        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (time: dayjs.Dayjs | null) => {
                const formattedTime = dayjs(time).format(format);
                console.log('formattedTime: ', formattedTime);
                onChange(formattedTime);
            },
            [onChange, utc]
        );

        return (
            <TimePicker
                placeholder={t('no_value') as string}
                value={value ? dayjs(value, format) : null}
                style={style}
                onChange={handleChange}
                needConfirm={false}
                format={{ format, type: 'mask' }}
                disabled={disabled}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
                variant={variant}
            />
        );
    }
);
