import { isEmpty } from 'is-lite/exports';
import dayjs from 'dayjs';
import { DayDataType } from './types';
import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { IObjectWithId } from '../../../../utils/store/MetaStore';
import * as HoursEnum from '../constants';
import { HOURS } from '../constants';

type GetDataFunction = (options: {
    daysCount: number;
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    selectedResources: IObjectWithId[];
    selectedLocations: IObjectWithId[];
    selectedTrips: IObjectWithId[];
    selectedVisits: IObjectWithId[];
    language: string;
    firstColumnFieldName: string;
    multiplicator?: number;
    // selectedDate: dayjs.Dayjs;
    // fetchBookedSlots: () => void;
}) => DayDataType[];

export const getData: GetDataFunction = ({
    freeSlots,
    language,
    bookedSlots,
    selectedTrips,
    selectedVisits,
    firstColumnFieldName,
    multiplicator = 1
}) => {
    // console.log(bookedSlots, firstColumnFieldName);
    const data: DayDataType[] = [];
    const selectedTripIds = selectedTrips.map((t) => t.Id);
    const selectedVisitIds = selectedVisits.map((t) => t.Id);

    for (const slot of freeSlots) {
        const parentItem = slot[firstColumnFieldName];

        if (!parentItem) continue;

        if (data.find((d) => d.Id === parentItem?.Id)) continue; // не будем дублировать ресурсы

        if (
            !isEmpty(selectedTrips) &&
            !bookedSlots.find(
                (bs) =>
                    bs[firstColumnFieldName]?.Id === parentItem?.Id &&
                    selectedTripIds.includes(bs?.Trip?.Id ?? '')
            )
        )
            continue;

        if (
            !isEmpty(selectedVisits) &&
            !bookedSlots.find(
                (bs) =>
                    bs[firstColumnFieldName]?.Id === parentItem?.Id &&
                    selectedVisitIds.includes(bs?.Visit?.Id ?? '')
            )
        )
            continue;

        // console.log(slot);

        // console.log(bookedSlots);

        const trips = Array.from(
            new Map(
                (
                    bookedSlots
                        .filter((bs) => bs[firstColumnFieldName]?.Id === parentItem?.Id && bs.Trip)
                        // .sort((a, b) =>
                        //     dayjs(a.StartDateAt ?? a.StartAt).diff(
                        //         dayjs(b.StartDateAt ?? b.StartAt)
                        //     )
                        // )
                        .map(
                            (bs) =>
                                ({
                                    ...bs.Trip,
                                    RowId: `${bs[firstColumnFieldName]?.Id}:${bs.Trip?.Id}`,
                                    Date: bs.Date,
                                    SlotIndex: bs.SlotIndex
                                }) as IObjectWithId
                        ) ?? []
                ).map((obj) => [`${obj.Id}`, obj])
            ).values()
        );

        const visits = Array.from(
            new Map(
                (
                    bookedSlots
                        .filter((bs) => bs[firstColumnFieldName]?.Id === parentItem?.Id && bs.Visit)
                        // .sort((a, b) =>
                        //     dayjs(a.StartDateAt ?? a.StartAt).diff(
                        //         dayjs(b.StartDateAt ?? b.StartAt)
                        //     )
                        // )
                        .map(
                            (bs) =>
                                ({
                                    ...bs.Visit,
                                    RowId: `${bs[firstColumnFieldName]?.Id}:${bs.Visit?.Id}`,
                                    Date: bs.Date,
                                    SlotIndex: bs.SlotIndex
                                }) as IObjectWithId
                        ) ?? []
                ).map((obj) => [`${obj.Id}`, obj])
            ).values()
        );

        // console.log(visits);

        const downtimeEvents = Array.from(
            new Map(
                (
                    bookedSlots
                        .filter(
                            (bs) =>
                                bs[firstColumnFieldName]?.Id === parentItem?.Id && bs.DowntimeEvent
                        )
                        // .sort((a, b) =>
                        //     dayjs(a.StartDateAt ?? a.StartAt).diff(
                        //         dayjs(b.StartDateAt ?? b.StartAt)
                        //     )
                        // )
                        .map(
                            (bs) =>
                                ({
                                    ...bs.DowntimeEvent,
                                    RowId: `${bs[firstColumnFieldName]?.Id}:${bs.DowntimeEvent?.Id}`,
                                    Date: bs.Date,
                                    SlotIndex: bs.SlotIndex
                                }) as IObjectWithId
                        ) ?? []
                ).map((obj) => [`${obj.Id}`, obj])
            ).values()
        );

        const CapacityCount = parentItem?.CapacityCount ?? 1;
        const Id = parentItem?.Id ?? '';
        const Name =
            // slot[firstColumnFieldName]?.Name?.[language] ??
            parentItem?.Name ?? parentItem?.Key ?? parentItem?.Id;

        const finalChildren = [
            ...trips.map((t, index) => {
                return {
                    Key: t.Key,
                    // Name: t?.Name?.[language] ?? t?.Key ?? t?.Id,
                    Name: t?.Name ?? t?.Key ?? t?.Id,
                    Id: t?.Id ?? '',
                    RowId: t?.RowId ?? '',
                    ParentItem: parentItem,
                    Trip: t,
                    TripId: t?.Id ?? '',
                    TripIndex: index,
                    ItemId: Id,
                    Meta: 'DocTrips',
                    CapacityCount,
                    Date: t.Date,
                    SlotIndex: t.SlotIndex,
                    ...HoursEnum[`HOURS_X${multiplicator}`]
                    // ...HOURS
                };
            }),
            ...visits.map((v, index) => {
                return {
                    Key: v.Key,
                    // Name: t?.Name?.[language] ?? t?.Key ?? t?.Id,
                    Name: v?.Name ?? v?.Key ?? v?.Id,
                    Id: v?.Id ?? '',
                    RowId: v?.RowId ?? '',
                    ParentItem: parentItem,
                    Visit: v,
                    VisitId: v?.Id ?? '',
                    VisitIndex: index,
                    ItemId: Id,
                    Meta: 'DocTerminalVisits',
                    CapacityCount,
                    Date: v.Date,
                    SlotIndex: v.SlotIndex,
                    ...HoursEnum[`HOURS_X${multiplicator}`]
                    // ...HOURS
                };
            }),
            ...downtimeEvents.map((de, index) => {
                return {
                    Key: de.Key,
                    // Name: de?.Name?.[language] ?? de?.Key ?? de?.Id,
                    Name: de?.Name ?? de?.Key ?? de?.Id,
                    Id: de?.Id ?? '',
                    RowId: de?.RowId ?? '',
                    ParentItem: parentItem,
                    DowntimeEvent: de,
                    DowntimeEventId: de?.Id ?? '',
                    DowntimeEventIndex: index,
                    ItemId: Id,
                    Meta: 'DocDowntimeEvents',
                    CapacityCount,
                    Date: de.Date,
                    SlotIndex: de.SlotIndex,
                    ...HoursEnum[`HOURS_X${multiplicator}`]
                    // ...HOURS
                };
            })
        ];
        // .sort((a, b) => {
        //     if (a.Date && b.Date) {
        //         const dateComparison = dayjs(a.Date).diff(dayjs(b.Date));
        //         if (dateComparison !== 0) {
        //             return dateComparison; // Если даты разные, возвращаем разницу
        //         }
        //     }
        //     // Если даты одинаковые, сравниваем по SlotIndex
        //     return (a.SlotIndex ?? 0) - (b.SlotIndex ?? 0);
        // });

        // console.log(trips, downtimeEvents, finalChildren);

        const dataItem: DayDataType = {
            Key: parentItem?.Key,
            Name,
            Id,
            RowId: Id,
            ParentItem: parentItem,
            Item: parentItem,
            ItemId: Id,
            TripId: undefined,
            CapacityCount,
            TripsIds: trips.map((t) => t?.Id),
            VisitsIds: visits.map((t) => t?.Id),
            DowntimeEventsIds: downtimeEvents.map((t) => t?.Id),
            Trips: trips,
            Visits: visits,
            DowntimeEvents: downtimeEvents,
            Meta: 'CatLocationResources',
            // ...HOURS,
            ...HoursEnum[`HOURS_X${multiplicator}`],

            children:
                trips.length || downtimeEvents.length || visits.length ? finalChildren : undefined
        };

        data.push(dataItem);
    }

    // console.log(freeSlots, data, bookedSlots);
    // console.log(data);

    return data; // TODO: sort
    // return data.sort((a, b) => (a.key > b.key ? 1 : -1));
};
