import { ColorPicker, Flex } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
// import { SmartTooltip } from 'smart/ui';
import { SmartSelectField } from '../SmartSelectField/SmartSelectField';
import { SmartMultiSelectField } from '..';

interface ColorFieldProps {
    value: string | string[] | null; // Color string format, e.g. '#ff0000'
    onChange: (newValue: string | string[]) => void;
    list?: string[];
    multi?: boolean;
}

export const ColorField = memo<ColorFieldProps>(({ value, onChange, list, multi }) => {
    const { t } = useTranslation();

    // const renderView = useCallback(
    //     (viewValue: string) => (
    //         <div
    //             style={{
    //                 backgroundColor: viewValue,
    //                 width: '50px',
    //                 height: '20px'
    //             }}
    //         />
    //     ),
    //     []
    // );
    if (list) {
        return (
            <Flex>
                {/* <div style={{ maxWidth: '60%', margin: 'auto 0' }}>
                    {multi ? (
                        <SmartTooltip simpleMode={false} style={{ marginRight: 5 }}>
                            <div style={{ display: 'inline-flex' }}>
                                {((value as string[]) ?? []).map((val, index, list) => (
                                    <div style={{ display: 'inline-flex' }}>
                                        <div
                                            style={{
                                                backgroundColor: val,
                                                padding: '2px 5px',
                                                width: 'max-content',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <span style={{ backgroundClip: 'text' }}>{val}</span>
                                        </div>
                                        {index !== list.length - 1 ? ', ' : null}
                                    </div>
                                ))}
                            </div>
                        </SmartTooltip>
                    ) : value ? (
                        <div
                            style={{
                                backgroundColor: value ?? undefined,
                                padding: '2px 5px',
                                width: 'max-content',
                                textAlign: 'center',
                                marginRight: 5
                            }}
                        >
                            <span style={{ backgroundClip: 'text' }}>{value}</span>
                        </div>
                    ) : null}
                </div> */}
                <div style={{ width: '100%' }}>
                    {multi ? (
                        <SmartMultiSelectField
                            value={((value as string[]) ?? []).map((item) => ({ Id: item }))}
                            metaName={''}
                            onChange={(newValue) => onChange(newValue?.map((item) => item.Id))}
                            dataSource={list.map((item) => ({ Id: item }))}
                            disableDropdown
                            style={{ width: '100%' }}
                            // simpleMode
                        />
                    ) : (
                        <SmartSelectField
                            value={{ Id: value }}
                            meta={''}
                            onChange={(newValue) => onChange(newValue?.Id)}
                            dataSource={list.map((item) => ({ Id: item }))}
                            // disableDropdown
                            style={{ width: '100%' }}
                            placeholder={t('no_value') as string}
                            simpleMode
                        />
                    )}
                </div>
            </Flex>
        );
    }

    if (multi) {
        return null; // TODO: code picker array then no list param
    }

    return (
        <ColorPicker
            size="large"
            value={value || ''}
            onChange={(value) => {
                onChange(value.toHexString());
            }}
        />
    );
});
