import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from 'components';
import { ButtonWithTooltips } from 'ui';
import { routeStore, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { createGlobalState, useEffectOnce, useMedia } from 'react-use';
import { NavBar } from 'antd-mobile';
import { Button } from 'antd';
import { useBaseLanguage } from 'smart/utils/hooks';
import { getPageTitle } from 'smart/utils';
import { ID_REGX, USER_ID_REGX } from '../constants';
import { getTableNameByPath } from '../../../../../utils';

interface CommonPageProps extends React.PropsWithChildren {
    dropScope: () => void;
    pathnameLastElem: string;
    pathnameLastElemForDetail: string;
    isDetailPage?: boolean;
}

interface CommonPageContextProps {
    getClosePageHandler: (
        location: Location,
        navigate: NavigateFunction,
        dropScope: () => void,
        additionalHandler?: () => void,
        isChanged?: boolean,
        modal?: { open: () => Promise<boolean> } | null
    ) => () => void;
}

const getClosePageHandler =
    (
        location: Location,
        navigate: NavigateFunction,
        dropScope: () => void,
        additionalHandler?: () => void,
        isChanged?: boolean,
        modal?: { open: () => Promise<boolean> } | null
    ) =>
    async () => {
        if (additionalHandler) additionalHandler();

        const { pathname, state, search } = location;

        if (isChanged && modal) {
            await modal.open();
            return;
        }

        const currentRouteIndex = routeStore.findIndex({ pathname, search, state });

        // if (isDefined(routeStore.routes[currentRouteIndex + 1])) {
        if (false) {
            // const { pathname, state, search } = routeStore.routes[currentRouteIndex + 1];
            //
            // navigate({ pathname, search: search || '' }, { state: toJS(state) });
        } else {
            const { pathname, state, search } = routeStore.routes[currentRouteIndex - 1];

            navigate({ pathname, search: search || '' }, { state: toJS(state) });
        }

        dropScope();
        routeStore.remove({ pathname, state, search });
    };

export const CommonPageContext = React.createContext<CommonPageContextProps>({
    getClosePageHandler
});

const initialDetailFormChanged = {
    changedMap: new Map<string, boolean>(),
    model: null
};

export const useDetailFormChanged = createGlobalState(initialDetailFormChanged);

export const CommonPage = memo<CommonPageProps>(({ dropScope, pathnameLastElem, children }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const navigate = useStoreNavigate();
    const oldNavigate = useNavigate();
    const id = useParams();
    const { pathname, search, state } = useLocation();
    const location = useLocation();
    const isBigMobile = useMedia('(max-width: 480px)');
    const baseLanguage = useBaseLanguage();
    const [{ changedMap, modal }, setIsChanged] = useDetailFormChanged();

    const isChanged = changedMap.get(`${pathname}_${state?.cacheKey}`) || false;

    useEffectOnce(() => {
        setIsChanged((prev) => ({ ...prev, changedMap: initialDetailFormChanged.changedMap }));
    });

    const isHomePage = pathname === '/';
    const isDetailPage =
        ID_REGX.test(pathnameLastElem) ||
        USER_ID_REGX.test(pathnameLastElem) ||
        pathnameLastElem === 'new' ||
        pathnameLastElem === 'copy';

    // console.log(isChanged);

    const [selectedLanguage, setSelectedLanguage] = useState('ru');

    useEffect(() => {
        (async () => {
            if (state?.pageTitle) return;

            if (
                !isDetailPage &&
                !isHomePage &&
                !pathname.includes('login') &&
                !pathname.includes('signup')
            ) {
                const metaRoute = metaStore.meta.get('all')?.routes?.find((route) => {
                    return (
                        route.path ===
                        `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                    );
                    // return route.path === pathname;
                });

                const metaCode = metaRoute?.meta;

                const metaCodeFromPathname = pathname.includes('other')
                    ? pathname.split('/').at(-1)
                    : null;

                const metaMenuItem = metaStore.meta.get('all')?.menu?.items.find((item) => {
                    // console.log(
                    //     item.path,
                    //     `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                    // );
                    return (
                        item.path ===
                        `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                    );
                });

                let pageTitle = '';
                let pluralName;

                if (metaCode) {
                    pluralName =
                        metaRoute?.layout?.Info?.Info?.PluralName ||
                        metaStore.meta.get(metaCode)?.info?.PluralName;
                } else if (metaCodeFromPathname) {
                    const metaInfo =
                        metaStore.meta.get(metaCodeFromPathname)?.info ??
                        (await metaStore.getInfo(metaCodeFromPathname));

                    pluralName = toJS(metaInfo?.PluralName);
                }

                // const metaMenuName = metaMenuItem?.name?.[language];
                const metaMenuName = metaMenuItem?.name;

                // console.log(metaMenuName, pluralName?.[language], t(pathnameLastElem));

                // pageTitle = pluralName?.[language] || metaMenuName || t(pathnameLastElem);
                pageTitle = toJS(pluralName) || toJS(metaMenuName) || t(pathnameLastElem);
                // console.log(pageTitle);

                setSelectedLanguage(language);

                const oldRoute = {
                    pathname,
                    search,
                    state
                };

                const newRoute = {
                    pathname,
                    search,
                    state: state
                        ? {
                              ...state,
                              pageTitle: state?.pageTitle?.includes('*')
                                  ? `${getPageTitle(
                                        { title: pageTitle },
                                        language,
                                        baseLanguage,
                                        t
                                    )}*`
                                  : pageTitle
                          }
                        : { pageTitle }
                };

                if (routeStore.findIndex(newRoute) === -1) {
                    routeStore.replace(oldRoute, newRoute);

                    oldNavigate(
                        { pathname, search },
                        {
                            state: state
                                ? {
                                      ...state,
                                      pageTitle:
                                          state.pageTitle?.includes('(') &&
                                          state.pageTitle?.includes(')')
                                              ? `${getPageTitle(
                                                    { title: pageTitle },
                                                    language,
                                                    baseLanguage,
                                                    t
                                                )}*`
                                              : pageTitle
                                  }
                                : { pageTitle }
                        }
                    );
                }
            }
        })();
    }, [
        baseLanguage,
        id,
        isDetailPage,
        isHomePage,
        language,
        oldNavigate,
        pathname,
        pathnameLastElem,
        search,
        selectedLanguage,
        state,
        t
    ]);

    const statePageTitle = state ? toJS(state.pageTitle) : undefined;
    const stateExtraPageTitle = state ? toJS(state.extraPageTitle) : undefined;

    const pageTitle = statePageTitle || t(pathnameLastElem);
    const extraPageTitle = stateExtraPageTitle;

    return (
        <CommonPageContext.Provider
            value={{
                getClosePageHandler
            }}
        >
            {isBigMobile && !isHomePage ? (
                <NavBar
                    right={
                        <Button
                            size={'large'}
                            type="text"
                            onClick={getClosePageHandler(location, navigate, dropScope)}
                            icon={<CloseOutlined />}
                        />
                    }
                    back={
                        <Button
                            size="large"
                            type="text"
                            onClick={() => navigate(-1)}
                            icon={<LeftOutlined />}
                        />
                    }
                    backArrow={false}
                    onBack={() => navigate(-1)}
                >
                    <strong>
                        {getPageTitle(
                            { title: pageTitle, extra: extraPageTitle },
                            language,
                            baseLanguage,
                            t
                        )}
                    </strong>
                </NavBar>
            ) : (
                <PageHeader
                    title={
                        getPageTitle(
                            { title: pageTitle, extra: extraPageTitle },
                            language,
                            baseLanguage,
                            t
                        ) + (isChanged ? '*' : '')
                    }
                    disabled={isHomePage}
                    state={state}
                    onVertical={900}
                    right={
                        <ButtonWithTooltips
                            id="close"
                            type="text"
                            onClick={getClosePageHandler(
                                location,
                                navigate,
                                dropScope,
                                undefined,
                                isChanged,
                                modal
                            )}
                            tooltipTitle={t('close')}
                            icon={<CloseOutlined />}
                        />
                    }
                />
            )}
            {children}
        </CommonPageContext.Provider>
    );
});
