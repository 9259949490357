import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CopyOutlined,
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Collapse, Flex, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { v4 } from 'uuid';
import { isFunction } from 'is-lite/exports';

import { Meta } from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips, EmptyMarker } from 'ui';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { emitter } from 'utils/emitter';

import { SmartTable } from '../../../../components';

interface SmartDetailPageChildTabContentProps {
    data: any;
    rootData?: any;
    setData: (newData: any) => void;
    onChange: (key: string, newData: any) => void;
    childNodeMeta: Meta;
    mode: 'view' | 'edit';
    padding?: number | string;
    readOnly?: boolean;
    enableCollapse?: boolean;
    forceEnableToolbar?: boolean;
    forceEnableSelection?: boolean;
    tableTitle?: string;
    onChildDoubleClick?: 'openRowModal' | ((row: any, index?: number) => void);
    rootMeta?: string;
    scroll?: { y: number | string; x?: string | number };
}

const rowsKeyColumnName = 'Id';
const SIZE = 'small';

const parseDefaultSortOrder = (sortOrder: string | undefined) => {
    const defaultSortOrder: {
        descend: string[];
        ascend: string[];
    } = { ascend: [], descend: [] };

    if (!sortOrder) {
        defaultSortOrder.ascend.push('ChildIndex');
        return defaultSortOrder;
    }

    const [column, order] = sortOrder.split(' ');
    const isASC = order === 'ASC';

    if (isASC) {
        defaultSortOrder.ascend.push(column);
    } else {
        defaultSortOrder.descend.push(column);
    }

    return defaultSortOrder;
};

export const SmartDetailPageChildTabContent = observer<SmartDetailPageChildTabContentProps>(
    ({
        data,
        rootData = data,
        childNodeMeta,
        mode,
        setData,
        onChange,
        padding,
        readOnly,
        tableTitle,
        enableCollapse,
        forceEnableToolbar,
        forceEnableSelection,
        onChildDoubleClick = 'openRowModal',
        rootMeta,
        scroll
    }) => {
        const { t } = useTranslation();
        const [selectedData, setSelectedData] = useState<any[]>([]);
        const isBigMobile = useMedia('(max-width: 480px)');

        const { message } = useNotifications();

        const isEditable = !readOnly && mode === 'edit';
        const viewFieldName = childNodeMeta.ViewFieldName;

        const isRowsSelected = selectedData.length > 0;

        useEffect(() => {
            (async () => {
                const inlineEditMaxItems = await metaStore.getParam({
                    param_name: 'INLINE_EDIT_MAX_ITEMS'
                });

                console.log('[SmartDetailPageChildTabContent] INLINE_EDIT_MAX_ITEMS = ', inlineEditMaxItems);
            })();
        }, []);

        const inlineEditMaxItems =
            metaStore.meta.get('all')?.params?.INLINE_EDIT_MAX_ITEMS || false;

        useEffect(() => {
            // сброс выделения при отмене
            setSelectedData([]);
        }, [mode]);

        const getSelectedRowKeys = useCallback(() => {
            return selectedData.map((row) => row[rowsKeyColumnName]) as string[];
        }, [selectedData]);

        const handleChangeChild = useCallback(
            (updater: any[] | ((prevValue: any) => any[])) => {
                const key = childNodeMeta.ArrayNameInRoot;

                if (key) onChange(key, isFunction(updater) ? updater(data[key]) : updater);
            },
            [childNodeMeta.ArrayNameInRoot, data, onChange]
        );

        const dataSource = useMemo(() => {
            return childNodeMeta.ArrayNameInRoot
                ? data
                    ? data[childNodeMeta.ArrayNameInRoot] || []
                    : []
                : [];
        }, [childNodeMeta.ArrayNameInRoot, data]);

        const handleAddRow = useCallback(() => {
            const key = childNodeMeta.ArrayNameInRoot;

            if (key) {
                const childArrayNewData = data[key] ? [...data[key]] : [];
                const firstSelectedRow = selectedData.at(0);
                const Parent = firstSelectedRow?.Parent;

                const newObject = {
                    Id: v4(),
                    Parent,
                    ChildIndex: 0
                };

                childArrayNewData.push(newObject);

                onChange(key, childArrayNewData);

                emitter.emit(`open_${childNodeMeta.Code}_row_modal`, newObject);
            }
        }, [childNodeMeta.ArrayNameInRoot, childNodeMeta.Code, data, onChange, selectedData]);

        const handleCopyRow = useCallback(() => {
            const key = childNodeMeta.ArrayNameInRoot;

            if (key) {
                const childArrayNewData = data[key] ? [...data[key]] : [];
                const firstSelectedRow = selectedData.at(0);
                const Parent = firstSelectedRow?.Parent;

                childArrayNewData.push({
                    ...firstSelectedRow,
                    Id: v4(),
                    Parent,
                    ChildIndex: (firstSelectedRow?.ChildIndex ?? 0) + 1
                });

                onChange(key, childArrayNewData);
            }
        }, [childNodeMeta.ArrayNameInRoot, data, onChange, selectedData]);

        const handleRemoveRow = useCallback(() => {
            const key = childNodeMeta.ArrayNameInRoot;

            if (key) {
                const childArrayNewData = [...dataSource];
                const selectedKeys = getSelectedRowKeys();

                for (let i = 0; i < selectedKeys.length; i++) {
                    const deletedIndex = childArrayNewData.findIndex(
                        (row) => row[rowsKeyColumnName] === selectedKeys[i]
                    );
                    if (deletedIndex >= 0) {
                        childArrayNewData.splice(deletedIndex, 1);
                    }
                }

                onChange(key, childArrayNewData);
            }
        }, [childNodeMeta.ArrayNameInRoot, data, getSelectedRowKeys, onChange]);
        // }, [setData, childNodeMeta.ArrayNameInRoot, getSelectedRowKeys]);

        const moveUp = () => {
            if (selectedData.length === 0) {
                message.warning('Выберите строки для перемещения');
                return;
            }

            if (!childNodeMeta.ArrayNameInRoot) {
                message.warning('Не заполнен ArrayNameInRoot в мете чайлда');
                return;
            }

            const newValue = [...dataSource];
            selectedData.forEach(({ Id }) => {
                const index = newValue.findIndex((item) => item.Id === Id);
                if (index > 0 && !selectedData.includes(newValue[index - 1].id)) {
                    const current = newValue[index];
                    // const prev = newValue[index + 1];
                    const prev = newValue[index - 1];

                    if (prev && current) {
                        let currentChildIndex = current.ChildIndex ?? 0;
                        const prevChildIndex = prev.ChildIndex ?? 0;

                        if (currentChildIndex === prevChildIndex) {
                            currentChildIndex -= 1;
                        }

                        current.ChildIndex = prevChildIndex;
                        prev.ChildIndex = currentChildIndex;

                        [newValue[index], newValue[index - 1]] = [
                            newValue[index - 1],
                            newValue[index]
                        ];
                    }
                }
            });

            handleChangeChild(newValue);
        };

        // Функция для перемещения строк вниз
        const moveDown = () => {
            if (selectedData.length === 0) {
                message.warning('Выберите строки для перемещения');
                return;
            }

            if (!childNodeMeta.ArrayNameInRoot) {
                message.warning('Не заполнен ArrayNameInRoot в мете чайлда');
                return;
            }

            const newValue = [...data[childNodeMeta.ArrayNameInRoot]];
            for (let i = selectedData.length - 1; i >= 0; i--) {
                const Id = selectedData[i].Id;
                const index = newValue.findIndex((item) => item.Id === Id);
                if (index < newValue.length - 1 && !selectedData.includes(newValue[index + 1].id)) {
                    const current = newValue[index];
                    const next = newValue[index + 1];

                    if (current && next) {
                        let currentChildIndex = current.ChildIndex ?? 0;
                        const nextChildIndex = next.ChildIndex ?? 0;

                        if (currentChildIndex === nextChildIndex) {
                            currentChildIndex += 1;
                        }

                        current.ChildIndex = nextChildIndex;
                        next.ChildIndex = currentChildIndex;

                        [newValue[index], newValue[index + 1]] = [
                            newValue[index + 1],
                            newValue[index]
                        ];
                    }
                }
            }

            handleChangeChild(newValue);
        };

        // console.log(data[childNodeMeta.ArrayNameInRoot || 'Key']);

        const titleRender = useCallback(() => {
            return (
                <Flex gap={5}>
                    <ButtonWithTooltips
                        id="add_row"
                        icon={<PlusOutlined />}
                        tooltipTitle={t('add_row')}
                        type="text"
                        onClick={handleAddRow}
                    />
                    <ButtonWithTooltips
                        id="remove_row"
                        icon={<MinusOutlined />}
                        tooltipTitle={t('remove_row')}
                        type="text"
                        onClick={handleRemoveRow}
                        disabled={!isRowsSelected}
                    />

                    <Space.Compact>
                        <Button
                            // size={isBigMobile ? 'middle' : 'small'}
                            type={'text'}
                            onClick={moveUp}
                            disabled={
                                selectedData.length === 0 ||
                                selectedData.length === dataSource.length ||
                                dataSource.length <= 1 ||
                                selectedData[0]?.Id === dataSource[0]?.Id
                            }
                        >
                            {/* <CaretUpOutlined /> */}
                            <ArrowUpOutlined />
                        </Button>
                        <Button
                            // size={isBigMobile ? 'middle' : 'small'}
                            type={'text'}
                            onClick={moveDown}
                            disabled={
                                selectedData.length === 0 ||
                                selectedData.length === dataSource.length ||
                                dataSource.length <= 1 ||
                                selectedData.at(-1)?.Id === dataSource.at(-1)?.Id
                            }
                        >
                            {/* <CaretDownOutlined /> */}
                            <ArrowDownOutlined />
                        </Button>
                    </Space.Compact>
                </Flex>
            );
        }, [handleAddRow, handleRemoveRow, isRowsSelected, t]);

        const defaultSortOrder = parseDefaultSortOrder(childNodeMeta.SortOrder);

        const collapseItems = [
            {
                key: tableTitle,
                label: <strong>{tableTitle}</strong>,
                children: (
                    <SmartTable
                        scroll={scroll}
                        rowContextMenuItems={
                            isEditable
                                ? [
                                      {
                                          key: 'add_row',
                                          label: t('add_row'),
                                          icon: <PlusOutlined />,
                                          onClick: handleAddRow
                                          // disabled: isMetaReadOnly
                                      },
                                      { type: 'divider' },
                                      {
                                          key: 'remove_row',
                                          label: t('remove_row'),
                                          icon: <MinusOutlined />,
                                          onClick: handleRemoveRow
                                          // disabled: isMetaReadOnly
                                      },
                                      {
                                          key: 'copy',
                                          label: t('copy'),
                                          icon: <CopyOutlined />,
                                          onClick: handleCopyRow
                                          // disabled: isMetaReadOnly
                                      }
                                  ]
                                : undefined
                        }
                        virtual={dataSource?.length > 30}
                        meta={childNodeMeta.Code}
                        rootMeta={rootMeta || childNodeMeta.RootMeta_Code}
                        rootData={rootData}
                        data={dataSource}
                        fields={childNodeMeta.Fields}
                        editable={isEditable}
                        viewMode={
                            isEditable &&
                            childNodeMeta.Fields?.filter((f) => !f.IsHiddenOnTable)?.length <=
                                inlineEditMaxItems &&
                            dataSource.length <= inlineEditMaxItems
                                ? 'inline'
                                : !isEditable &&
                                  childNodeMeta.Fields?.filter((f) => !f.IsHiddenOnTable)?.length <=
                                      inlineEditMaxItems
                                ? 'inline'
                                : 'modal'
                        }
                        selectable={forceEnableSelection || isEditable}
                        // clickable={false}
                        setData={handleChangeChild}
                        titleRender={forceEnableToolbar || isEditable ? titleRender : undefined}
                        selectedRows={selectedData}
                        onRowSelectionChange={setSelectedData}
                        doubleClickable={!!onChildDoubleClick}
                        onDoubleClick={onChildDoubleClick}
                        defaultSortOrder={defaultSortOrder}
                        leftFixedField={viewFieldName}
                        components={{
                            // @ts-ignore
                            body: EmptyMarker({ noDescription: true, noImage: true })
                        }}
                    />
                )
            }
        ];

        return (
            <Flex
                vertical
                className="smart_detail_page__child_content"
                style={{ padding, width: '100%' }}
            >
                {enableCollapse && tableTitle ? (
                    <Collapse
                        className="smart_detail_page__collapse"
                        size={SIZE}
                        defaultActiveKey={[tableTitle]}
                        items={collapseItems}
                    />
                ) : (
                    collapseItems.map(({ children }) => (
                        <div style={{ width: '100%' }}>
                            {tableTitle ? (
                                <span
                                    style={
                                        isBigMobile
                                            ? {
                                                  color: 'rgba(0, 0, 0, 0.65)',
                                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                                  display: 'block',
                                                  padding: '8px 16px',
                                                  width: 'calc(100% + 22px)',
                                                  transform: 'translateX(-11px)'
                                              }
                                            : {
                                                  display: 'block',
                                                  alignSelf: 'center',
                                                  color: 'rgba(0, 0, 0, 0.45)',
                                                  paddingBottom: 5
                                              }
                                    }
                                >
                                    {`${tableTitle}:`}
                                </span>
                            ) : null}
                            {children}
                        </div>
                    ))
                )}
            </Flex>
        );
    }
);
