import { useCallback, useEffect, useState } from 'react';

import { SelectResponse } from 'modules/services/backend-api/generated_api';
import { Meta } from 'modules/services/backend-api/generated_info';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { useNotifications } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routeStore, useStoreNavigate } from 'utils/store';
import { fnv1aHash, getDetailPageTitle } from 'smart/utils';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { useHandlerRun } from 'smart/utils/hooks';
import { PlainObject } from '@gilbarbara/types';
import { toJS } from 'mobx';
import { transformFilterString } from '../../../../utils/transformFilterString';

interface IResult {
    info: Meta | undefined;
    select: SelectResponse | undefined;
}

interface UseTableQueryAndActionsProps {
    meta: string;
    selectedRows: IObjectWithId[];
    setSelectedRows: (rows: IObjectWithId[]) => void;
    filterString?: string;
    preFilters?: string;
    currentPathForGetMetaInfo?: string;
}

export const useTableQueryAndActions = ({
    meta,
    filterString,
    preFilters,
    currentPathForGetMetaInfo,
    selectedRows,
    setSelectedRows
}: UseTableQueryAndActionsProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { notification } = useNotifications();
    const location = useLocation();
    const storeNavigate = useStoreNavigate();
    const { run } = useHandlerRun();

    const [isLoading, setIsLoading] = useState(false);
    const [logMessages, setLogMessages] = useState<LogMessage[]>([]);

    useEffect(() => {
        (async () => {
            const softDelete = await metaStore.getParam({
                param_name: 'SOFT_DELETE',
                default_value: false
            });
            console.log('[UseTableQueryAndActions] SOFT_DELETE = ', softDelete);
        })();
    }, []);

    const softDelete = metaStore.meta.get('all')?.params?.SOFT_DELETE;

    const fetch = useCallback(
        async ({
            fetchInfo = true,
            fetchSelect = true,
            forceFetching = false,
            refetchSelectedRowsOnly = false
        }: {
            fetchInfo?: boolean;
            fetchSelect?: boolean;
            forceFetching?: boolean;
            refetchSelectedRowsOnly?: boolean;
        }) => {
            const result: IResult = {
                info: undefined,
                select: undefined
            };

            try {
                setIsLoading(true);

                if (fetchInfo) {
                    // console.log('FETCH INFO');
                    result.info = await metaStore.getInfo(meta, currentPathForGetMetaInfo);
                }

                if (fetchSelect) {
                    // console.log('FETCH SELECT');
                    const cachedSelect = metaStore.meta.get(meta)?.select;

                    // const parsedFilterString =
                    //     filterString && userData
                    //         ? parseTemplate(filterString, language, {
                    //               user: userData
                    //           })
                    //         : filterString;

                    // console.log('c', toJS(cachedSelect), filterString);

                    result.select =
                        cachedSelect?.filters === filterString && !forceFetching
                            ? cachedSelect
                            : await metaStore.makeSelect({
                                  meta,
                                  filters:
                                      selectedRows.length && refetchSelectedRowsOnly
                                          ? `Id=in.[${selectedRows.map(({ Id }) => Id)}]&${
                                                filterString || ''
                                            }`
                                          : `${filterString || ''}`,
                                  page: 1,
                                  page_size: 10000
                                  //   page_size: window.location.hostname === 'localhost' ? 350 : 10000
                                  // page_size: 50000
                              });

                    if (selectedRows.length && refetchSelectedRowsOnly && result.select?.objects) {
                        setSelectedRows(result.select.objects as IObjectWithId[]);
                    }

                    // console.log('r', toJS(result.select));
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }

            return result;
        },
        [currentPathForGetMetaInfo, filterString, meta, selectedRows]
    );

    const refresh = useCallback(
        async (refetchSelectedRowsOnly?: boolean) => {
            // debugger;

            return fetch({
                fetchInfo: false,
                fetchSelect: true,
                forceFetching: true,
                refetchSelectedRowsOnly
            });
        },
        [fetch]
    );

    const remove = useCallback(async (value?: string, rowData?: IObjectWithId) => {
        await run({
            Action_Id: '',
            meta,
            ids: rowData ? [rowData.Id] : selectedRows?.map(({ Id }) => String(Id)) || [],
            handler: softDelete ? 'SoftDelete' : 'Delete',
            args: {}
        });

        if (rowData) setSelectedRows((prev) => prev.filter((row) => row.Id !== rowData.Id));
        else setSelectedRows([]);

        refresh();
    }, [meta, refresh, run, selectedRows, softDelete]);

    const create = useCallback(async () => {
        let pathname = `${location.pathname}/new`;

        if (location.pathname.endsWith('/')) {
            pathname = `${location.pathname}new`;
        }

        const singularName =
            // metaStore.meta.get(meta)?.info?.SingularName?.[language] ||
            metaStore.meta.get(meta)?.info?.SingularName ?? pathname.split('/').at(-2); // get pre last pathname element for correctly displaying object context

        const parentFieldName = metaStore.meta
            .get(meta)
            ?.info?.Fields?.find((field) => field.ValueType?.includes('is_group'))?.FieldName;

        const parent = selectedRows?.[0]?.[parentFieldName ?? 'Parent'];
        // const parentName =
        //     parent?.Name?.[language] ??
        //     parent?.PluralName?.[language] ??
        //     parent?.ShortTitle?.[language] ??
        //     parent?.Code ??
        //     parent?.Key;

        let preDataFromParams: PlainObject<any> = {};

        if (preFilters) {
            const fields = toJS(metaStore.meta.get(meta)?.info?.Fields) ?? [];

            preDataFromParams = await transformFilterString(preFilters, fields, language);
        }

        // console.log(preFilters, preDataFromParams);

        const data = parent
            ? {
                  ...preDataFromParams,
                  [parentFieldName ?? 'Parent']: parent
              }
            : preDataFromParams;

        storeNavigate(
            { pathname },
            {
                state: {
                    ...location.state,
                    // pageTitle: `${t('new')} (${singularName}${parent ? ` - ${parentName}` : ''})`,
                    pageTitle: 'new',
                    extraPageTitle: toJS(singularName),
                    cacheKey: fnv1aHash(`${JSON.stringify(data)}`),
                    data
                }
            }
        );
    }, [
        preFilters,
        language,
        location.pathname,
        location.state,
        meta,
        storeNavigate,
        selectedRows
    ]);

    const copy = useCallback(async () => {
        if (routeStore.routes.find(({ pathname }) => pathname.includes('copy'))) {
            notification.error({
                description: t('close_or_finish_previous_copying') as string,
                message: t('already_open')
            });

            return;
        }

        const response = await run({
            Action_Id: '',
            meta,
            ids: selectedRows?.map(({ Id }) => String(Id)) || [],
            handler: 'Copy',
            args: {}
        });

        if (
            response?.run?.[0]?.Status === 'EXECUTION_SUCCEEDED' &&
            response?.run &&
            response?.run[0].Result
        ) {
            const data = response.run[0]?.Result?.CopiedObject;

            let pathname = `${location.pathname}/copy`;

            if (location.pathname.endsWith('/')) {
                pathname = `${location.pathname}copy`;
            }

            const singularName =
                // metaStore.meta.get(meta)?.info?.SingularName?.[language] ||
                toJS(metaStore.meta.get(meta)?.info?.SingularName) || pathname.split('/').at(-2); // get pre last pathname element for correctly displaying object context

            storeNavigate(
                { pathname },
                {
                    state: {
                        ...location.state,
                        // pageTitle: `${t('new')} (${singularName})`,
                        pageTitle: 'new',
                        extraPageTitle: singularName,
                        cacheKey: fnv1aHash(`${JSON.stringify(data)}`),
                        data
                    }
                }
            );
        }
    }, [
        location.pathname,
        location.state,
        meta,
        notification,
        run,
        selectedRows,
        storeNavigate,
        t
    ]);

    const edit = useCallback((value?: any, rowData?: IObjectWithId, options?: { openViewModeFirst?: boolean }) => {
        const row = rowData ?? selectedRows?.[0];

        const metaRoutes = metaStore.meta.get(meta)?.routes;
        const metaDetailRouteId = metaRoutes?.find((route) =>
            route.path.includes(`${location.pathname}`)
        )?.detail_route_id;

        let tunedDetailPageRoute;
        if (metaDetailRouteId) {
            tunedDetailPageRoute = metaStore.meta
                .get(meta)
                ?.routes?.find((route) => route.id === metaDetailRouteId);
        }

        if (row?.Id) {
            const pageTitle = getDetailPageTitle({
                pathname: location.pathname,
                state: location.state,
                data: row,
                meta
            });

            const state = {
                ...location.state,
                mode: options?.openViewModeFirst ? 'view' : 'edit',
                ...pageTitle
            };

            if (tunedDetailPageRoute) {
                storeNavigate(
                    { pathname: `${tunedDetailPageRoute.path}/${row.Id}`, search: '' },
                    { state }
                );
            } else
                storeNavigate(
                    { pathname: `${location.pathname}/${row.Id}`, search: '' },
                    { state }
                );
        }
    }, [location.pathname, location.state, meta, selectedRows, storeNavigate]);

    return { fetch, isLoading, logMessages, setLogMessages, refresh, remove, create, copy, edit };
};
