import { TranslationOutlined } from '@ant-design/icons';
import { Button, Input, Space, Typography } from 'antd';
import _ from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { i18n, LANGUAGES } from 'utils/i18n/i18n';
import { MultilanguageValueType } from '../types';
import { LanguageTranslateDialog } from './components/LanguageTranslateDialog';

import './SmartMultilanguageField.scss';
import { StringField } from '../StringField/StringField';
import { Variant } from 'antd/es/config-provider';
import { metaStore } from 'utils/store/MetaStore';
import { RowHeight } from '../SmartTable/BaseTable';

export type { MultilanguageValueType };

interface SmartMultilanguageFieldProps {
    value: MultilanguageValueType;
    onChange: (newValue: MultilanguageValueType) => void;
    label?: string;
    disabled?: boolean;
    fieldName?: string;
    variant?: Variant;
}

const initialValue = _.zipObject(i18n.languages, Array(i18n.languages.length).fill(''));

export const SmartMultilanguageField = memo<SmartMultilanguageFieldProps>(
    ({ value, onChange, label, disabled, variant, fieldName = '' }) => {
        const {
            i18n: { language: currentLanguage },
            t
        } = useTranslation();

        const [languageTranslateDialogOpen, setLanguageTranslateDialogOpen] = useState(false);

        const handleChange = useCallback(
            (newValue: string) => {
                const copyValue = { ...value };
                copyValue[currentLanguage as LANGUAGES] = newValue;

                if (Object.keys(copyValue).length === 0) {
                    onChange(null);
                } else {
                    onChange(copyValue);
                }
            },
            [value, currentLanguage, onChange]
        );

        const tableDensity = (metaStore.meta.get('all')?.params?.TABLE_DENSITY ??
                            'NORMAL');
        const isBorderless = variant === 'borderless';

        return (
            <>
                <LanguageTranslateDialog
                    open={languageTranslateDialogOpen}
                    fieldName={fieldName}
                    setOpen={setLanguageTranslateDialogOpen}
                    value={value || initialValue}
                    setValue={onChange}
                />
                {label && <Typography.Text type="secondary">{label}</Typography.Text>}
                <Space.Compact style={{ width: '100%', height: isBorderless ? RowHeight[tableDensity] : undefined }}>
                    <StringField
                        disabled={disabled}
                        placeholder={t('no_value') as string}
                        allowClear
                        value={value ? value[currentLanguage as LANGUAGES] : undefined}
                        onChange={handleChange}
                        style={{ height: '100%' }}
                        variant={variant}
                    />

                    <Button
                        className="language_translate_button"
                        // type="default"
                        onClick={() => {
                            setLanguageTranslateDialogOpen(true);
                        }}
                        icon={<TranslationOutlined />}
                        style={{
                            color: 'rgba(0,0,0,.25)',
                            height: isBorderless ? '100%' : undefined, 
                            borderRadius: isBorderless ? 0 : undefined, 
                            background: isBorderless ? 'transparent' : undefined
                        }}
                        type={isBorderless ? 'text' : 'default'}
                    />
                </Space.Compact>
            </>
        );
    }
);
