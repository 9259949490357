import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Flex, Segmented, Slider, Tooltip, Typography } from 'antd';
import { Popup } from 'antd-mobile';
import dayjs from 'dayjs';
import { range } from 'lodash';
import { useAsync, useAsyncRetry, useBoolean, useMap, useMedia, useUpdateEffect } from 'react-use';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'is-lite/exports';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { ReactComponent as TransposeIcon } from 'assets/icons/transpose.svg';

import { PlainObject } from '@gilbarbara/types';
import { Legend } from './components';
import { DATE_FORMAT, DAY_RANGE_ENDPOINTS } from './contants';
import {
    RegBookedFleetInterval,
    RegDriverSlot
} from '../../../modules/services/backend-api/generated_models';
// import { BookedSlotsDataType } from './types';
import './SmartTimelineTablePage.scss';
import { SmartMultiSelectField, SmartTimeline } from '../../components';
import { LazyIcon } from '../../ui';

const { Text } = Typography;

interface SmartTimelinePageProps {
    fromMeta: string;
    filters?: Filter[];
}

type ViewMetaType = 'drivers' | 'VH' | 'resources';

const viewSlotsMetaMap = {
    drivers: 'RegDriverSlots',
    VH: 'RegVehicleSlots',
    resources: 'RegLocationResourceSlots'
};

const viewMetaPropertyMap = {
    drivers: 'Driver',
    VH: 'Vehicle',
    resources: 'LocationResource'
};

const viewMetaMap = {
    drivers: 'CatPartners',
    VH: 'CatVehicles',
    resources: 'CatLocationResources'
};

export const ModifiedSmartTimelineTablePage = memo<SmartTimelinePageProps>(
    ({ fromMeta, filters }) => {
        const enableTimelineMultiplicator =
            metaStore.meta.get('all')?.params?.ENABLE_TIMELINE_MULTIPLICATOR;
        // const isTripDisabled = fromMeta === 'DocTrips';
        // const isResourcesDisabled = fromMeta === 'CatLocationResources';
        const isLocationsDisabled = fromMeta === 'CatLocations';
        const isVehiclesDisabled = fromMeta === viewMetaMap.VH;
        const isDriversDisabled = fromMeta === viewMetaMap.drivers;

        const initialLocations =
            // stateData?.SourceLocation && fromMeta === 'DocTrips'
            //     ? [stateData.SourceLocation]
            //     : stateData && fromMeta === 'CatLocations'
            //     ? [stateData] :
            [];
        // const initialVehicles = stateData && isResourcesDisabled ? [stateData] : [];
        const initialVehicles = [];
        // const initialDrivers = stateData && isResourcesDisabled ? [stateData] : [];
        const initialDrivers = [];

        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');

        const [datesRange, setDatesRange] = useState('day');
        const [viewMeta, setViewMeta] = useState<ViewMetaType>('drivers');
        const [displayMode, setDisplayMode] = useState<'horizontal' | 'vertical'>(
            isBigMobile ? 'vertical' : 'horizontal'
        );
        const [date, setDate] = useState(dayjs());
        // const [resources, setResources] = useState<IObjectWithId[]>([]);
        // const [locations, setLocations] = useState<IObjectWithId[]>([]);
        // const [trip, setTrip] = useState<IObjectWithId | null>(null);
        // const [selectedRows, setSelectedRows] = useState<IObjectWithId[]>([]);
        const [legend, legendMake] = useMap<PlainObject>({});
        const [isOpenMobilePopup, setIsOpenMobilePopup] = useBoolean(false);
        const [locations, setLocations] = useState<IObjectWithId[]>(initialLocations);
        const [vehicles, setVehicles] = useState<IObjectWithId[]>(initialVehicles);
        const [drivers, setDrivers] = useState<IObjectWithId[]>(initialDrivers);
        const [multiplicator, setMultiplicator] = useState(1);
        const [multiplicatorTechnical, setMultiplicatorTechnical] = useState(0);

        const locationsIdsIn = locations.map(({ Id }) => Id).join(',');
        const vehiclesIdsIn = vehicles.map(({ Id }) => Id).join(',');
        const driversIdsIn = drivers.map(({ Id }) => Id).join(',');

        const daysCountFromDateRangesEndpoint = useMemo(() => {
            switch (datesRange) {
                case 'day':
                    return 1 * multiplicator;
                case '4_days':
                    return 4 * multiplicator;
                case 'week':
                    return 7 * multiplicator;
                default:
                    return 1 * multiplicator;
            }
        }, [datesRange, multiplicator]);

        const dateRangeToFetch = useMemo(
            () =>
                range(0, daysCountFromDateRangesEndpoint).map((index) => {
                    return date.add(index, 'days').format(DATE_FORMAT);
                }),
            [date, daysCountFromDateRangesEndpoint]
        );
        const dateIn = dateRangeToFetch.join(',');
        // const resourcesIdsIn = resources.map(({ Id }) => Id).join(',');
        // const minDate = useMemo(
        //     () => date.set('hours', 0).set('minutes', 0).set('seconds', 0).format(DATE_TIME_FORMAT),
        //     [date]
        // );
        // const maxDate = useMemo(
        //     () =>
        //         date
        //             .add(daysCountFromDateRangesEndpoint - 1, 'days')
        //             .set('hours', 23)
        //             .set('minutes', 59)
        //             .set('seconds', 59)
        //             .format(DATE_TIME_FORMAT),
        //     [date, daysCountFromDateRangesEndpoint]
        // );

        // // Запрашиваем рейсы для отбора
        // useAsync(async () => {
        //     if (filters?.[0].value[0] && fromMeta === 'DocTrips') {
        //         const response = await metaStore.makeSelect({
        //             meta: 'DocTrips',
        //             filters: `Id=eq.${filters?.[0].value[0]}`
        //         });
        //
        //         if (response) setTrip(response.objects[0]);
        //     }
        // }, [filters, fromMeta]);
        //

        // Запрашиваем МПЛ для отбора
        const { loading: locationsLoading } = useAsync(async () => {
            if (filters?.length && fromMeta === 'CatLocations' && !locations.length) {
                // console.log('LOCATIONS RESPONSE');
                const response = await metaStore.makeSelect({
                    meta: 'CatLocations',
                    // filters: `Id=in.[${location.state.filters[0].value.join(',')}]`
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setLocations(response.objects);
            }
        }, [filters, fromMeta]);

        // Запрашиваем Ресурсы для отбора
        const { loading: vehiclesLoading } = useAsync(async () => {
            // if (filters?.length && isResourcesDisabled && !vehicles.length) {
            if (filters?.length && isVehiclesDisabled && !vehicles.length) {
                const response = await metaStore.makeSelect({
                    meta: viewMetaMap.VH,
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setVehicles(response.objects);
            }
        }, [filters, fromMeta]);

        // Запрашиваем Ресурсы для отбора
        const { loading: driversLoading } = useAsync(async () => {
            // if (filters?.length && isResourcesDisabled && !drivers.length) {
            if (filters?.length && isDriversDisabled && !drivers.length) {
                const response = await metaStore.makeSelect({
                    meta: viewMetaMap.drivers,
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setDrivers(response.objects);
            }
        }, [filters, fromMeta]);
        //
        // // Запрашиваем Ресурсы для отбора
        // useAsync(async () => {
        //     if (filters?.length && fromMeta === 'CatLocationResources') {
        //         const response = await metaStore.makeSelect({
        //             meta: 'CatLocationResources',
        //             filters: `Id=in.[${filters?.[0].value.join(',')}]`
        //         });
        //
        //         if (response) setResources(response.objects);
        //     }
        // }, [filters, fromMeta]);

        // Запрашиваем Свободные слоты
        const freeSlots = useAsyncRetry(async () => {
            // if (!locationsIdsIn) return [];

            let filters = `SlotDate=in.[${dateIn}]`;

            if (!isEmpty(locationsIdsIn)) filters += `&DepotLocation=in.[${locationsIdsIn}]`;
            if (!isEmpty(driversIdsIn)) filters += `&Driver=in.[${driversIdsIn}]`;
            if (!isEmpty(vehiclesIdsIn)) filters += `&Vehicle=in.[${vehiclesIdsIn}]`;

            const res = await metaStore.makeSelect({ meta: viewSlotsMetaMap[viewMeta], filters });

            // console.log(viewSlotsMetaMap[viewMeta], res);
            return res?.objects ?? ([] as RegDriverSlot[]);
        }, [dateIn, viewMeta, locationsIdsIn, vehiclesIdsIn, driversIdsIn]);

        const settedDefaultScale = useRef(false);

        useEffect(() => {
            settedDefaultScale.current = false;
        }, [locations, viewMeta, date]);

        useEffect(() => {
            if (
                !settedDefaultScale.current &&
                enableTimelineMultiplicator &&
                freeSlots.value?.length
            ) {
                const minDuration = Math.min(
                    ...freeSlots.value.map(
                        (item) => item.LocationResource?.SlotDuration ?? Infinity
                    )
                );

                const hours = Math.ceil(minDuration / 3600);

                let multiplicator = 1;

                if (hours === Infinity) multiplicator = 1;
                else if (hours >= 24) multiplicator = 24;
                else if (24 % hours === 0) {
                    if (hours === 3) multiplicator = hours - 1;
                    else if (hours === 8) multiplicator = 6;
                    else multiplicator = hours;
                } else multiplicator = 1;

                if (multiplicator === 1) setMultiplicatorTechnical(0);
                else if (multiplicator === 2) setMultiplicatorTechnical(20);
                else if (multiplicator === 4) setMultiplicatorTechnical(40);
                else if (multiplicator === 6) setMultiplicatorTechnical(60);
                else if (multiplicator === 12) setMultiplicatorTechnical(80);
                else if (multiplicator === 24) setMultiplicatorTechnical(100);

                setMultiplicator(multiplicator);
                settedDefaultScale.current = true;
            }
        }, [freeSlots.value, enableTimelineMultiplicator]);

        // Запрашиваем Занятые слоты
        const bookedSlots = useAsyncRetry(async () => {
            // if (!locationsIdsIn) return [];

            let filters = `SlotDate=in.[${dateIn}]`;

            if (!isEmpty(locationsIdsIn)) filters += `&DepotLocation=in.[${locationsIdsIn}]`;
            if (!isEmpty(driversIdsIn)) filters += `&Driver=in.[${driversIdsIn}]`;
            if (!isEmpty(vehiclesIdsIn)) filters += `&Vehicle=in.[${vehiclesIdsIn}]`;

            const res = await metaStore.makeSelect({
                meta: 'RegBookedFleetIntervalsV',
                filters,
                sort: 'StartDateAt ASC'
            });

            // console.log('RegBookedFleetIntervalsV', res);
            return res?.objects ?? ([] as RegBookedFleetInterval[]);
        }, [dateIn, viewMeta, locationsIdsIn, vehiclesIdsIn, driversIdsIn]);

        useUpdateEffect(() => {
            if (bookedSlots.value) {
                legendMake.setAll(
                    bookedSlots.value.reduce((acc, bs) => {
                        const res = acc;
                        if (bs.Status?.Color) res[bs.Status?.Color] = bs.Status?.Name?.[language];
                        return res;
                    }, {} as PlainObject)
                );
            }
        }, [bookedSlots.value]);

        const fetchBookedSlots = useCallback(
            () => Promise.all([freeSlots.retry(), bookedSlots.retry()]),
            [bookedSlots, freeSlots]
        );

        const loading = freeSlots.loading || bookedSlots.loading;

        const reload = async () => {
            await fetchBookedSlots();
        };

        const driversFilter = 'FirstRole=eq.DRIVER&Schedule=isnot.null';
        const vehiclesFilter = 'Schedule=isnot.null';

        return (
            <div id="smart_timeline_table_page_container">
                <Flex align="flex-start" gap={5} wrap="wrap" vertical style={{ marginBottom: 5 }}>
                    <Flex gap={5} wrap="wrap">
                        <Flex
                            align="center"
                            justify="flex-start"
                            style={{ width: isBigMobile ? '49%' : '180px' }}
                        >
                            <Segmented
                                size={isBigMobile ? 'large' : 'middle'}
                                style={{ width: '100%' }}
                                block
                                id="timeline_table_view_meta"
                                options={[
                                    { value: 'drivers', label: t('drivers') },
                                    { value: 'VH', label: t('VH') }
                                ]}
                                value={viewMeta}
                                onChange={setViewMeta}
                            />
                        </Flex>
                        <Flex
                            align="center"
                            justify="flex-start"
                            style={{ width: isBigMobile ? '49%' : '250px' }}
                        >
                            <Segmented
                                size={isBigMobile ? 'large' : 'middle'}
                                style={{ width: '100%' }}
                                block
                                id="timeline_table_date_interval"
                                options={(() => {
                                    const options = [
                                        {
                                            value: 'day',
                                            label: t(
                                                multiplicator === 2
                                                    ? '2_days'
                                                    : multiplicator === 4
                                                    ? '4_days'
                                                    : multiplicator === 6
                                                    ? '6_days'
                                                    : multiplicator === 12
                                                    ? '12_days'
                                                    : multiplicator === 24
                                                    ? '24_days'
                                                    : 'day'
                                            ) as string
                                        },
                                        {
                                            value: '4_days',
                                            label: t(
                                                multiplicator === 2
                                                    ? 'week'
                                                    : multiplicator === 4
                                                    ? 'half_month'
                                                    : multiplicator === 6
                                                    ? '24_days'
                                                    : multiplicator === 12
                                                    ? 'month'
                                                    : multiplicator === 24
                                                    ? '3_months'
                                                    : '4_days'
                                            ) as string
                                        }
                                    ];

                                    if (multiplicator === 1 || multiplicator === 2) {
                                        options.push({
                                            value: 'week',
                                            label: t(
                                                multiplicator === 2 ? '2_weeks' : 'week'
                                            ) as string
                                        });
                                    }

                                    return options;
                                })()}
                                value={datesRange}
                                onChange={setDatesRange}
                            />
                        </Flex>
                        <Flex gap={5} wrap="wrap">
                            <Flex align="center" justify="center" gap={5}>
                                {!isBigMobile && <Text>{t('date')}:</Text>}
                                <DatePicker
                                    size={isBigMobile ? 'large' : 'middle'}
                                    style={{ width: '150px' }}
                                    id="timeline_table_date"
                                    value={date}
                                    onChange={setDate}
                                    format={'DD.MM.YYYY'}
                                    allowClear={false}
                                    getPopupContainer={() =>
                                        document.getElementById(
                                            'smart_timeline_table_page_container'
                                        ) as HTMLElement
                                    }
                                />
                            </Flex>
                            <Button
                                size={isBigMobile ? 'large' : 'middle'}
                                icon={<ReloadOutlined />}
                                onClick={reload}
                            />
                            <Legend size={isBigMobile ? 'large' : 'middle'} legend={legend} />
                            {!isBigMobile && (
                                <Tooltip
                                    title={t('transpose')}
                                    placement={'bottom'}
                                    destroyTooltipOnHide={true}
                                >
                                    <Button
                                        icon={<LazyIcon component={TransposeIcon} />}
                                        onClick={() => {
                                            setDisplayMode((prev) =>
                                                prev === 'horizontal' ? 'vertical' : 'horizontal'
                                            );
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {enableTimelineMultiplicator && (
                                <Flex align="center" justify="flex-start" gap={10}>
                                    {!isBigMobile && <Text>{t('scale')}:</Text>}
                                    <Slider
                                        marks={{
                                            0: <></>,
                                            20: <></>,
                                            40: <></>,
                                            60: <></>,
                                            80: <></>,
                                            100: <></>
                                        }}
                                        tooltip={{
                                            trigger: 'hover',
                                            formatter: (value) =>
                                                value === 20
                                                    ? 'x2'
                                                    : value === 40
                                                    ? 'x4'
                                                    : value === 60
                                                    ? 'x6'
                                                    : value === 80
                                                    ? 'x12'
                                                    : value === 100
                                                    ? 'x24'
                                                    : 'x1'
                                        }}
                                        step={null}
                                        defaultValue={0}
                                        value={multiplicatorTechnical}
                                        onChange={(value) => {
                                            setMultiplicatorTechnical(value);

                                            if (value === 0) setMultiplicator(1);
                                            else if (value === 20) setMultiplicator(2);
                                            else if (value === 40) setMultiplicator(4);
                                            else if (value === 60) setMultiplicator(6);
                                            else if (value === 80) setMultiplicator(12);
                                            else if (value === 100) setMultiplicator(24);
                                        }}
                                        style={{
                                            width: 200,
                                            marginTop: 0,
                                            marginLeft: 8,
                                            marginBottom: -1,
                                            marginRight: 12
                                        }}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>

                    {!isBigMobile ? (
                        <Flex align="flex-start" gap={5} wrap="wrap" style={{ marginBottom: 5 }}>
                            <Flex align="center" gap={5}>
                                <Text>{t('locations')}:</Text>
                                {/* <Text>
                                    <Text style={{ display: 'inline' }} type="danger">
                                        *
                                    </Text>
                                    {t('locations')}:
                                </Text> */}
                                <div style={{ width: '250px' }}>
                                    <SmartMultiSelectField
                                        metaName="CatLocations"
                                        // filters="TotalResourcesCount=gt.0"
                                        onChange={setLocations}
                                        loading={locationsLoading}
                                        value={locations}
                                        // isDisabled={isLocationsDisabled || isResourcesDisabled}
                                        isDisabled={isLocationsDisabled}
                                    />
                                </div>
                            </Flex>
                            <Flex align="center" gap={5}>
                                <Text>{t(viewMeta)}:</Text>
                                <div style={{ width: '250px' }}>
                                    <SmartMultiSelectField
                                        metaName={viewMetaMap[viewMeta]}
                                        onChange={viewMeta === 'VH' ? setVehicles : setDrivers}
                                        value={viewMeta === 'VH' ? vehicles : drivers}
                                        loading={
                                            viewMeta === 'VH' ? vehiclesLoading : driversLoading
                                        }
                                        isDisabled={
                                            viewMeta === 'VH'
                                                ? isVehiclesDisabled
                                                : isDriversDisabled
                                            // : isDriversDisabled) || !locationsIdsIn
                                        }
                                        // isDisabled={!locationsIdsIn}
                                        filters={
                                            locations.length === 0
                                                ? viewMeta === 'VH'
                                                    ? vehiclesFilter
                                                    : driversFilter
                                                : `${
                                                      viewMeta === 'VH'
                                                          ? vehiclesFilter
                                                          : driversFilter
                                                  }${
                                                      viewMeta === 'VH'
                                                          ? `&DepotLocation=in.[${locations
                                                                .map(({ Id }) => Id)
                                                                .join(',')}]`
                                                          : ''
                                                  }`
                                        }
                                    />
                                </div>
                            </Flex>
                        </Flex>
                    ) : (
                        <>
                            <Badge size={'large'} dot={!!locations?.length}>
                                <Button
                                    icon={<FilterOutlined />}
                                    children={t('filters')}
                                    onClick={() => setIsOpenMobilePopup(true)}
                                    size={'large'}
                                />
                            </Badge>
                            <Popup
                                bodyStyle={{
                                    display: 'flex',
                                    gap: 20,
                                    flexDirection: 'column',
                                    padding: 10
                                }}
                                onClose={() => setIsOpenMobilePopup(false)}
                                onMaskClick={() => setIsOpenMobilePopup(false)}
                                visible={isOpenMobilePopup}
                            >
                                <SmartMultiSelectField
                                    placeholder={t('locations') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName="CatLocations"
                                    onChange={setLocations}
                                    value={locations}
                                    // isDisabled={isLocationsDisabled || isResourcesDisabled}
                                    isDisabled={isLocationsDisabled}
                                />

                                <SmartMultiSelectField
                                    placeholder={t(viewMeta) as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName={viewMetaMap[viewMeta]}
                                    onChange={viewMeta === 'VH' ? setVehicles : setDrivers}
                                    value={viewMeta === 'VH' ? vehicles : drivers}
                                    isDisabled={
                                        viewMeta === 'VH' ? isVehiclesDisabled : isDriversDisabled
                                    }
                                    filters={
                                        locations.length === 0
                                            ? ''
                                            : `Location=in.[${locations
                                                  .map(({ Id }) => Id)
                                                  .join(',')}]`
                                    }
                                />
                            </Popup>
                        </>
                    )}
                </Flex>
                {/* <Tabs defaultActiveKey="1" type="line" items={[tab1, tab2]} /> */}
                <SmartTimeline
                    daysCount={daysCountFromDateRangesEndpoint}
                    selectedDate={date}
                    selectedResources={[]}
                    selectedLocations={locations}
                    // selectedTrips={trip ? [trip] : selectedRows}
                    selectedTrips={[]}
                    selectedVisits={[]}
                    freeSlots={freeSlots.value ?? []}
                    bookedSlots={bookedSlots.value ?? []}
                    fetchBookedSlots={fetchBookedSlots}
                    loading={loading}
                    firstColumnFieldName={viewMetaPropertyMap[viewMeta]}
                    displayMode={displayMode}
                    meta={viewMetaMap[viewMeta]}
                    multiplicator={multiplicator}
                />
            </div>
        );
    }
);
