import { Variant } from 'antd/es/config-provider';
import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { DatePicker as MobileDatePicker } from 'antd-mobile';
import dayjs from 'dayjs';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { localeFormats, REGEX_REPLACE_GMT, TIME_FORMAT } from 'utils/helpers/dates';
import { LANGUAGES } from 'utils/i18n/i18n';

import './SmartDateField.scss';

interface SmartDateFieldProps {
    value: string | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: string | string[] | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    showTime?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    style?: { [key: string]: any };
    className?: string;
    multiple?: boolean;
    variant?: Variant;
}
// The component is used to display and edit date and time values using user's preferred format!

// TODO: добавить новый язык и формат к нему, если требуется
export const langMasks = {
    ru: {
        date: 'DD.MM.YYYY',
        datetime: 'DD.MM.YYYY HH:mm'
    },
    en: {
        date: 'MM/DD/YYYY',
        datetime: 'MM/DD/YYYY HH:mm'
    }
};

export const SmartDateField = memo<SmartDateFieldProps>(
    ({
        value,
        onChange,
        disabled,
        popoverContainerHtmlId,
        className,
        style,
        showTime,
        multiple,
        variant
    }) => {
        const { t, i18n } = useTranslation();
        const lang = i18n.language;
        const isRu = lang === 'ru';
        const isEn = lang === 'en';
        const datePickerRef = useRef(null);
        const [localValue, setLocalValue] = useState();
        const isBigMobile = useMedia('(max-width: 480px)');

        const format = showTime
            ? langMasks[i18n.language === 'ru' ? 'ru' : 'en'].datetime
            : langMasks[i18n.language === 'ru' ? 'ru' : 'en'].date;

        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (date: dayjs.Dayjs | null) => {
                // setLocalValue(date);
                onChange(dayjs(date).format(showTime ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD'));
            },
            [onChange, showTime]
        );

        const handleChangeMultiple = useCallback(
            (dates: dayjs.Dayjs[] | null) => {
                // setLocalValue(date);
                // console.log(
                //     dates?.map((date) => date.format('YYYY-MM-DD')),
                //     datesString
                // );
                onChange(dates ? dates.map((date) => date.format('YYYY-MM-DD')) : null);
            },
            [onChange]
        );

        const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
            if (['Enter', 'Tab'].includes(e.key)) {
                if (!e.target.value) {
                    onChange(null);
                    return;
                }

                const date = dayjs(
                    e.target.value,
                    localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
                    true
                ).locale(i18n.language);

                if (date.isValid()) {
                    handleChange(date);
                }
            }
        };

        const [openMobilePicker, setOpenMobilePicker] = useState(false);

        // TODO: add multiple support to mobile
        if (isBigMobile) {
            const currentValue = value
                ? dayjs(value.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
                : null;

            const renderLabel = (type: string, data: number) => {
                switch (type) {
                    case 'hour':
                        return `${data} ${isRu ? 'ч' : 'h'}`;
                    case 'minute':
                        return `${data} ${isRu ? 'мин' : 'min'}`;
                    case 'second':
                        return `${data} ${isRu ? 'с' : 's'}`;
                    default:
                        return data;
                }
            };

            return (
                <div className="smart_date_field smart_date_field__mobile">
                    <DatePicker
                        ref={datePickerRef}
                        placeholder={t('no_value') as string}
                        value={currentValue}
                        open={false}
                        format={{ format, type: 'mask' }}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        showTime={showTime ? { format: TIME_FORMAT } : false}
                        className={className}
                        style={style}
                        disabled={disabled}
                        onClick={() => setOpenMobilePicker(true)}
                    />
                    <MobileDatePicker
                        precision={showTime ? 'minute' : 'day'}
                        defaultValue={new Date()}
                        visible={openMobilePicker}
                        onClose={() => {
                            setOpenMobilePicker(false);
                        }}
                        value={currentValue?.toDate()}
                        confirmText={t('ok')}
                        cancelText={t('cancel')}
                        onConfirm={(date) => handleChange(dayjs(date))}
                        renderLabel={renderLabel}
                    />
                </div>
            );
        }

        const displayValue =
            value && typeof value === 'string'
                ? dayjs(value.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
                : null;

        const multipleDisplayValue =
            value && Array.isArray(value)
                ? value.map((v) => dayjs(v.replace(REGEX_REPLACE_GMT, '').replace('Z', '')))
                : null;

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <DatePicker
                variant={variant}
                multiple={multiple}
                maxTagCount="responsive"
                ref={datePickerRef}
                placeholder={t('no_value') as string}
                value={multiple ? multipleDisplayValue : displayValue}
                onChange={multiple ? handleChangeMultiple : handleChange}
                onBlur={
                    !multiple
                        ? (e) =>
                              handleKeyPress({
                                  target: { value: e.target.value || setLocalValue },
                                  key: 'Enter'
                              })
                        : undefined
                }
                format={{ format, type: 'mask' }}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                showTime={!multiple && showTime ? { format: TIME_FORMAT } : false}
                className={className}
                style={style}
                disabled={disabled}
                onKeyDown={handleKeyPress}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
            />
        );
    }
);
