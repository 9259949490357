import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { DatePicker as MobileDatePicker } from 'antd-mobile';
import dayjs, { Dayjs } from 'dayjs';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, localeFormats, TIME_FORMAT } from 'utils/helpers/dates';
import { LANGUAGES } from 'utils/i18n/i18n';
import { langMasks } from '../SmartDateField/SmartDateField';

import './SmartDateRangeField.scss';
import { Variant } from 'antd/es/config-provider';

type AntdRangeValue<T> = [T | null, T | null];

type DateRangeValue = {
    FromDate: string | null;
    ToDate: string | null;
};

interface SmartDateRangeFieldProps {
    value: DateRangeValue | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: DateRangeValue | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    showTime?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    style?: { [key: string]: any };
    className?: string;
    variant?: Variant;
}
// The component is used to display and edit date and time values using user's preferred format!

export const SmartDateRangeField = memo<SmartDateRangeFieldProps>(
    ({ value, onChange, disabled, popoverContainerHtmlId, className, style, showTime, utc, variant }) => {
        const { t, i18n } = useTranslation();
        const lang = i18n.language;
        const isRu = lang === 'ru';
        const isEn = lang === 'en';
        const isBigMobile = useMedia('(max-width: 480px)');

        // const [focusedField, setFocusedField] = React.useState<'FromDate' | 'ToDate' | null>(null);
        const [antdValue, setAntdValue] = React.useState<AntdRangeValue<Dayjs> | null>([
            value?.FromDate ? dayjs(value.FromDate) : null,
            value?.ToDate ? dayjs(value.ToDate) : null
        ]);

        useEffect(() => {
            const val = [
                value?.FromDate ? dayjs(value.FromDate) : null,
                value?.ToDate ? dayjs(value.ToDate) : null
            ];

            setAntdValue(val);
        }, [value]);

        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            // setAntdValue([null, null]);
            onChange(null);
        };

        const handleChange = useCallback(
            (dates: AntdRangeValue<Dayjs> | null) => {
                // console.log(dates);
                // const [FromDate, ToDate] = dates ?? [null, null];

                // console.log(dates, FromDate, ToDate);

                // if (FromDate && ToDate) {
                //     setAntdValue(dates);

                //     onChange({
                //         FromDate: FromDate ? FromDate.format(DATE_FORMAT) : null,
                //         ToDate: ToDate ? ToDate.format(DATE_FORMAT) : null
                //     });
                // }

                // console.log(dates);

                if (Array.isArray(dates)) {
                    setAntdValue(dates);

                    const [FromDate, ToDate] = dates;
                    onChange({
                        FromDate: FromDate ? dayjs(FromDate).format('YYYY-MM-DD') : null,
                        ToDate: ToDate ? dayjs(ToDate).format('YYYY-MM-DD') : null
                    });
                } else {
                    setAntdValue(dates);
                    onChange({
                        FromDate: dates ? dayjs(dates).format('YYYY-MM-DD') : null,
                        ToDate: null
                    });
                }
            },
            [onChange]
        );

        const format = langMasks[i18n.language ?? 'en'].date;

        // const handleFocus = (inputType: 'FromDate' | 'ToDate') => {
        //     setFocusedField(inputType);
        // };

        // const antdValue: AntdRangeValue<Dayjs> = [
        //     value?.FromDate ? dayjs(value.FromDate) : null,
        //     value?.ToDate ? dayjs(value.ToDate) : null
        // ];

        // const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
        //     const inputElement = e.target as HTMLInputElement;
        //     const inputElementValue = inputElement.value || '';
        //     if (['Enter', 'Tab'].includes(e.key)) {
        //         const date = dayjs(
        //             inputElementValue,
        //             localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
        //             true
        //         ).locale(i18n.language);

        //         if (date.isValid()) {
        //             const newAntdValue = [
        //                 ...(antdValue || [null, null])
        //             ] as AntdRangeValue<Dayjs | null>;
        //             if (focusedField === 'FromDate') {
        //                 newAntdValue[0] = date;
        //             } else if (focusedField === 'ToDate') {
        //                 newAntdValue[1] = date;
        //             }

        //             if (newAntdValue.length === 2 && newAntdValue[0] && newAntdValue[1]) {
        //                 // setAntdValue(newAntdValue);
        //                 handleChange(newAntdValue);
        //             }
        //         }
        //     }
        // };

        const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
            if (['Enter', 'Tab'].includes(e.key)) {
                if (!e.target.value) {
                    onChange(null);
                    return;
                }

                const date = dayjs(
                    e.target.value,
                    localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
                    true
                ).locale(i18n.language);

                if (date.isValid()) {
                    setAntdValue(date);
                    handleChange(date);
                }
            }
        };

        const [openMobilePicker, setOpenMobilePicker] = useState(false);
        const [openMobilePickerNext, setOpenMobilePickerNext] = useState(false);

        if (isBigMobile) {
            const renderLabel = (type: string, data: number) => {
                switch (type) {
                    case 'hour':
                        return `${data} ${isRu ? 'ч' : 'h'}`;
                    case 'minute':
                        return `${data} ${isRu ? 'мин' : 'min'}`;
                    case 'second':
                        return `${data} ${isRu ? 'с' : 's'}`;
                    default:
                        return data;
                }
            };

            const startValue = antdValue?.[0];
            const startDate = startValue?.toDate();

            const endValue = antdValue?.[1];
            const endDate = endValue?.toDate();

            return (
                <div className="smart_date_range_field smart_date_range_field__mobile">
                    <DatePicker.RangePicker
                        placeholder={[t('no_value') as string, t('no_value') as string]}
                        value={antdValue}
                        onChange={handleChange}
                        format={{ format, type: 'mask' }}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        showTime={showTime ? { format: TIME_FORMAT } : false}
                        className={className}
                        onKeyDown={handleKeyPress}
                        style={style}
                        disabled={disabled}
                        open={false}
                        onClick={() => setOpenMobilePicker(true)}
                    />
                    <MobileDatePicker
                        title={t('start')}
                        precision={showTime ? 'minute' : 'day'}
                        defaultValue={new Date()}
                        visible={openMobilePicker}
                        onClose={() => {
                            setOpenMobilePicker(false);
                        }}
                        value={startDate}
                        confirmText={t('next')}
                        cancelText={t('cancel')}
                        onConfirm={(date) => {
                            handleChange([dayjs(date), endValue || null]);
                            setOpenMobilePickerNext(true);
                        }}
                        renderLabel={renderLabel}
                        max={endDate}
                    />
                    <MobileDatePicker
                        title={t('end')}
                        precision={showTime ? 'minute' : 'day'}
                        defaultValue={new Date()}
                        visible={openMobilePickerNext}
                        onClose={() => {
                            setOpenMobilePickerNext(false);
                        }}
                        onCancel={() => {
                            setOpenMobilePickerNext(false);
                            setOpenMobilePicker(true);
                        }}
                        value={endDate}
                        confirmText={t('ok')}
                        cancelText={t('prev')}
                        onConfirm={(date) => handleChange([startValue || null, dayjs(date)])}
                        renderLabel={renderLabel}
                        min={startDate}
                    />
                </div>
            );
        }
        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <DatePicker.RangePicker
                placeholder={[t('no_value') as string, t('no_value') as string]}
                value={antdValue}
                onChange={handleChange}
                format={{ format, type: 'mask' }}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                showTime={showTime}
                className={className}
                variant={variant}
                // onFocus={(e) => {
                //     const inputElement = e.target as HTMLInputElement;
                //     const dateRangeAttr = inputElement.getAttribute('date-range');
                //     handleFocus(dateRangeAttr === 'start' ? 'FromDate' : 'ToDate');
                // }}
                // onBlur={(e) =>
                //     handleKeyPress({
                //         target: { value: e.target.value ?? null },
                //         key: 'Enter'
                //     })
                // }
                // onKeyDown={handleKeyPress}
                style={style}
                disabled={disabled}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
                // needConfirm={false}
            />
        );
    }
);
