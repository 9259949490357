import { ExclamationCircleOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Badge, Button, DatePicker, Flex, Segmented, Slider, Tooltip, Typography } from 'antd';
import { Popup } from 'antd-mobile';
import dayjs from 'dayjs';
import { isEmpty } from 'is-lite/exports';
import { range } from 'lodash';
import { useAsync, useAsyncRetry, useBoolean, useMap, useMedia, useUpdateEffect } from 'react-use';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT } from 'utils/helpers/dates';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { SmartMultiSelectField, SmartSelectField, SmartTimeline } from 'smart/components';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { ReactComponent as TransposeIcon } from 'assets/icons/transpose.svg';
import { Loader } from 'ui/Loader/Loader';

import { Legend } from './components';
import { DATE_FORMAT, DAY_RANGE_ENDPOINTS } from './contants';
import {
    DocTrip,
    RegBookedLocationSlot
} from '../../../modules/services/backend-api/generated_models';
// import { BookedSlotsDataType } from './types';
import './SmartTimelineTablePage.scss';
import { LazyIcon } from '../../ui';

const { Text } = Typography;

interface SmartTimelinePageProps {
    fromMeta: string;
    filters?: Filter[];
    fullState?: PlainObject;
}

export const SmartTimelineTablePage = memo<SmartTimelinePageProps>(
    ({ fromMeta, filters, fullState }) => {
        const stateData = fullState?.data;

        // console.log(fromMeta, stateData, fullState);
        const enableTimelineMultiplicator =
            metaStore.meta.get('all')?.params?.ENABLE_TIMELINE_MULTIPLICATOR;

        const isTripDisabled = fromMeta === 'DocTrips';
        const isResourcesDisabled = fromMeta === 'CatLocationResources';
        const isLocationsDisabled = fromMeta === 'CatLocations';
        const isVisitDisabled = fromMeta === 'DocTerminalVisits';

        const hasTripRoutes = !!metaStore.meta.get('all')?.routesMap?.get('DocTrips')?.length;
        const hasVripRoutes = !!metaStore.meta.get('all')?.routesMap?.get('DocTerminalVisits')
            ?.length;

        const initialLocations =
            stateData && (isTripDisabled || isVisitDisabled)
                ? stateData.Flow?.Code === 'IN'
                    ? stateData.DestinationLocation
                        ? [stateData.DestinationLocation]
                        : []
                    : [stateData.SourceLocation]
                : stateData && isResourcesDisabled
                  ? [stateData.Location]
                  : stateData && isLocationsDisabled
                    ? [stateData]
                    : [];

        const initialTrips = stateData && isTripDisabled ? stateData : null;
        const initialVisits = stateData && isVisitDisabled ? stateData : null;
        const initialResources = stateData && isResourcesDisabled ? [stateData] : [];
        const initialTripDate =
            stateData?.StartDateAt && isTripDisabled ? dayjs(stateData.StartDateAt) : dayjs();

        // console.log('1', stateData, initialResources, initialLocations, initialTrips);

        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');

        const [datesRange, setDatesRange] = useState('day');
        const [displayMode, setDisplayMode] = useState<'horizontal' | 'vertical'>(
            isBigMobile ? 'vertical' : 'horizontal'
        );
        const [date, setDate] = useState(initialTripDate);
        const [resources, setResources] = useState<IObjectWithId[]>(initialResources);
        const [locations, setLocations] = useState<IObjectWithId[]>(initialLocations);
        // console.log(locations);
        const [trip, setTrip] = useState<DocTrip | null>(initialTrips);
        const [visit, setVisit] = useState<DocTrip | null>(initialVisits);
        // const [selectedRows, setSelectedRows] = useState<IObjectWithId[]>([]);
        const [legend, legendMake] = useMap<PlainObject>({});
        const [isOpenMobilePopup, setIsOpenMobilePopup] = useBoolean(false);
        const [multiplicator, setMultiplicator] = useState(1);
        const [multiplicatorTechnical, setMultiplicatorTechnical] = useState(0);

        // console.log('2', resources, locations, trip);

        const daysCountFromDateRangesEndpoint = useMemo(() => {
            switch (datesRange) {
                case 'day':
                    return 1 * multiplicator;
                case '4_days':
                    return 4 * multiplicator;
                case 'week':
                    return 7 * multiplicator;
                default:
                    return 1 * multiplicator;
            }
        }, [datesRange, multiplicator]);

        // если мы совершили переход от рейса - выставляем дату его начала
        useEffect(() => {
            if (trip && isTripDisabled && !stateData) {
                setDate(dayjs(trip.StartDateAt));
            }
        }, [trip, isTripDisabled]);

        useEffect(() => {
            if (visit && isVisitDisabled && !stateData) {
                setDate(dayjs(visit.StartDateAt));
            }
        }, [visit, isVisitDisabled]);

        const dateRangeToFetch = useMemo(
            () =>
                range(0, daysCountFromDateRangesEndpoint).map((index) => {
                    return date.add(index, 'days').format(DATE_FORMAT);
                }),
            [date, daysCountFromDateRangesEndpoint]
        );
        const dateIn = dateRangeToFetch.join(',');
        const resourcesIdsIn = resources.map(({ Id }) => Id).join(',');
        const locationsIdsIn = locations.map(({ Id }) => Id).join(',');
        const minDate = useMemo(
            () => date.set('hours', 0).set('minutes', 0).set('seconds', 0).format(DATE_TIME_FORMAT),
            [date]
        );
        const maxDate = useMemo(
            () =>
                date
                    .add(daysCountFromDateRangesEndpoint - 1, 'days')
                    .set('hours', 23)
                    .set('minutes', 59)
                    .set('seconds', 59)
                    .format(DATE_TIME_FORMAT),
            [date, daysCountFromDateRangesEndpoint]
        );

        // Запрашиваем рейсы для отбора
        const { loading: tripLoading } = useAsync(async () => {
            if (filters?.[0].value[0] && isVisitDisabled && !trip) {
                // console.log('TRIPS RESPONSE');
                const response = await metaStore.makeSelect({
                    meta: 'DocTrips',
                    // filters: `Id=eq.${location.state.filters[0].value[0]}`
                    filters: `Id=eq.${filters?.[0].value[0]}`
                });

                if (response) {
                    const trip = response.objects[0];
                    setTrip(trip);

                    const sourceLoc = trip?.SourceLocation;
                    const destinationLoc = trip?.DestinationLocation;

                    if (!locations.length && trip?.Flow?.Code === 'IN') {
                        if (destinationLoc) setLocations([destinationLoc]);
                    } else if (!locations.length && sourceLoc) setLocations([sourceLoc]);
                }
            }
        }, [filters, fromMeta]);

        // Запрашиваем визиты для отбора
        const { loading: visitLoading } = useAsync(async () => {
            if (filters?.[0].value[0] && isVisitDisabled && !visit) {
                const response = await metaStore.makeSelect({
                    meta: 'DocTerminalVisits',
                    filters: `Id=eq.${filters?.[0].value[0]}`
                });

                if (response) {
                    const visit = response.objects[0];
                    setVisit(visit);

                    const sourceLoc = visit?.SourceLocation;
                    const destinationLoc = visit?.DestinationLocation;

                    if (!locations.length && visit?.Flow?.Code === 'IN') {
                        if (destinationLoc) setLocations([destinationLoc]);
                    } else if (!locations.length && sourceLoc) setLocations([sourceLoc]);
                }
            }
        }, [filters, fromMeta]);

        // Запрашиваем МПЛ для отбора
        const { loading: locationsLoading } = useAsync(async () => {
            if (filters?.length && isLocationsDisabled && !locations.length) {
                const response = await metaStore.makeSelect({
                    meta: 'CatLocations',
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setLocations(response.objects);
            }
        }, [filters, fromMeta]);

        // запрашиваем мету ресурсов
        useAsync(async () => {
            await metaStore.getInfo('CatLocationResources');
        }, []);

        // Запрашиваем Ресурсы для отбора
        const { loading: resourcesLoading } = useAsync(async () => {
            if (filters?.length && isResourcesDisabled && !resources.length) {
                const response = await metaStore.makeSelect({
                    meta: 'CatLocationResources',
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setResources(response.objects);
            }
        }, [filters, fromMeta]);

        // Запрашиваем Свободные слоты
        const freeSlots = useAsyncRetry(async () => {
            if (!locationsIdsIn) return []; // if have not filters - empty

            let filters = `SlotDate=in.[${dateIn}]`;

            if (!isEmpty(resourcesIdsIn)) filters += `&LocationResource=in.[${resourcesIdsIn}]`;
            if (!isEmpty(locationsIdsIn)) filters += `&Location=in.[${locationsIdsIn}]`;

            const res = await metaStore.makeSelect({
                meta: 'RegLocationResourceSlots',
                filters
            });

            return res?.objects ?? [];
        }, [resourcesIdsIn, locationsIdsIn, dateIn]);

        // console.log('free', freeSlots);

        const settedDefaultScale = useRef(false);

        useEffect(() => {
            settedDefaultScale.current = false;
        }, [locations, visit, trip, resources, date]);

        useEffect(() => {
            if (
                !settedDefaultScale.current &&
                enableTimelineMultiplicator &&
                freeSlots.value?.length
            ) {
                const minDuration = Math.min(
                    ...freeSlots.value.map(
                        (item) => item.LocationResource?.SlotDuration ?? Infinity
                    )
                );

                const hours = Math.ceil(minDuration / 3600);

                let multiplicator = 1;

                if (hours === Infinity) multiplicator = 1;
                else if (hours >= 24) multiplicator = 24;
                else if (24 % hours === 0) {
                    if (hours === 3) multiplicator = hours - 1;
                    else if (hours === 8) multiplicator = 6;
                    else multiplicator = hours;
                } else multiplicator = 1;

                if (multiplicator === 1) setMultiplicatorTechnical(0);
                else if (multiplicator === 2) setMultiplicatorTechnical(20);
                else if (multiplicator === 4) setMultiplicatorTechnical(40);
                else if (multiplicator === 6) setMultiplicatorTechnical(60);
                else if (multiplicator === 12) setMultiplicatorTechnical(80);
                else if (multiplicator === 24) setMultiplicatorTechnical(100);

                setMultiplicator(multiplicator);
                settedDefaultScale.current = true;
            }
        }, [freeSlots.value, enableTimelineMultiplicator]);

        // Запрашиваем Занятые слоты
        const bookedSlots = useAsyncRetry<RegBookedLocationSlot[]>(async () => {
            if (!locationsIdsIn) return [];

            let filters = `Date=in.[${dateIn}]`;

            if (!isEmpty(resourcesIdsIn)) filters += `&LocationResource=in.[${resourcesIdsIn}]`;
            if (!isEmpty(locationsIdsIn)) filters += `&Location=in.[${locationsIdsIn}]`;

            const res = await metaStore.makeSelect({
                meta: 'RegBookedLocationSlots',
                filters,
                sort: 'StartAt ASC'
            });
            return res?.objects ?? [];
        }, [resourcesIdsIn, locationsIdsIn, dateIn]);

        // console.log('booked', bookedSlots);

        useUpdateEffect(() => {
            if (bookedSlots.value) {
                legendMake.setAll(
                    bookedSlots.value.reduce((acc, bs) => {
                        const res = acc;
                        if (bs.Status?.Color) res[bs.Status?.Color] = bs.Status?.Name?.[language];
                        return res;
                    }, {} as PlainObject)
                );
            }
        }, [bookedSlots.value]);

        const fetchBookedSlots = useCallback(
            () => Promise.all([freeSlots.retry(), bookedSlots.retry()]),
            [bookedSlots, freeSlots]
        );

        // const loading = dataLoading || freeSlots.loading || bookedSlots.loading;
        const loading = freeSlots.loading || bookedSlots.loading;

        const reload = async () => {
            await fetchBookedSlots();
            // await Promise.all([fetchData(), fetchBookedSlots()]);
        };

        return (
            <div id="smart_timeline_table_page_container">
                <Flex align="flex-start" gap={5} wrap="wrap" style={{ marginBottom: 5 }}>
                    <Flex align="center" justify="flex-start" style={{ width: '250px' }}>
                        <Segmented
                            size={isBigMobile ? 'large' : 'middle'}
                            style={{ width: '100%' }}
                            block
                            id="timeline_table_date_interval"
                            options={(() => {
                                const options = [
                                    {
                                        value: 'day',
                                        label: t(
                                            multiplicator === 2
                                                ? '2_days'
                                                : multiplicator === 4
                                                  ? '4_days'
                                                  : multiplicator === 6
                                                    ? '6_days'
                                                    : multiplicator === 12
                                                      ? '12_days'
                                                      : multiplicator === 24
                                                        ? '24_days'
                                                        : 'day'
                                        ) as string
                                    },
                                    {
                                        value: '4_days',
                                        label: t(
                                            multiplicator === 2
                                                ? 'week'
                                                : multiplicator === 4
                                                  ? 'half_month'
                                                  : multiplicator === 6
                                                    ? '24_days'
                                                    : multiplicator === 12
                                                      ? 'month'
                                                      : multiplicator === 24
                                                        ? '3_months'
                                                        : '4_days'
                                        ) as string
                                    }
                                ];

                                if (multiplicator === 1 || multiplicator === 2) {
                                    options.push({
                                        value: 'week',
                                        label: t(multiplicator === 2 ? '2_weeks' : 'week') as string
                                    });
                                }

                                return options;
                            })()}
                            value={datesRange}
                            onChange={setDatesRange}
                        />
                    </Flex>
                    <Flex gap={5} wrap="wrap">
                        <Flex align="center" justify="center" gap={5}>
                            {!isBigMobile && <Text>{t('date')}:</Text>}
                            <DatePicker
                                size={isBigMobile ? 'large' : 'middle'}
                                style={{ width: '150px' }}
                                id="timeline_table_date"
                                value={date}
                                onChange={setDate}
                                format={'DD.MM.YYYY'}
                                allowClear={false}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        'smart_timeline_table_page_container'
                                    ) as HTMLElement
                                }
                            />
                        </Flex>
                        <Button
                            size={isBigMobile ? 'large' : 'middle'}
                            icon={<ReloadOutlined />}
                            onClick={reload}
                            disabled={!locationsIdsIn}
                        />
                        <Legend size={isBigMobile ? 'large' : 'middle'} legend={legend} />
                        {!isBigMobile && (
                            <Tooltip
                                title={t('transpose')}
                                placement={'bottom'}
                                destroyTooltipOnHide={true}
                            >
                                <Button
                                    icon={<LazyIcon component={TransposeIcon} />}
                                    onClick={() => {
                                        setDisplayMode((prev) =>
                                            prev === 'horizontal' ? 'vertical' : 'horizontal'
                                        );
                                    }}
                                />
                            </Tooltip>
                        )}

                        {enableTimelineMultiplicator && (
                            <Flex align="center" justify="flex-start" gap={10}>
                                {!isBigMobile && <Text>{t('scale')}:</Text>}
                                <Slider
                                    marks={{
                                        0: <></>,
                                        20: <></>,
                                        40: <></>,
                                        60: <></>,
                                        80: <></>,
                                        100: <></>
                                    }}
                                    tooltip={{
                                        trigger: 'hover',
                                        formatter: (value) =>
                                            value === 20
                                                ? 'x2'
                                                : value === 40
                                                  ? 'x4'
                                                  : value === 60
                                                    ? 'x6'
                                                    : value === 80
                                                      ? 'x12'
                                                      : value === 100
                                                        ? 'x24'
                                                        : 'x1'
                                    }}
                                    step={null}
                                    defaultValue={0}
                                    value={multiplicatorTechnical}
                                    onChange={(value) => {
                                        setMultiplicatorTechnical(value);

                                        if (value === 0) setMultiplicator(1);
                                        else if (value === 20) setMultiplicator(2);
                                        else if (value === 40) setMultiplicator(4);
                                        else if (value === 60) setMultiplicator(6);
                                        else if (value === 80) setMultiplicator(12);
                                        else if (value === 100) setMultiplicator(24);
                                    }}
                                    style={{
                                        width: 200,
                                        marginTop: 0,
                                        marginLeft: 8,
                                        marginBottom: -1,
                                        marginRight: 12
                                    }}
                                />
                            </Flex>
                        )}
                    </Flex>
                    {!isBigMobile ? (
                        <Flex align="flex-start" gap={5} wrap="wrap" style={{ marginBottom: 5 }}>
                            <Flex align="center" gap={5}>
                                <Text>
                                    <Text style={{ display: 'inline' }} type="danger">
                                        *
                                    </Text>
                                    {t('locations')}:
                                </Text>
                                <div style={{ width: '250px' }}>
                                    <SmartMultiSelectField
                                        metaName="CatLocations"
                                        filters="TotalResourcesCount=gt.0"
                                        onChange={setLocations}
                                        loading={locationsLoading}
                                        value={locations}
                                        isDisabled={isLocationsDisabled || isResourcesDisabled}
                                    />
                                </div>
                            </Flex>
                            <Flex align="center" gap={5}>
                                <Text>{t('resources')}:</Text>
                                <div style={{ width: '250px' }}>
                                    <SmartMultiSelectField
                                        metaName="CatLocationResources"
                                        onChange={setResources}
                                        value={resources}
                                        loading={resourcesLoading}
                                        isDisabled={isResourcesDisabled || !locationsIdsIn}
                                        filters={
                                            locations.length === 0
                                                ? ''
                                                : `Location=in.[${locations
                                                      .map(({ Id }) => Id)
                                                      .join(',')}]`
                                        }
                                    />
                                </div>
                            </Flex>
                            {hasTripRoutes && (
                                <Flex align="center" gap={5}>
                                    <Text>{t('trip')}:</Text>
                                    <div style={{ width: '250px' }}>
                                        <SmartSelectField
                                            meta="DocTrips"
                                            onChange={setTrip}
                                            value={trip}
                                            loading={tripLoading}
                                            disabled={isTripDisabled || !locationsIdsIn}
                                            filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде
                                        />
                                    </div>
                                </Flex>
                            )}

                            {hasVripRoutes && (
                                <Flex align="center" gap={5}>
                                    <Text>{t('visit')}:</Text>
                                    <div style={{ width: '250px' }}>
                                        <SmartSelectField
                                            meta="DocTerminalVisits"
                                            onChange={setVisit}
                                            value={visit}
                                            loading={visitLoading}
                                            disabled={isVisitDisabled || !locationsIdsIn}
                                            filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде
                                        />
                                    </div>
                                </Flex>
                            )}
                        </Flex>
                    ) : (
                        <>
                            <Badge
                                size={'large'}
                                dot={!!(trip || locations?.length || resources?.length)}
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                    children={t('filters')}
                                    onClick={() => setIsOpenMobilePopup(true)}
                                    size={'large'}
                                />
                            </Badge>
                            <Popup
                                bodyStyle={{
                                    display: 'flex',
                                    gap: 20,
                                    flexDirection: 'column',
                                    padding: 10
                                }}
                                onClose={() => setIsOpenMobilePopup(false)}
                                onMaskClick={() => setIsOpenMobilePopup(false)}
                                visible={isOpenMobilePopup}
                            >
                                <SmartMultiSelectField
                                    placeholder={t('locations') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName="CatLocations"
                                    onChange={setLocations}
                                    value={locations}
                                    isDisabled={isLocationsDisabled || isResourcesDisabled}
                                />

                                <SmartMultiSelectField
                                    placeholder={t('resources') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName="CatLocationResources"
                                    onChange={setResources}
                                    value={resources}
                                    isDisabled={isResourcesDisabled}
                                    filters={
                                        locations.length === 0
                                            ? ''
                                            : `Location=in.[${locations
                                                  .map(({ Id }) => Id)
                                                  .join(',')}]`
                                    }
                                />
                                {!visit && (
                                    <SmartSelectField
                                        placeholder={t('trip') as string}
                                        style={{ width: 'calc(100% - 20px)' }}
                                        size={'large'}
                                        meta="DocTrips"
                                        onChange={setTrip}
                                        value={trip}
                                        loading={loading}
                                        disabled={isTripDisabled}
                                        filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде
                                    />
                                )}

                                {!trip && (
                                    <SmartSelectField
                                        placeholder={t('visit') as string}
                                        style={{ width: 'calc(100% - 20px)' }}
                                        size={'large'}
                                        meta="DocTerminalVisits"
                                        onChange={setVisit}
                                        value={visit}
                                        loading={loading}
                                        disabled={isVisitDisabled}
                                        filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде
                                    />
                                )}
                            </Popup>
                        </>
                    )}
                </Flex>
                {/* <Tabs defaultActiveKey="1" type="line" items={[tab1, tab2]} /> */}
                <Loader
                    customTip={t('filter_first_message') as string}
                    customIndicator={<ExclamationCircleOutlined />}
                    status={!locationsIdsIn}
                    style={{ color: 'rgba(0,0,0,.85)' }}
                >
                    <SmartTimeline
                        daysCount={daysCountFromDateRangesEndpoint}
                        selectedDate={date}
                        freeSlots={freeSlots.value ?? []}
                        bookedSlots={bookedSlots.value ?? []}
                        selectedResources={resources}
                        selectedLocations={locations}
                        selectedTrips={trip ? [trip] : []}
                        selectedVisits={visit ? [visit] : []}
                        fetchBookedSlots={fetchBookedSlots}
                        loading={freeSlots.loading || bookedSlots.loading}
                        firstColumnFieldName={'LocationResource'}
                        displayMode={displayMode}
                        meta={'CatLocationResources'}
                        multiplicator={multiplicator}
                        // extraContexts={{ filter: { LocationResource } }}
                    />
                </Loader>
            </div>
        );
    }
);
