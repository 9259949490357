import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { v4 } from 'uuid';

dayjs.extend(timezone);

function extractTimezone(input: string): string | null {
    // Регулярное выражение для поиска таймзоны в формате +HH:MM или -HH:MM
    const timezoneRegex = /[+-]\d{2}:\d{2}$/;
    const match = input.match(timezoneRegex);

    // Если таймзона найдена, вернуть ее, иначе вернуть null
    return match ? match[0] : null;
}

export const transformSlots = (
    intervals: PlainObject[],
    propertyNames: { StartDateAt: string; EndDateAt: string } = {
        StartDateAt: 'StartDateAt',
        EndDateAt: 'EndDateAt'
    },
    multiplicator: number = 1
) => {
    const slots: PlainObject[] = [];

    // console.log('I', intervals);

    const multiplicatedMinutes = 60 * multiplicator;

    const intervalsMap = {};

    intervals
        .sort((a, b) => (a.SlotIndex ?? 0) - (b.SlotIndex ?? 0))
        .forEach((interval) => {
            // const mapArray = intervalsMap[interval.Object_Id];

            const startDateString =
                (interval.StartAt as string) ?? (interval[propertyNames.StartDateAt] as string);
            const startTZ = extractTimezone(startDateString);

            const endDateString =
                (interval.EndAt as string) ?? (interval[propertyNames.EndDateAt] as string);
            const endTZ = extractTimezone(endDateString);

            const start = dayjs(startDateString).tz(startTZ || undefined);
            const startMinutes = start.get('minutes');
            // const startPercent = ((60 - startMinutes) / 60) * 100;
            const startPercent =
                ((multiplicatedMinutes - startMinutes) / multiplicatedMinutes) * 100;

            const end = dayjs(endDateString).tz(endTZ || undefined);
            const endMinutes = end.get('minutes');
            // const endPercent = ((endMinutes || 60) / 60) * 100;
            const endPercent = ((endMinutes || multiplicatedMinutes) / multiplicatedMinutes) * 100;

            const startHour = start.get('hours');
            const endHour = end.get('hours') || 24;
            // console.log(
            //     'INTERVAL',
            //     interval.SlotIndex,
            //     interval,
            //     startDateString,
            //     endDateString,
            //     startHour,
            //     startMinutes,
            //     endHour,
            //     endMinutes,
            //     startPercent,
            //     endPercent
            // );

            if (!intervalsMap[interval.Object_Id]) {
                intervalsMap[interval.Object_Id] = [{...interval, startPercent, endPercent, startMinutes, endMinutes, startHour, endHour}];
            } else {
                intervalsMap[interval.Object_Id].push({...interval, startPercent, endPercent, startMinutes, endMinutes, startHour, endHour});
            }

            // console.log(start, end, startHour, endHour);
            if (endHour !== 24 && endHour - startHour > 0 && endMinutes === 0) {
                const StartDateAt = start
                    .set('hours', startHour)
                    .set('minutes', startMinutes)
                    .format('YYYY-MM-DDTHH:mm:ssZ');

                const EndDateAt = end
                    .set('hours', endHour)
                    .set('minutes', endMinutes)
                    .format('YYYY-MM-DDTHH:mm:ssZ');

                // console.log(
                //     '!!!!!!!!!!!!',
                //     startDateString,
                //     endDateString,
                //     startPercent,
                //     endPercent,
                //     endPercent - startPercent
                // );

                const array = intervalsMap[interval.Object_Id];
                const firstIntervalForCurrentObject = array?.at(0);

                slots.push({
                    ...interval,
                    Id: v4(),
                    // SlotIndex: startHour,
                    SlotIndex: startHour - (startHour % multiplicator),
                    SlotDate: interval.SlotDate ?? interval.Date,
                    StartDateAt,
                    StartAt: StartDateAt,
                    EndDateAt,
                    EndAt: EndDateAt,
                    IntervalStartAt: startDateString,
                    IntervalEndAt: endDateString,
                    SlotPercent: array.length === 1 ? endPercent : endPercent - (firstIntervalForCurrentObject?.endPercent ?? 0),
                    IsSlotStart: true,
                    IsSlotEnd: false
                });
            } else if (startHour === endHour) {
                const StartDateAt = start
                    .set('hours', startHour)
                    .set('minutes', startMinutes)
                    .format('YYYY-MM-DDTHH:mm:ssZ');

                const EndDateAt = end
                    .set('hours', endHour)
                    .set('minutes', endMinutes)
                    .format('YYYY-MM-DDTHH:mm:ssZ');

                const array = intervalsMap[interval.Object_Id].filter((item) => item.startHour === startHour);
                const firstIntervalForCurrentObject = array?.at(0);

                slots.push({
                    ...interval,
                    Id: v4(),
                    // SlotIndex: startHour,
                    SlotIndex: startHour - (startHour % multiplicator),
                    SlotDate: interval.SlotDate ?? interval.Date,
                    StartDateAt,
                    StartAt: StartDateAt,
                    EndDateAt,
                    EndAt: EndDateAt,
                    IntervalStartAt: startDateString,
                    IntervalEndAt: endDateString,
                    // SlotPercent: startMinutes > 30 ? startPercent : endPercent,
                    SlotPercent: array.length === 1 ? endPercent : endPercent - (firstIntervalForCurrentObject?.endPercent ?? 0) + 5,
                    // IsSlotStart: false,
                    IsSlotStart: firstIntervalForCurrentObject?.startMinutes !== 0,
                    // IsSlotEnd: true
                    IsSlotEnd: firstIntervalForCurrentObject?.startMinutes === 0
                });
            } else {
                // for (let hour = startHour; hour <= endHour; hour++) {
                // for (let hour = startHour; hour < endHour; hour += multiplicator) {
                for (let hour = startHour - (startHour % multiplicator); hour < endHour; hour += multiplicator) {
                    // const isStartHour = hour === startHour;
                    const isStartHour = hour === startHour - (startHour % multiplicator);
                    // const isEndHour = hour + 1 === endHour;
                    // const isEndHour = hour + multiplicator === endHour || hour === endHour - (endHour % multiplicator) ;
                    const isEndHour = hour + multiplicator + (endHour % multiplicator) === endHour;

                    const StartDateAt = start
                        // .set('hours', hour)
                        .set('hours', isStartHour ? startHour : hour)
                        .set('minutes', isStartHour ? startMinutes : 0)
                        .format('YYYY-MM-DDTHH:mm:ssZ');
                    const EndDateAt = end
                        // .set('hours', hour + 1)
                        .set('months', start.get('months'))
                        .set('dates', start.get('dates'))
                        // .set('hours', hour + multiplicator)
                        .set('hours', endHour < hour + multiplicator ? endHour : hour + multiplicator + (endHour % multiplicator))
                        .set('minutes', isEndHour || endHour < hour + multiplicator ? endMinutes : 0)
                        .format('YYYY-MM-DDTHH:mm:ssZ');

                    if (endMinutes && isEndHour) {
                        slots.push({
                            ...interval,
                            Id: v4(),
                            SlotIndex: hour,
                            SlotDate: interval.SlotDate ?? interval.Date,
                            StartDateAt,
                            StartAt: StartDateAt,
                            IntervalStartAt: startDateString,
                            IntervalEndAt: endDateString,
                            EndDateAt: end
                                // .set('hours', hour + 1)
                                .set('hours', hour + multiplicator)
                                .set('minutes', 0)
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                            EndAt: end
                                // .set('hours', hour + 1)
                                .set('hours', hour + multiplicator)
                                .set('minutes', 0)
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                            SlotPercent: isStartHour ? startPercent : 100,
                            IsSlotStart: isStartHour,
                            IsSlotEnd: false
                        });
                        slots.push({
                            ...interval,
                            Id: v4(),
                            // SlotIndex: hour + 1,
                            SlotIndex: hour + multiplicator,
                            SlotDate: interval.SlotDate ?? interval.Date,
                            IntervalStartAt: startDateString,
                            IntervalEndAt: endDateString,
                            StartDateAt: end
                                // .set('hours', hour + 1)
                                .set('hours', hour + multiplicator)
                                .set('minutes', 0)
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                            StartAt: end
                                // .set('hours', hour + 1)
                                .set('hours', hour + multiplicator)
                                .set('minutes', 0)
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                            EndDateAt,
                            EndAt: EndDateAt,
                            SlotPercent: isEndHour ? endPercent : 100,
                            IsSlotStart: false,
                            IsSlotEnd: isEndHour
                        });

                        break;
                    } else {
                        slots.push({
                            ...interval,
                            Id: v4(),
                            SlotIndex: hour,
                            SlotDate: interval.SlotDate ?? interval.Date,
                            StartDateAt,
                            StartAt: StartDateAt,
                            EndDateAt,
                            EndAt: EndDateAt,
                            SlotPercent: isStartHour ? startPercent : isEndHour ? endPercent : 100,
                            IsSlotStart: isStartHour,
                            IsSlotEnd: isEndHour,
                            IntervalStartAt: startDateString,
                            IntervalEndAt: endDateString
                        });
                    }
                }
            }
        });

    return slots;
};
