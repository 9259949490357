import { Flex, Typography } from 'antd';
import { UniversalBoundary } from 'components';
import { USER_ID_REGX } from 'modules/layouts/DefaultLayout/components/constants';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { fnv1aHash, getNeededMetaRoutePathWithFilters } from 'smart/utils';
import { StoreLink } from 'ui';
import { useStoreNavigate } from 'utils/store';

interface QRCodeScannerProps {
    navigateMode?: 'immediatly' | 'afterClick';
    scannerWidth?: string | number;
    scannerHeight?: string | number;
    onAfterScan?: () => void;
}

export const QRCodeScanner: React.FC<QRCodeScannerProps> = ({
    scannerWidth = 400,
    scannerHeight = scannerWidth,
    navigateMode = 'immediatly',
    onAfterScan
}) => {
    const { t } = useTranslation();
    const storeNavigate = useStoreNavigate();

    const calledOnce = useRef(false);

    const [data, setData] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    // console.log(data);

    // console.log(calledOnce.current);

    return (
        <Flex
            vertical
            justify="center"
            align="center"
            className="qr_code_scanner"
            style={{ height: '95%', width: '95%', backgroundColor: 'white', padding: 10 }}
        >
            {error ? (
                <UniversalBoundary
                    status={'error'}
                    title={t('error_qr_scan.title') as string}
                    // subTitle={t('error_qr_scan.description') as string}
                    subTitle={error}
                    enableGoBackOrGoHome={false}
                    enableTryAgain
                    onTryAgain={() => {
                        setData(null);
                        setError(null);
                        calledOnce.current = false;
                    }}
                />
            ) : data && navigateMode === 'afterClick' ? (
                <UniversalBoundary
                    status={'success'}
                    title={t('success_qr_scan.title') as string}
                    subTitle={t('success_qr_scan.description') as string}
                    enableGoBackOrGoHome={false}
                    enableTryAgain
                    onTryAgain={() => {
                        setData(null);
                        setError(null);
                        calledOnce.current = false;
                    }}
                />
            ) : (
                <BarcodeScannerComponent
                    width={scannerWidth}
                    height={scannerHeight}
                    onUpdate={(err, result) => {
                        if (calledOnce.current) return;

                        if (navigateMode === 'afterClick') {
                            if (error) setError(error);
                            else if (result) setData(result.getText());
                        } else if (navigateMode === 'immediatly') {
                            if (error) {
                                setError(error);
                                console.error(t('error_qr_scan.description'));
                            } else if (result) {
                                const data = result.getText();

                                // console.log('SCANNER', data);

                                if (data.includes(window.location.origin)) {
                                    const path = data.replace(window.location.origin, '');
                                    // console.log(path);
                                    let pathname = path.split('?')[0];
                                    const pathnameLastElement = pathname.split('/').at(-1);
                                    const isDetail =
                                        USER_ID_REGX.test(pathnameLastElement || '') ||
                                        pathnameLastElement === 'new' ||
                                        pathnameLastElement === 'copy';
                                    // const pathnameWithoutLastElement = pathname
                                    //     .split('/')
                                    //     .slice(0, -1);
                                    let filterString = path.split('?')?.[1];
                                    let pageTitle;
                                    // let cacheKey;

                                    const metaCode = filterString.split('meta=eq.')[1];

                                    if (metaCode) {
                                        const {
                                            path,
                                            preFilters,
                                            cacheKey: ck,
                                            pageTitle: pt
                                        } = getNeededMetaRoutePathWithFilters(metaCode);

                                        pathname = isDetail
                                            ? `${path}/${pathnameLastElement}`
                                            : path;
                                        filterString = preFilters;
                                        // cacheKey = ck;
                                        // cacheKey = isDetail
                                        //     ? fnv1aHash(`${metaCode}_${pathnameLastElement}_view`)
                                        //     : ck;
                                        pageTitle = pt;
                                    }

                                    // console.log('SCANNER SUCCESS. NEXT NAVIGATE...');

                                    const state = {};

                                    if (filterString) state.filterString = filterString;
                                    // if (cacheKey) state.cacheKey = cacheKey;
                                    if (pageTitle) state.pageTitle = pageTitle;

                                    calledOnce.current = true;

                                    if (Object.keys(state).length) {
                                        storeNavigate({ pathname, search: '' }, { state });
                                    } else {
                                        storeNavigate(pathname);
                                    }

                                    if (onAfterScan) onAfterScan();
                                } else {
                                    // console.error(t('error_qr_scan.description'));
                                    setError(t('error_qr_scan.description'));
                                    // window.location.assign(data);
                                    // window.open(data, '_blank', 'noopener,noreferrer');
                                }
                            }
                        }
                    }}
                />
            )}
            {data && navigateMode === 'afterClick' ? (
                data.includes(window.location.origin) ? (
                    <StoreLink to={data} children={data} />
                ) : (
                    <Typography.Link href={data} children={data} />
                )
            ) : null}
        </Flex>
    );
};
