import { UploadOutlined } from '@ant-design/icons';
import { Flex, Upload, UploadProps, Image } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ButtonWithTooltips } from 'ui';
import { FILE_BUCKET } from 'utils/config/constants';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useNotifications } from 'utils/hooks';
import { downloadFile, getFileUrlForDownload } from 'smart/utils';

import './FilePickerField.scss';
import { metaStore } from 'utils/store/MetaStore';

// Способ подчеркнуть, что в некоторые поля должны присваивать именно URL. TS позволяет (:
type FileUrlType = string;

interface ValueType {
    file_path: FileUrlType;
    file_name: string;
    mime_type?: string;
    file_size?: number;
    file_md5?: any;
}

export interface FilePickerFieldProps {
    value: ValueType | null;
    onChange: (options: ValueType) => void;
    onChangeExternalFileName?: (value: string) => void;
    path: string;
    bucket?: string;
    enablePreview?: boolean;
    readOnly?: boolean;
}

const UploadInput: React.FC<UploadProps & { enableDragger?: boolean }> = ({
    children,
    ...props
}) => {
    return props.enableDragger ? (
        <Upload.Dragger {...props} customRequest={props.customRequest}>
            {children}
        </Upload.Dragger>
    ) : (
        <Upload
            {...props}
            customRequest={props.customRequest}
            itemRender={(originNode) => <div style={{ marginTop: -8 }}>{originNode}</div>}
        >
            {children}
        </Upload>
    );
};

function getFileExtension(filename: string): string {
    const parts = filename.split('.');

    return parts.length > 1 ? parts.pop() : '';
}

export const FilePickerField = memo<FilePickerFieldProps>(
    ({
        value,
        onChange,
        onChangeExternalFileName,
        readOnly,
        path,
        bucket = FILE_BUCKET,
        enablePreview
    }) => {
        const { t } = useTranslation();
        const { notification } = useNotifications();

        const [fileList, setFileList] = useState<UploadFile[]>([]);

        const [previewOpen, setPreviewOpen] = useState(false);
        const [previewImage, setPreviewImage] = useState('');

        const handlePreview = async (file: UploadFile) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
        };

        // console.log(fileList);
        const [showButton, setShowButton] = useState(!value?.file_name && !value?.file_path);

        useEffect(() => {
            setShowButton(!fileList.length);
        }, [fileList.length]);

        // Тут обновляем fileList для компонента upload, чтобы акутализировать текущий файл в ui и корректно отрисовать с функционалом
        useEffect(() => {
            (async () => {
                if (!value || !value.file_name || !value.file_path) return;

                if (!readOnly) {
                    setFileList(([prev]) => [
                        {
                            ...prev,
                            uid: '0',
                            name: value.file_name,
                            tech_name: value.file_path.split('/').at(-1) || '',
                            // status: 'done' as const,
                            size: value.file_size
                        }
                        // { ...prev, uid: '0', name: value.file_name || '', status: 'uploading' as const }
                    ]);
                }

                try {
                    const downloadUrl = await getFileUrlForDownload(bucket, value.file_path);

                    setFileList(([prev]) => [
                        {
                            ...prev,
                            uid: '0',
                            url: downloadUrl,
                            name: value.file_name,
                            tech_name: value.file_path.split('/').at(-1) || '',
                            size: value.file_size,
                            status: 'done',
                            linkProps: {
                                // ...prev.linkProps,
                                download: value.file_name
                            }
                        }
                    ]);
                } catch (error) {
                    setFileList(([prev]) => [{ ...prev, status: 'error' }]);
                }
            })();
        }, [bucket, value?.file_path]);

        // Функция для отправки файла на сервер
        const handleUpload = useCallback(
            async (file: RcFile) => {
                try {
                    // save file name before replace th
                    if (onChangeExternalFileName) onChangeExternalFileName(file.name);

                    if (typeof file === 'string')
                        throw new Error(t('file_uncorrect_type') as string);

                    // Проверяем, что файл выбран
                    if (!file) {
                        throw new Error(t('file_not_choosen') as string);
                    }

                    const initialName = `${file.name}`;
                    let techName = uuidv4();

                    const ext = getFileExtension(initialName);
                    if (ext) {
                        techName += `.${ext}`;
                    }

                    Object.defineProperty(file, 'name', {
                        // value: translitRu(file.name)
                        value: techName
                    });
                    file.initial_name = initialName;
                    // Object.defineProperty(file, 'initial_name', {
                    //     // value: translitRu(file.name)
                    //     value: initialName
                    // });

                    // const internalFileName = fileList.find((f) => f.uid === file.uid)?.linkProps
                    //     .internalFileName;

                    // const filePath = `${path}/${internalFileName}`;
                    let filePath = '';
                    if (path) {
                        filePath = `${path}/`;
                    }
                    if (file.name) {
                        filePath += file.name;
                    }
                    const { data, error } = await metaStore?.api?.client?.storage.upload(
                        bucket,
                        filePath,
                        file
                    );
                    // const { data, error } = await supabaseClient.storage
                    // .from(bucket)
                    // .upload(filePath, file);

                    // Если ошибка, то бросаем исключение
                    if (error && !data) throw error;

                    // Успех
                    // onChange({ file_name: file.name, file_path: data.path, file_size: file.size });
                    onChange({
                        file_name: initialName,
                        file_path: data.path,
                        file_size: file.size,
                        mime_type: file.type
                    });
                } catch (error) {
                    notification.error({
                        message: t('file_upload_error') as string,
                        description: makeErrorReadable((error as Error).message),
                        duration: 0
                    });

                    // eslint-disable-next-line no-console
                    console.error((error as Error).message); // точно будет message, так как явно его передаем выше
                    throw error;
                }
            },
            [onChangeExternalFileName, t, path, bucket, onChange, notification]
        );

        // Функция удаления файла
        const handleRemove = useCallback(
            async (file: UploadFile) => {
                // let filePath = '';
                // if (path) {
                //     filePath = `${path}/`;
                // }
                // if (file.tech_name) {
                //     filePath += file.tech_name;
                // }
                // debugger;
                // const error = await metaStore?.api?.client?.storage?.delete(bucket, `${filePath}`);
                // if (error) {
                //     console.warn(error);
                // }
                // await supabaseClient.storage
                // .from(bucket)
                // .remove([`${path}/${file.name}`]);
                // .remove([`${path}/${file.linkProps.internalFileName}`]);

                setFileList([]);

                onChange({
                    file_name: '',
                    file_path: '',
                    file_size: undefined,
                    mime_type: undefined,
                    file_md5: undefined
                });
                if (onChangeExternalFileName) onChangeExternalFileName(file.name);
            },
            // [bucket, onChange, path, onChangeExternalFileName]
            [onChange, onChangeExternalFileName]
        ) as UploadProps['onRemove'];

        // Функция загрузки файла
        const handleDownload = useCallback(async () => {
            try {
                if (!value) {
                    notification.error({
                        message: t('file_download_error') as string,
                        description: t('no_value') as string
                    });

                    return;
                }

                await downloadFile(bucket, value.file_path, value.file_name);
            } catch (error) {
                notification.error({
                    message: t('file_download_error') as string,
                    description: makeErrorReadable((error as Error).message),
                    duration: 0
                });

                // eslint-disable-next-line no-console
                console.error((error as Error).message);
            }
        }, [bucket, notification, t, value]);

        // const handleRemove = (file: UploadFile) => handleRemoveFile(file as RcFile);

        const handleRequest: UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
            const res = handleUpload(file as RcFile);
            if (res) {
                res.then(onSuccess).catch(onError);
            }
        };

        const handleChange: UploadProps['onChange'] = (info) => {
            const newFileList = [...info.fileList].map((file) => {
                // console.log(file);
                const newFile = { ...file };

                if (file.response) {
                    newFile.url = file.response.url;
                }
                if (file.originFileObj?.initial_name) {
                    newFile.name = file.originFileObj.initial_name;
                }

                return newFile;
            });

            setFileList(newFileList);
        };

        // render

        return (
            <Flex
                className="file_picker_field"
                style={{ height: enablePreview ? '100%' : 32, width: '100%' }}
                align="center"
            >
                <UploadInput
                    onDownload={handleDownload}
                    onRemove={!readOnly ? handleRemove : () => {}}
                    customRequest={!readOnly ? handleRequest : () => {}}
                    onChange={!readOnly ? handleChange : () => {}}
                    listType={enablePreview ? 'picture-card' : 'text'}
                    onPreview={enablePreview ? handlePreview : undefined}
                    fileList={fileList}
                    showUploadList={{
                        extra: ({ size = 0 }) => {
                            return (
                                <span style={{ color: '#cccccc' }}>
                                    {' '}
                                    ({(size / 1024 / 1024).toFixed(2)}MB)
                                </span>
                            );
                        },
                        removeIcon: readOnly ? () => <></> : undefined
                    }}
                >
                    {!readOnly && showButton ? (
                        <ButtonWithTooltips
                            className="file_picker_upload"
                            type="default"
                            id={'upload'}
                            tooltipTitle={t('upload')}
                            icon={<UploadOutlined />}
                        >
                            {t('upload')}
                        </ButtonWithTooltips>
                    ) : null}
                </UploadInput>
                {enablePreview && previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage('')
                        }}
                        src={previewImage}
                    />
                )}
            </Flex>
        );
    }
);
