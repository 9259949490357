import { Flex, Input } from 'antd';
import { memo } from 'react';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { isNullOrUndefined } from 'is-lite/exports';
import { Variant } from 'antd/es/config-provider';

interface BarcodeFieldProps {
    value: string | null;
    optionsValue?: string | null;
    onChange?: (newValue: string) => void;
    readOnly?: boolean;
    popover?: boolean;
    variant?: Variant;
}
// Сделал BarcodeField без лейбла чтобы выводить его в отдельный колапс и он выглядил как на макете продукта
export const BarcodeField = memo(
    ({
        value,
        optionsValue,
        onChange = () => {},
        popover = false,
        readOnly,
        variant
    }: BarcodeFieldProps) => {
        const { t } = useTranslation();

        useEffectOnce(() => {
            if (isNullOrUndefined(value) && optionsValue) {
                onChange(optionsValue);
            }
        });

        let barcode;

        if (popover) {
            barcode = null; // не показывать barcode если poopover, так как скорее всего это таблица а там не нужно показывать штрихкод
        } else if (value) {
            barcode = <Barcode value={value} displayValue={readOnly} />;
        } else {
            barcode = <Barcode value="no_value" displayValue={false} lineColor="#fc5e53" />;
        }

        return (
            <Flex vertical>
                {barcode}
                {!readOnly && (
                    <Input.TextArea
                        placeholder={t('no_value') as string}
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        value={value ?? ''}
                        onChange={(e) => {
                            if (onChange) {
                                onChange(e.target.value);
                            }
                        }}
                        allowClear
                        variant={variant}
                    />
                )}
            </Flex>
        );
    }
);
