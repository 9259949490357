import { makeAutoObservable } from 'mobx';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { isEqual } from 'lodash';
import { JSONSafeParse } from '../../../smart/utils';

interface RouteStoreStateType {
    filters?: Filter[];
    filterString?: string;
    pageTitle?: string;
    data?: any;
    cacheKey?: string | number;
    mode: 'edit' | 'view';
}

export interface RouteStoreItemType {
    pathname: string;
    search?: string | null;
    state?: RouteStoreStateType | null;
    [key: string]: any;
}

const initialStore = [{ pathname: '/', search: '', state: null }];

const isEqualWithJSON = (a: any, b: any) => {
    return isEqual(JSONSafeParse(JSON.stringify(a)), JSONSafeParse(JSON.stringify(b)));
};

export class RouteStore {
    routes: RouteStoreItemType[] = initialStore;

    constructor() {
        makeAutoObservable(this);
    }

    private _getExcludeRouteCondition = (pathname: string) => {
        return (
            pathname === '/login' ||
            pathname === '/signup' ||
            pathname === '/verify' ||
            pathname === '/invited' ||
            pathname === '/'
        );
    };

    push = (route: RouteStoreItemType) => {
        if (this._getExcludeRouteCondition(route.pathname)) return;

        if (!this.routes.find((r) => isEqualWithJSON(r, route))) {
            this.routes.push(route);
        }
    };

    insertAt = (index: number, route: RouteStoreItemType) => {
        if (this._getExcludeRouteCondition(route.pathname)) return;

        if (!this.routes.find((r) => isEqualWithJSON(r, route))) {
            this.routes.splice(index, 0, route);
        }
    };

    clear = () => {
        if (this.routes) this.routes = initialStore;
    };

    findIndex = (route: RouteStoreItemType) => {
        return this.routes.findIndex((item) => isEqualWithJSON(item, route));
    };

    isEqual = (r1: RouteStoreItemType, r2: RouteStoreItemType) => {
        return isEqualWithJSON(r1, r2);
    };

    remove = (route: RouteStoreItemType) => {
        if (this.routes) this.routes = this.routes.filter((r) => !isEqualWithJSON(r, route));
    };

    replace = (prevRoute: RouteStoreItemType, newRoute: RouteStoreItemType) => {
        if (this._getExcludeRouteCondition(newRoute.pathname)) return;

        const prevIndex = this.findIndex(prevRoute);
        this.routes.splice(prevIndex, 1, newRoute);
    };
}

export const routeStore = new RouteStore();
