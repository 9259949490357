import { Filter } from 'modules/supabase/utils/supabaseClient';
import { parseValueType } from 'smart/utils';

import dayjs from 'dayjs';
import { FilterField, FilterOption } from '../../types';
import { isArrayIdType } from '../isArrayIdType';
import { isRangeType } from '../isRangeType';
import {
    convertBetweenFilter,
    convertDatetimeIntervalFilter,
    convertDynamicDateRangeToFiler,
    convertIdArrayFilterWithContainsOptionToFilter,
    convertInListToFilter,
    convertNotInListToFilter,
    convertRangeFieldWithContainOptionToFilter,
    convertUIFilterColumnName,
    convertUIFilterValue,
    mapMUITableFilterOperationAndSupabaseFilterOperation
} from './helpers';
import {
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    DATE_TIME_LOCAL_FORMAT,
    TIME_FORMAT
} from '../../../../../../../utils/helpers/dates';

export const convertComplexFilterToSupabaseFilter = (
    complexFilters: FilterField[],
    language: string
): Filter[] => {
    const filter: Filter[] = [];

    for (let i = 0; i < complexFilters.length; i++) {
        const complexFilter = complexFilters[i];

        // console.log('complexFilters', complexFilter);

        const { type: dataType } = parseValueType(
            { valueType: complexFilter.field.ValueType, Options: complexFilter.field.Options },
            language
        );

        for (let j = 0; j < complexFilter.values.length; j++) {
            const complexFilterValue = complexFilter.values[j];
            const filterOperator = complexFilterValue.operator as string;
            const fValue = complexFilterValue.value;

            if (filterOperator) {
                // Обработка оператора in_list
                if (filterOperator === 'in_list') {
                    const inListFilter = convertInListToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...inListFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка оператора not_in_list
                if (filterOperator === 'not_in_list') {
                    const inListFilter = convertNotInListToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...inListFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка оператора dynamic_range
                if (filterOperator === 'dynamic_range') {
                    const dynamicRangeFilter = convertDynamicDateRangeToFiler({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...dynamicRangeFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка поля с типом массив ID [1,2,3...]
                if (isArrayIdType(dataType) && filterOperator === 'contains') {
                    const arrayIdContainsFilter = convertIdArrayFilterWithContainsOptionToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...arrayIdContainsFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка поля с типом RANGE с оператором contains
                if (isRangeType(dataType) && filterOperator === 'contains') {
                    const rangeContainFilter = convertRangeFieldWithContainOptionToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...rangeContainFilter, field: complexFilter.field });

                    continue;
                }

                // Обработка оператора between для полей с типом ДАТА / ДАТАВРЕМЯ
                if (filterOperator === 'between') {
                    // console.log(complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                // const format =
                //     dataType === 'date'
                //         ? DATE_FORMAT
                //         : dataType === 'time'
                //         ? TIME_FORMAT
                //         : DATE_TIME_FORMAT;
                // : DATE_TIME_LOCAL_FORMAT;

                if (filterOperator === 'thisHour') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'thisHour'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'lastHour') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'lastHour'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'nextHour') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'nextHour'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'pastHour') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'pastHour'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'today') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'today'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'tomorrow') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'tomorrow'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'thisDayToNow') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'thisDayToNow'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'nowToThisDay') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'nowToThisDay'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'thisDay') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'thisDay'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'lastDay') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'lastDay'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'nextDay') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'nextDay'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'pastDay') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'pastDay'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'thisWeek') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'thisWeek'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'lastWeek') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'lastWeek'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'nextWeek') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'nextWeek'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'pastWeek') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'pastWeek'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'thisMonth') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'thisMonth'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'lastMonth') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'lastMonth'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'nextMonth') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'nextMonth'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'pastMonth') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertDatetimeIntervalFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: 'pastMonth'
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                // Обработка всех остальных полей
                let operator = mapMUITableFilterOperationAndSupabaseFilterOperation(
                    filterOperator as FilterOption
                );

                if (operator !== 'neq' && !fValue) {
                    operator = 'eq';
                }

                const filterValue: Filter = {
                    column: convertUIFilterColumnName(complexFilter.field, operator, language),
                    operator,
                    value: undefined
                };

                // Обработка опции пусто/не пусто
                if (filterOperator === 'empty') {
                    filter.push({
                        ...filterValue,
                        operator: 'is',
                        value: null,
                        field: complexFilter.field
                    });
                    continue;
                }

                if (filterOperator === 'notEmpty') {
                    filter.push({
                        ...filterValue,
                        operator: 'isnot',
                        value: null,
                        field: complexFilter.field
                    });
                    continue;
                }

                let value;

                if (fValue) {
                    if (dataType.includes('code')) {
                        value = typeof fValue === 'object' ? fValue.Code : fValue;
                    } else if (dataType.includes('id')) {
                        value = typeof fValue === 'object' ? fValue.Id : fValue;
                    } else if (dataType.includes('multilang_text')) {
                        value = fValue[language];
                    } else if (dataType.includes('jsonb')) {
                        value = JSON.stringify(fValue);
                    } else {
                        value = typeof fValue === 'object' ? fValue[filterValue.column] : fValue;
                    }
                }

                filterValue.value = convertUIFilterValue(
                    value,
                    filterValue.column,
                    language,
                    filterValue.operator,
                    dataType
                );

                filter.push({ ...filterValue, field: complexFilter.field });
            }
        }
    }
    return filter;
};
