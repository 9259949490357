import Hamster from 'assets/gifs/hamster-loader.gif';

export const HamsterLoader: React.FC = () => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <img alt="loading" style={{ width: 350, maxHeight: 350 }} src={Hamster} />
    </div>
);
