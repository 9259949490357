import React, { forwardRef, useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
// import Handsontable from 'handsontable';
// import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react-wrapper';
import ReactDOM from 'react-dom';

import 'handsontable/styles/handsontable.min.css';
// import 'handsontable/styles/ht-theme-main.min.css';

import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable';
import { textRenderer } from 'handsontable/renderers';
import { Button, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import './ExcelEditor.scss';
import { StoreLink } from 'ui';
import { createRoot, Root } from 'react-dom/client';
import { useStoreNavigate } from 'utils/store';
import { fnv1aHash, getNeededMetaRoutePathWithFilters } from 'smart/utils';

registerAllModules();

const indexedColors = [
    '#000000',
    '#FFFFFF',
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF'
    // Продолжите таблицу индексированных цветов ...
];

const themeColors = [
    '#FFFFFF',
    '#000000',
    '#EEECE1',
    '#1F497D',
    '#4F81BD',
    '#C0504D',
    '#9BBB59',
    '#8064A2'
    // Добавьте цвета темы Excel (обычно 10 основных цветов)
];

const getCellColor = (fill: ExcelJS.Fill | undefined): string | null => {
    if (!fill || !fill.fgColor) return null;

    if ('argb' in fill.fgColor) {
        return `#${fill.fgColor.argb.slice(2)}`;
    }

    if ('indexed' in fill.fgColor && fill.fgColor.indexed !== undefined) {
        return indexedColors[fill.fgColor.indexed] || null;
    }

    if ('theme' in fill.fgColor && fill.fgColor.theme !== undefined) {
        return themeColors[fill.fgColor.theme] || null;
    }

    return null;
};

type ExcelMetadataInfo = {
    LinksMap: Record<
        string,
        {
            Link: string;
            State: Record<string, any>;
        }
    >;
};

type ExcelMetadataInfoRow = {
    Cell: string;
    Type: string;
    Value: string;
    StoreState: Record<string, any>;
};

const ExcelExcelMetadataInfoRowCells: Record<string, number> = {
    Cell: 1,
    Type: 2,
    Value: 3,
    StoreState: 4
};

const getMetadataInfoRow = (excelRow: ExcelJS.Row): ExcelMetadataInfoRow => {
    const row: Partial<ExcelMetadataInfoRow> = {};
    const cell = excelRow.getCell(ExcelExcelMetadataInfoRowCells['Cell']).value;
    if (cell) {
        row.Cell = cell as string;
    }

    const type = excelRow.getCell(ExcelExcelMetadataInfoRowCells['Type']).value;
    if (type) {
        row.Type = type as string;
    }

    const value = excelRow.getCell(ExcelExcelMetadataInfoRowCells['Value']).value;
    if (value) {
        row.Value = value as string;
    }

    const storeState = excelRow.getCell(ExcelExcelMetadataInfoRowCells['StoreState']).value;
    if (storeState) {
        try {
            row.StoreState = JSON.parse(storeState as string);
        } catch (error) {}
    }

    return row as ExcelMetadataInfoRow;
};

const getMetadataInfo = (workbook: ExcelJS.Workbook): ExcelMetadataInfo => {
    const excelMetadataInfo: ExcelMetadataInfo = {
        LinksMap: {}
    };

    const metadataSheet = workbook.getWorksheet('METADATA');
    if (metadataSheet) {
        metadataSheet?.eachRow((row, rowIndex) => {
            if (rowIndex == 0) {
                return;
            }

            const metadataRow = getMetadataInfoRow(row);

            if (metadataRow.Type === 'LINK') {
                excelMetadataInfo.LinksMap[metadataRow.Cell] = {
                    Link: metadataRow.Value,
                    State: metadataRow.StoreState
                };
            }
        });
    }

    return excelMetadataInfo;
};

type CellStyle = React.CSSProperties & { Link: string; StoreState: Record<string, any> };

export const ExcelEditor = forwardRef<
    HTMLDivElement,
    {
        file?: File | ArrayBuffer;
        className?: string;
        disableHeader?: boolean;
        data?: (string | number | null)[][];
        height?: string;
        colWidths?: string | number;
    }
>(
    (
        {
            file,
            className,
            data: propData,
            colWidths,
            // height = 'calc(100vh - 12rem)',
            height = 'calc(100vh - 15rem)',
            disableHeader = false
        },
        ref
    ) => {
        const [data, setData] = useState<(string | number | null)[][]>([]);
        const [cellStyles, setCellStyles] = useState<CellStyle[][]>([]);

        const handleFileChange: UploadProps['onChange'] = async (info) => {
            // const file = event.target.files?.[0];
            const file = (info.file.originFileObj ?? info.file) as File | ArrayBuffer;

            if (!file) return;

            const workbook = new ExcelJS.Workbook();
            // console.log('workbook', workbook);
            const res = await workbook.xlsx.load(
                (file as any).arrayBuffer
                    ? await(file as File).arrayBuffer()
                    : (file as ArrayBuffer)
            );

            const worksheet = workbook.worksheets[0]; // Первый лист

            const excelMetadataInfo = getMetadataInfo(workbook);

            // const maxRows = worksheet?.rowCount;
            const maxCols = Math.max(...worksheet?.columns?.map((col) => col.number));

            // console.log(maxCols);

            const data: (string | number | null)[][] = [];
            const cellStyles: CellStyle[][] = [];

            worksheet?.eachRow((row, rowIndex) => {
                if (rowIndex > 100) return;

                const rowData: (string | number | null)[] = [];
                const rowStyles: CellStyle[] = [];

                for (let colIndex = 1; colIndex <= maxCols; colIndex++) {
                    // const cell = worksheet?.getRow(rowIndex).getCell(colIndex);
                    const cell = row.getCell(colIndex);

                    // console.log('cell name:', cell.address);

                    rowData[colIndex - 1] = cell.value ?? null;

                    // console.log(cell);

                    const style: CellStyle = {};
                    if (cell.fill) {
                        const fgColor = getCellColor(cell.fill);
                        if (fgColor) {
                            style.backgroundColor = fgColor;
                        }
                    }
                    if (cell.font) {
                        if (cell.font.name) style.fontFamily = cell.font.name;
                        if (cell.alignment) style.textAlign = cell.alignment.horizontal;
                        if (cell.font.size) style.fontSize = `${cell.font.size + 3}px`;
                        if (cell.font.bold) style.fontWeight = 'bold';
                        if (cell.font.italic) style.fontStyle = 'italic';
                        style.color = cell.font.color?.argb
                            ? `#${cell.font.color.argb.slice(2)}${cell.font.color.argb.slice(0, 2)}`
                            : cell.font.color?.theme
                            ? themeColors[cell.font.color.theme]
                            : '#000000';
                    }

                    const cellLink = excelMetadataInfo.LinksMap[cell.address];
                    if (cellLink) {
                        style.Link = cellLink.Link;
                        style.StoreState = cellLink.State;
                    }

                    rowStyles[colIndex - 1] = style;
                }

                data[rowIndex - 1] = rowData;
                cellStyles[rowIndex - 1] = rowStyles;
            });

            if (!data[0]) data[0] = Array(maxCols);

            setCellStyles(cellStyles);
            setData(data);
        };

        useEffect(() => {
            // console.log(file);
            if (file && !propData) {
                handleFileChange({ file: { originFileObj: file } });
            }
        }, [file, propData]);

        const storeNavigate = useStoreNavigate();

        const linkRender = (
            link: string,
            storeState: Record<string, any>,
            value: string
        ): HTMLAnchorElement => {
            const anchor = document.createElement('a');
            anchor.href = link;
            anchor.target = '_blank';
            anchor.className = 'ant-typography';
            anchor.innerHTML = value as string;

            const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                storeNavigate(link, {
                    state: { ...storeState, pageTitle: value, cacheKey: fnv1aHash(link) }
                });
            };

            // @ts-ignore
            anchor.addEventListener('click', handleClick as MouseEvent);

            return anchor;
        };
        // Store root references to prevent memory leaks
        const roots = new WeakMap<HTMLElement, Root>();

        return (
            <div ref={ref} className={`excel_field ${className}`}>
                {/* <input type="file" onChange={handleFileChange} /> */}
                {(!file || propData) && (
                    <Upload showUploadList={false} onChange={handleFileChange}>
                        <Button style={{ marginBottom: 10 }} icon={<UploadOutlined />}>
                            Click to Upload
                        </Button>
                    </Upload>
                )}

                {!!data.length && (
                    <HotTable
                        className="excel_editor"
                        data={propData ?? data}
                        rowHeaders={!disableHeader}
                        colHeaders={!disableHeader}
                        rowHeaderWidth={30}
                        style={{ '--ht-cell-vertical-padding': '1px' }}
                        width="100%"
                        colWidths={colWidths}
                        height={height}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        wordWrap={false}
                        manualColumnResize={true}
                        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                        cells={(row, col) => {
                            const cellProperties: Partial<Handsontable.CellProperties> = {};
                            const style = cellStyles[row]?.[col];
                            if (style) {
                                // console.log(style);
                                // eslint-disable-next-line func-names
                                cellProperties.renderer = function (instance, td, ...rest) {
                                    textRenderer.apply(this, [instance, td, ...rest]);

                                    if (style.backgroundColor)
                                        td.style.backgroundColor = style.backgroundColor;
                                    if (style.color) td.style.color = style.color;
                                    if (style.fontWeight) td.style.fontWeight = style.fontWeight;
                                    if (style.fontStyle) td.style.fontStyle = style.fontStyle;
                                    if (style.fontFamily) td.style.fontFamily = style.fontFamily;
                                    if (style.fontSize) td.style.fontSize = style.fontSize;
                                    if (style.textAlign) td.style.textAlign = style.textAlign;

                                    if (style.Link) {
                                        const cellValue = td.childNodes[0].nodeValue;
                                        td.innerHTML = '';
                                        td.appendChild(
                                            linkRender(
                                                style.Link,
                                                style.StoreState,
                                                cellValue as string
                                            )
                                        );
                                    }
                                };
                            }
                            return cellProperties;
                        }}
                    />
                )}
            </div>
        );
    }
);
